/**
 * Flag enum for attachment permissions
 */ export var AttachmentPermissions;
(function(AttachmentPermissions) {
    AttachmentPermissions[AttachmentPermissions["DisableNone"] = 0] = "DisableNone";
    AttachmentPermissions[AttachmentPermissions["DisableUpdateAttachments"] = 1] = "DisableUpdateAttachments";
    AttachmentPermissions[AttachmentPermissions["DisableEditPublishedAttachments"] = 2] = "DisableEditPublishedAttachments";
    AttachmentPermissions[AttachmentPermissions["DisableAddAttachment"] = 4] = "DisableAddAttachment";
    AttachmentPermissions[AttachmentPermissions["DisableAll"] = 7] = "DisableAll";
})(AttachmentPermissions || (AttachmentPermissions = {}));
/**
 * Returns true if the given attachment permission is disabled
 * @param permissionSet The permission set to check against
 * @param permission The permission to check
 */ export function isAttachmentPermissionDisabled(permissionSet, permission) {
    return (permissionSet & permission) !== 0;
}
