// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Styles
import { concatStyleSets, concatStyleSetsWithProps, memoizeFunction, NeutralColors } from "@fluentui/react";
import { getContextualMenuStyles } from "../../contextualMenu/ContextualMenu.styles";
import { getCheckboxStyleFunction } from "../checkbox/Checkbox.styles";
import { buttonFluentUIStyle, fontColorNeutralSecondary } from "../../../utilities/Mixin.styles";
import { highContrastBorders } from "../../../utilities/HighContrast.styles";
/**
 * Get custom styles for TaskDetailsAttachmentsSection control
 * @param theme App context theme
 * @param customStyles (optional) Any custom style overrides
 */ export const getCustomTaskDetailsAttachmentsSectionStyles = memoizeFunction((theme, customStyles)=>{
    const subComponentStyles = {
        contextualMenu: getContextualMenuStyles(theme),
        attachmentLabel: getAttachmentLabelStyles(theme),
        list: getAttachmentListStyles(theme),
        addAttachmentButton: buttonFluentUIStyle(theme)
    };
    const styles = {
        subComponentStyles
    };
    return concatStyleSets(styles, customStyles);
});
/**
 * Helper to get styles for task attachment label
 * @param theme App context theme
 */ const getAttachmentLabelStyles = memoizeFunction((theme)=>{
    const attachmentLabelStyles = {
        root: fontColorNeutralSecondary(theme)
    };
    return attachmentLabelStyles;
});
/**
 * Helper to get styles for task attachment show on card checkbox
 * @param theme App context theme
 */ const getShowOnCardStyles = memoizeFunction((theme)=>{
    const customCheckboxStyles = (props)=>{
        const isActiveState = props.checked && !props.disabled;
        const activeStyles = {
            "> .ms-Checkbox-label": [
                isActiveState && {
                    "> .ms-Checkbox-checkbox": [
                        theme.name === UnoTheme.TeamsContrast && {
                            borderColor: Colors.Black,
                            backgroundColor: Colors.TeamsContrastHyperlinkColor,
                            "> .ms-Checkbox-checkmark": {
                                color: Colors.Black
                            }
                        },
                        theme.name === UnoTheme.SharePoint && {
                            backgroundColor: Colors.SharepointCheckboxCheckedColor()
                        }
                    ]
                },
                theme.name === UnoTheme.SharePoint && {
                    "> .ms-Checkbox-text": {
                        color: props.disabled ? Colors.SharepointPlaceholderFontColor() : Colors.SharepointNeutralTextFontColor()
                    }
                }
            ]
        };
        const styles = {
            checkbox: [
                theme.name === UnoTheme.SharePoint && [
                    isActiveState && {
                        backgroundColor: Colors.SharepointCheckboxCheckedColor()
                    }
                ]
            ],
            root: [
                activeStyles,
                {
                    ":hover, :active, :focus": activeStyles
                }
            ],
            checkmark: [
                theme.name === UnoTheme.TeamsContrast && {
                    color: Colors.White
                }
            ]
        };
        return styles;
    };
    const defaultCheckboxStyles = getCheckboxStyleFunction(theme);
    const showOnCardStyles = (props)=>concatStyleSetsWithProps(props, defaultCheckboxStyles, customCheckboxStyles);
    return showOnCardStyles;
});
/**
 * Helper to get styles for task attachment list control
 * @param theme App context theme
 */ const getAttachmentListStyles = memoizeFunction((theme)=>{
    const leftContent = ()=>{
        switch(theme.name){
            case UnoTheme.TeamsDark:
            case UnoTheme.Dark:
            case UnoTheme.LoopDark:
                return {
                    border: "none"
                };
            case UnoTheme.TeamsContrast:
                return {
                    ":hover": {
                        borderColor: Colors.TeamsContrastHyperlinkColor
                    }
                };
            case UnoTheme.SharePoint:
                return {
                    backgroundColor: Colors.SharepointBackgroundColor(),
                    border: "none",
                    boxShadow: "none",
                    ":hover": {
                        backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor()
                    }
                };
            default:
                return {};
        }
    };
    const attachmentChicklet = ()=>{
        switch(theme.name){
            case UnoTheme.TeamsContrast:
                return {
                    borderColor: Colors.White,
                    backgroundColor: Colors.Black,
                    ":hover": {
                        backgroundColor: Colors.TeamsContrastHyperlinkColor,
                        borderColor: Colors.TeamsContrastHyperlinkColor,
                        color: Colors.Black
                    }
                };
            case UnoTheme.Dark:
            case UnoTheme.TeamsDark:
                return {
                    backgroundColor: NeutralColors.gray150,
                    ":hover": {
                        backgroundColor: NeutralColors.gray140
                    }
                };
            case UnoTheme.LoopDark:
                return {
                    backgroundColor: Colors.NeutralDarkGrey24,
                    ":hover": {
                        backgroundColor: Colors.NeutralDarkGrey12
                    }
                };
            default:
                return {};
        }
    };
    const itemListTitleText = ()=>{
        switch(theme.name){
            case UnoTheme.TeamsContrast:
            case UnoTheme.SharePoint:
                return {
                    color: "inherit"
                };
            case UnoTheme.Dark:
            case UnoTheme.TeamsDark:
            case UnoTheme.LoopDark:
                return {
                    color: Colors.White
                };
            default:
                return {};
        }
    };
    const itemListUrlText = ()=>{
        switch(theme.name){
            case UnoTheme.TeamsContrast:
            case UnoTheme.SharePoint:
                return {
                    color: "inherit"
                };
            case UnoTheme.Dark:
            case UnoTheme.TeamsDark:
                return {
                    color: NeutralColors.gray60
                };
            case UnoTheme.LoopDark:
                return {
                    color: Colors.NeutralDarkGrey84
                };
            default:
                return {};
        }
    };
    const listStyles = {
        subComponentStyles: {
            row: {
                content: [
                    theme.name === UnoTheme.TeamsContrast && {
                        color: Colors.White
                    }
                ],
                leftContent: [
                    leftContent(),
                    highContrastBorders()
                ],
                attachmentChicklet: attachmentChicklet(),
                previewListImage: [
                    theme.name === UnoTheme.SharePoint && {
                        backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor()
                    }
                ],
                fallbackPreviewIcon: [
                    theme.name === UnoTheme.TeamsContrast && {
                        color: "inherit"
                    }
                ],
                itemListTitleText: itemListTitleText(),
                itemListUrlText: itemListUrlText(),
                subComponentStyles: {
                    showOnCard: getShowOnCardStyles(theme),
                    contextualMenu: getContextualMenuStyles(theme)
                }
            }
        }
    };
    return listStyles;
});
