import { concatStyleSets, FontSizes, memoizeFunction, mergeStyles } from "@fluentui/react";
export const dndGripperWidth = "16px";
/**
 * Helper to get styles for the Checklist Container
 * @param theme Current app theme
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const ChecklistContainerStyles = {
        root: [
            {
                marginLeft: `-${dndGripperWidth}`,
                fontSize: FontSizes.medium,
                ul: {
                    margin: "0",
                    padding: "0"
                },
                li: {
                    listStyleType: "none"
                },
                "ul.dndList": {
                    li: {
                        margin: "0"
                    },
                    "li.placeholder": {
                        height: "30px"
                    },
                    "overflow-y": "hidden"
                }
            }
        ]
    };
    return concatStyleSets(ChecklistContainerStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles for the Checklist Container component
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("checklist-container", styles.root)
    };
});
