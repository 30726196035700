// Models
// Utilities
import { ContentType } from "../../service/graph/general/ContentType";
import cloneDeep from "lodash/cloneDeep";
/**
 * These properties needs to be passed when a new conversation is created to supress the emails for comments,
 * these are extension properties and thus not on the graph documentation. These are the result of a special agreement between planner and exchange.
 * For future ref: The way this feature works is
 * The logic is if you have never commented on the task (but other people are commenting) you should never see an email in your inbox
 * (but you will see the comments in the group mailbox).
 * If you comment on a task, you will get all future comments for that task (even by other people) in your inbox.
 */ export const TransportTrafficTypeName = "String {00020386-0000-0000-C000-000000000046} Name X-MS-Exchange-Organization-TransportTrafficType";
export const TransportTrafficTypeValue = "Email";
export const TransportTrafficSubTypeName = "String {00020386-0000-0000-C000-000000000046} Name X-MS-Exchange-Organization-TransportTrafficSubType";
export const TransportTrafficSubTypeValue = "PlannerComment";
export class Post {
    getClone() {
        return new Post(this);
    }
    forCreate() {
        return {
            body: {
                content: this.body.content,
                contentType: this.body.contentType
            },
            singleValueExtendedProperties: [
                {
                    id: TransportTrafficTypeName,
                    value: TransportTrafficTypeValue
                },
                {
                    id: TransportTrafficSubTypeName,
                    value: TransportTrafficSubTypeValue
                }
            ]
        };
    }
    /**
     * Build from a post resource entity as initialization data.
     * @param resource post resource entity
     */ static fromResource(resource) {
        return new Post({
            body: {
                content: resource.body?.content ?? "",
                contentType: resource.body?.contentType ?? ContentType.Text
            },
            changeKey: resource.changeKey,
            createdDateTime: resource.createdDateTime,
            fromField: {
                emailAddress: {
                    address: resource.from.emailAddress.address,
                    name: resource.from.emailAddress.name
                }
            },
            id: resource.id,
            lastModifiedDateTime: resource.lastModifiedDateTime,
            receivedDateTime: resource.receivedDateTime,
            sender: {
                emailAddress: {
                    address: resource.sender.emailAddress.address,
                    name: resource.sender.emailAddress.name
                }
            }
        });
    }
    /**
     * Method to generate the html string for the post body
     *
     * Note: The content text must be escaped beforehand.
     * @param contentText Text contents for the post
     */ static generatePostBody(contentText) {
        return {
            content: contentText,
            contentType: ContentType.HTML
        };
    }
    constructor(propertyBag){
        if (propertyBag.body == null) {
            throw new Error("ArgumentNullException: body");
        }
        this.body = cloneDeep(propertyBag.body);
        this.changeKey = propertyBag.changeKey;
        this.createdDateTime = cloneDeep(propertyBag.createdDateTime);
        this.fromField = cloneDeep(propertyBag.fromField);
        this.id = propertyBag.id;
        this.lastModifiedDateTime = cloneDeep(propertyBag.lastModifiedDateTime);
        this.receivedDateTime = cloneDeep(propertyBag.receivedDateTime);
        this.sender = cloneDeep(propertyBag.sender);
    }
}
