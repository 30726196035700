// Constants
import { MaxSecondaryDialogWidth, SecondaryDialogWidth } from "../../../constants/StyleConstants";
// Styles
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { FontSizes, FontWeights } from "@fluentui/react";
import { fontColorNeutralPrimary, themeBorderColorPrimary } from "../../../utilities/Mixin.styles";
// Types
import { getPlannerAppDialogStyles, getPlannerAppDialogContentStyles } from "../../../utilities/BaseDialog.styles";
/**
 * Helper to get styles for the delete task dialog
 * @param theme Current app theme
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const deleteRecurringTaskDialogStyles = {
        taskChoice: [
            {
                ".ms-ChoiceField-field.is-checked, .ms-ChoiceField-field.is-checked:before, .ms-ChoiceField-field.is-checked:after": [
                    themeBorderColorPrimary(theme)
                ],
                ".ms-ChoiceFieldLabel": [
                    fontColorNeutralPrimary(theme),
                    {
                        fontSize: FontSizes.medium
                    }
                ]
            }
        ]
    };
    return concatStyleSets(deleteRecurringTaskDialogStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles for the desk task dialog component
 * @param styles The styles for the component
 * @param customStyles Any custom style overrides
 */ export const getClassNames = memoizeFunction((theme, customStyles)=>{
    const styles = getStyles(theme, customStyles);
    return {
        taskChoice: mergeStyles("deleteTaskChoice", styles.taskChoice)
    };
});
/**
 * Helper to get styles for DeleteRecurringTaskDialog
 * @param theme Planner theme
 */ export const getDeleteRecurringTaskDialogStyles = memoizeFunction((theme)=>{
    const main = {
        maxWidth: MaxSecondaryDialogWidth,
        width: SecondaryDialogWidth,
        "@media (min-width: 480px)": {
            maxWidth: MaxSecondaryDialogWidth,
            width: SecondaryDialogWidth
        }
    };
    const dialogStyles = {
        main
    };
    return getPlannerAppDialogStyles(theme, dialogStyles);
});
/**
 * Helper to get styles for DeleteRecurringTaskDialogContent
 * @param theme Planner theme
 */ export const getDeleteRecurringTaskDialogContentStyles = memoizeFunction((theme)=>{
    const title = {
        fontSize: FontSizes.large,
        fontWeight: FontWeights.semibold
    };
    const dialogContentStyles = {
        title
    };
    return getPlannerAppDialogContentStyles(theme, dialogContentStyles);
});
