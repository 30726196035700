import * as DOMPurify from "dompurify";
import { Allowed_HTML_Tags, Allowed_HTML_Attributes, Allowed_CSS_Properties } from "@ms/uno-constants/lib/local/DOMPurifyConstants";
/**
 * Function used to sanitize content
 * @param content The original content being sanitized
 */ export function sanitizeContent(content) {
    const domPurify = setUpDOMPurifyConfigs();
    return domPurify.sanitize(content);
}
// Helper to get a mapping of allowed css attributes to Javascript properties
function getAllowedCSSToJavascriptPropertiesMap() {
    const cssPropsMap = {};
    const toCamelCaseRegExp = new RegExp("-([a-z])", "g");
    Allowed_CSS_Properties.forEach((cssProp)=>{
        const cssJavaScriptProperty = cssProp.replace(toCamelCaseRegExp, function(g) {
            return g[1].toUpperCase();
        });
        cssPropsMap[cssJavaScriptProperty] = cssProp;
    });
    return cssPropsMap;
}
// Helper method to set up DOMPurify configs, including settings such as allowed attributes/tags etc.
function setUpDOMPurifyConfigs() {
    const allowedCSSPropsMap = getAllowedCSSToJavascriptPropertiesMap();
    /**
     * Below code is mostly copied from: https://github.com/cure53/DOMPurify/blob/main/demos/hooks-sanitize-css-demo.html
     * The purpose of this chunk of code is to handling css sanitization
     * DOMPurify currently doesn't have a direct config to handle css sanitization
     */ /**
     * Take CSS property-value pairs and validate against allow-list,
     * then add the styles to an array of property-value pairs
     */ function validateStyles(output, styles) {
        // Validate regular CSS properties
        for(const prop in styles){
            if (typeof styles[prop] === "string") {
                if (styles[prop] && allowedCSSPropsMap[prop]) {
                    output.push(allowedCSSPropsMap[prop] + ":" + styles[prop] + ";");
                }
            }
        }
    }
    /**
     * Take CSS rules and analyze them, create string wrapper to
     * apply them to the DOM later on. Note that only selector rules
     * are supported right now
     */ function addCSSRules(output, cssRules) {
        for(let index = cssRules.length - 1; index >= 0; index--){
            const rule = cssRules[index];
            // check for rules with selector
            if (rule.type === 1 && rule.selectorText) {
                output.push(rule.selectorText + "{");
                if (rule.style) {
                    validateStyles(output, rule.style);
                }
                output.push("}");
            }
        }
    }
    // Add a hook to enforce CSS element sanitization
    DOMPurify.addHook("uponSanitizeElement", function(node, data) {
        if (data.tagName === "style") {
            const output = [];
            addCSSRules(output, node.sheet.cssRules);
            node.textContent = output.join("\n");
        }
    });
    // Add a hook to enforce CSS attribute sanitization
    DOMPurify.addHook("afterSanitizeAttributes", function(node) {
        // Nasty hack to fix baseURI + CSS problems in Chrome
        if (!node.ownerDocument.baseURI) {
            const base = document.createElement("base");
            base.href = document.baseURI;
            node.ownerDocument.head.appendChild(base);
        }
        // Check all style attribute values and validate them
        if (node.hasAttribute("style")) {
            const output = [];
            validateStyles(output, node.style);
            // re-add styles in case any are left
            if (output.length) {
                node.setAttribute("style", output.join(""));
            } else {
                node.removeAttribute("style");
            }
        }
    });
    /* END copied code */ // The following config settings specify accepted HTML tags and attributes
    // The accepted lists match with current Todo Server accepted list
    // Refer to service code todoserver/Utility/HtmlConverter.cs for allowed list
    // This setting also matches with Teams Tasks Mobile DomPurify Config setting
    // Refer to TT's mobilern/src/components/taskEditor/TaskRichTextEditor.tsx
    const config = {
        ALLOWED_TAGS: Allowed_HTML_Tags,
        ALLOWED_ATTR: Allowed_HTML_Attributes,
        ALLOW_DATA_ATTR: false,
        ALLOW_ARIA_ATTR: false
    };
    DOMPurify.setConfig(config);
    return DOMPurify;
}
