// Models
import { DriveItem } from "./DriveItem";
import { SupportedReferenceTypes } from "../taskAttachment/ITaskAttachmentType";
// Utilities
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
import moment from "moment";
/**
 * Configuration and building of DriveItem objects
 */ export class DriveItemBuilder {
    /**
     * Build out the DriveItem
     */ build() {
        return new DriveItem(this);
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.id = propertyBag.id || this.id;
        this.name = propertyBag.name || this.name;
        this.fileType = propertyBag.fileType || this.fileType;
        this.url = propertyBag.url || this.url;
        this.webDavUrl = propertyBag.webDavUrl || this.webDavUrl;
        this.modifiedDate = propertyBag.modifiedDate || this.modifiedDate;
        this.childCount = propertyBag.childCount !== undefined ? propertyBag.childCount : this.childCount;
        this.itemPath = propertyBag.itemPath || this.itemPath;
        this.driveId = propertyBag.driveId || this.driveId;
        return this;
    }
    /**
     * Build from a GraphDriveItemResource entity as initialization data.
     * @param graphDriveItem DriveItem resource entity to use for initializing the driveItem builder
     */ fromGraphDriveItemResource(graphDriveItem) {
        return this.withPropertyBag({
            id: graphDriveItem.id,
            name: graphDriveItem.name,
            fileType: graphDriveItem.folder ? SupportedReferenceTypes.Other : graphDriveItem.package ? DriveItem.GetType(graphDriveItem.package.type) : DriveItem.GetFileType(graphDriveItem.name),
            url: graphDriveItem.webUrl,
            webDavUrl: graphDriveItem.webDavUrl,
            modifiedDate: moment(graphDriveItem.lastModifiedDateTime),
            childCount: graphDriveItem.folder ? graphDriveItem.folder.childCount : -1,
            itemPath: graphDriveItem.parentReference.path,
            driveId: graphDriveItem.parentReference.driveId
        });
    }
    /**
     * Initialize an instance of the DriveItem builder
     */ constructor(){
        this.id = generateGuid();
        this.name = "";
        this.fileType = SupportedReferenceTypes.Other;
        this.url = "";
        this.webDavUrl = "";
        this.modifiedDate = moment();
        this.childCount = -1;
        this.itemPath = "/drive/root:";
        this.driveId = "";
    }
}
