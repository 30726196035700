import { memoizeFunction } from "@fluentui/react";
/**
 * Get styles for icon button
 */ export const getIconButtonStyles = memoizeFunction(()=>{
    return {
        root: {
            color: "inherit",
            backgroundColor: "inherit"
        },
        rootHovered: {
            color: "inherit",
            backgroundColor: "inherit"
        },
        rootPressed: {
            color: "inherit",
            backgroundColor: "inherit"
        }
    };
});
