// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
// Styles
import { concatStyleSetsWithProps, memoizeFunction } from "@fluentui/react";
import { NeutralColors } from "@fluentui/react";
import { flexChildSizeFixed, flexParent, fontColorNeutralPrimary, fontColorNeutralSecondary, fontColorNeutralTertiary, themePrimaryColor } from "../../../utilities/Mixin.styles";
// Utilities
import { isLoopTheme, isWindowsHighContrast } from "../../../utilities/ThemingUtilities";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
const checkboxSize = "16px";
/**
 * Helper to get styles function for Checkbox
 * @param theme Uno theme
 * @param customStyles Any custom style overrides
 */ export const getCheckboxStyleFunction = memoizeFunction((theme, customStyles)=>(props)=>{
        const { checked, disabled } = props;
        const isLoop = isLoopTheme(theme);
        // Don't apply these in HC, or else it will override Fluent's Checkbox HC styles
        const root = !isWindowsHighContrast() && {
            ":hover, :active, :focus": {
                "> .ms-Checkbox-label": {
                    "> .ms-Checkbox-checkbox": {
                        backgroundColor: checkboxActiveBackgroundColor(theme, checked, disabled),
                        borderColor: checkboxActiveBorderColor(theme, checked, disabled),
                        "> .ms-Checkbox-checkmark": {
                            color: checkmarkActiveColor(theme, checked)
                        }
                    },
                    "> .ms-Checkbox-text": disabled ? fontColorNeutralTertiary(theme) : fontColorNeutralPrimary(theme)
                }
            }
        };
        const checkbox = [
            flexChildSizeFixed(checkboxSize),
            {
                width: checkboxSize,
                height: checkboxSize,
                backgroundColor: checkboxBackgroundColor(theme, checked, disabled),
                borderColor: checkboxBorderColor(theme, checked, disabled),
                // This checkmark style is defined with selector to have higher specificity than Loop's default styling
                "> .ms-Checkbox-checkmark": flexParent()
            },
            isLoop && {
                borderRadius: "3px"
            }
        ];
        const checkmark = {
            color: checkmarkColor(theme, checked)
        };
        const text = disabled ? fontColorNeutralTertiary(theme) : fontColorNeutralSecondary(theme);
        const checkboxStyles = {
            root,
            checkbox,
            checkmark,
            text
        };
        return concatStyleSetsWithProps(props, checkboxStyles, customStyles);
    });
const checkboxBorderColor = (theme, checked = false, disabled = false)=>{
    if (disabled) {
        return Colors.BorderColorNeutralTertiaryAlt;
    }
    if (checked) {
        switch(theme.name){
            case UnoTheme.TeamsContrast:
                return Colors.TeamsContrastHyperlinkColor;
            default:
                return themePrimaryColor(theme);
        }
    }
    switch(theme.name){
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            return NeutralColors.gray60;
        case UnoTheme.TeamsContrast:
            return Colors.White;
        case UnoTheme.SharePoint:
            return Colors.SharepointTextFontColor();
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
        default:
            return Colors.NeutralSecondary;
    }
};
const checkboxActiveBorderColor = (theme, checked = false, disabled = false)=>{
    if (disabled) {
        return Colors.BorderColorNeutralTertiaryAlt;
    }
    if (checked) {
        switch(theme.name){
            case UnoTheme.TeamsLight:
                return Colors.TeamsAppBrand04;
            case UnoTheme.TeamsDark:
                return Colors.TeamsAppBrand00;
            case UnoTheme.TeamsContrast:
                return Colors.White;
            case UnoTheme.LoopWorkspaceLight:
                return Colors.LoopBrand70;
            case UnoTheme.LoopWorkspaceDark:
                return Colors.LoopBrand110;
            case UnoTheme.Light:
            case UnoTheme.LoopLight:
                return Colors.PlannerSecondary;
            case UnoTheme.Dark:
            case UnoTheme.LoopDark:
                return Colors.PlannerDarkSecondary;
            default:
                return Colors.ThemeSecondary;
        }
    }
    switch(theme.name){
        case UnoTheme.TeamsLight:
            return Colors.TeamsAppBrand00;
        case UnoTheme.TeamsDark:
            return Colors.TeamsAppBrand08;
        case UnoTheme.TeamsContrast:
            return Colors.White;
        case UnoTheme.LoopWorkspaceLight:
            return Colors.LoopBrand70;
        case UnoTheme.LoopWorkspaceDark:
            return Colors.LoopBrand110;
        case UnoTheme.Light:
        case UnoTheme.LoopLight:
            return Colors.PlannerPrimary;
        case UnoTheme.Dark:
        case UnoTheme.LoopDark:
            return Colors.PlannerDarkPrimary;
        default:
            return Colors.BorderColorNeutralPrimary;
    }
};
const checkboxBackgroundColor = (theme, checked = false, disabled = false)=>{
    if (checked || disabled) {
        return checkboxBorderColor(theme, checked, disabled);
    }
    return "";
};
const checkboxActiveBackgroundColor = (theme, checked = false, disabled = false)=>{
    if (disabled) {
        return checkboxActiveBorderColor(theme, checked, disabled);
    }
    if (checked) {
        switch(theme.name){
            case UnoTheme.TeamsContrast:
                return Colors.Black;
            default:
                return checkboxActiveBorderColor(theme, checked, disabled);
        }
    }
    return "";
};
const checkmarkColor = (theme, checked = false)=>{
    if (checked) {
        switch(theme.name){
            case UnoTheme.TeamsDark:
            case UnoTheme.TeamsContrast:
            case UnoTheme.LoopDark:
            case UnoTheme.LoopWorkspaceDark:
                return Colors.Black;
            case UnoTheme.Light:
            case UnoTheme.Dark:
            case UnoTheme.TeamsLight:
            case UnoTheme.LoopLight:
            case UnoTheme.LoopWorkspaceLight:
            default:
                return Colors.White;
        }
    }
    // Not checked, checkmark is not displayed
    return "";
};
const checkmarkActiveColor = (theme, checked = false)=>{
    if (checked) {
        switch(theme.name){
            case UnoTheme.TeamsDark:
                return Colors.Black;
            case UnoTheme.TeamsContrast:
            case UnoTheme.LoopLight:
            case UnoTheme.LoopDark:
            case UnoTheme.LoopWorkspaceLight:
            case UnoTheme.LoopWorkspaceDark:
                return Colors.White;
            default:
                return "";
        }
    }
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.LoopLight:
            return Colors.PlannerPrimary;
        case UnoTheme.Dark:
        case UnoTheme.LoopDark:
            return Colors.PlannerDarkPrimary;
        case UnoTheme.TeamsLight:
            return Colors.TeamsAppBrand00;
        case UnoTheme.TeamsDark:
            return Colors.TeamsAppBrand08;
        case UnoTheme.TeamsContrast:
            return Colors.White;
        case UnoTheme.LoopWorkspaceLight:
            return Colors.LoopBrand70;
        case UnoTheme.LoopWorkspaceDark:
            return Colors.LoopBrand110;
        default:
            return "";
    }
};
