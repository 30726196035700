// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { LoopBorderRadius, LoopLightHoverShadow, LoopDarkHoverShadow, PlannerInputHeight } from "../../../constants/StyleConstants";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Styles
import { memoizeFunction } from "@fluentui/react";
import { dialogControlPlaceholderStyle, dialogControlSecondaryStyle, dialogControlStyle, fontColorDisabled, fontColorNeutralPrimary, fontColorNeutralSecondary, selectedControlStyle } from "../../../utilities/Mixin.styles";
// Utilities
import { isWindowsHighContrast, isLoopTheme } from "../../../utilities/ThemingUtilities";
/**
 * Helper to get styles for DatePicker
 * @param theme theme
 */ export const getDatePickerStyles = memoizeFunction((theme)=>{
    const isLoop = isLoopTheme(theme);
    const root = {
        "&.is-open, &:focus": {
            "> div .ms-TextField-wrapper > .ms-TextField-fieldGroup": [
                !isWindowsHighContrast() && selectedControlStyle(theme),
                isLoop && {
                    borderBottomWidth: "2px"
                }
            ]
        }
    };
    const callout = [
        {
            ".ms-Callout-main": [
                isLoop && {
                    borderRadius: LoopBorderRadius
                },
                theme.name === UnoTheme.TeamsContrast && {
                    border: `1px solid ${Colors.White}`
                }
            ]
        },
        isLoop && {
            borderRadius: LoopBorderRadius,
            boxShadow: theme.name === UnoTheme.LoopLight ? LoopLightHoverShadow : LoopDarkHoverShadow
        }
    ];
    return {
        root,
        callout
    };
});
/**
 * Helper to get styles for textField in DatePicker
 * @param theme theme
 * @param isDisabled Whether or not the textfield is disabled
 */ export const getTextFieldStyles = memoizeFunction((theme, isDisabled)=>{
    const isLoop = isLoopTheme(theme);
    let msTextFieldFieldBorderRadius = "";
    let msTextFieldFieldGroupIconColor = "";
    let msTextFieldFieldGroupIconHoverColor = "";
    switch(theme.name){
        case UnoTheme.Light:
            msTextFieldFieldGroupIconHoverColor = Colors.NeutralDark;
            break;
        case UnoTheme.Dark:
            msTextFieldFieldGroupIconColor = Colors.NeutralTertiaryAlt;
            msTextFieldFieldGroupIconHoverColor = Colors.NeutralLight;
            break;
        case UnoTheme.TeamsLight:
            msTextFieldFieldBorderRadius = "3px";
            msTextFieldFieldGroupIconHoverColor = Colors.NeutralDark;
            break;
        case UnoTheme.TeamsDark:
            msTextFieldFieldBorderRadius = "3px";
            msTextFieldFieldGroupIconColor = Colors.NeutralTertiaryAlt;
            msTextFieldFieldGroupIconHoverColor = Colors.NeutralLight;
            break;
        case UnoTheme.TeamsContrast:
            msTextFieldFieldBorderRadius = "3px";
            msTextFieldFieldGroupIconColor = Colors.White;
            msTextFieldFieldGroupIconHoverColor = Colors.TeamsContrastHyperlinkColor;
            break;
        case UnoTheme.LoopLight:
            msTextFieldFieldGroupIconColor = Colors.NeutralLightGrey38;
            break;
        case UnoTheme.SharePoint:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceLight:
        case UnoTheme.LoopWorkspaceDark:
        default:
            break;
    }
    // Keep styling priorities for fieldGroup
    const root = {
        ".ms-TextField-wrapper": {
            "> .ms-Label": fontColorNeutralSecondary(theme),
            "> .ms-TextField-fieldGroup": [
                isLoop ? dialogControlSecondaryStyle(theme) : dialogControlStyle(theme),
                theme.name === UnoTheme.SharePoint && {
                    border: "1px",
                    borderColor: Colors.SharepointCardSeparator(),
                    borderStyle: "solid"
                }
            ],
            "> .ms-TextField-fieldGroup > .ms-TextField-field": {
                "::placeholder": [
                    isDisabled && fontColorDisabled(theme)
                ]
            }
        },
        "&.is-active": {
            ".ms-TextField-wrapper > .ms-TextField-fieldGroup": [
                selectedControlStyle(theme),
                (theme.name === UnoTheme.LoopLight || theme.name === UnoTheme.LoopDark) && {
                    borderBottomWidth: "2px"
                }
            ]
        }
    };
    const fieldGroup = [
        {
            height: PlannerInputHeight,
            "> .ms-TextField-field": [
                fontColorNeutralPrimary(theme),
                dialogControlPlaceholderStyle(theme),
                {
                    borderRadius: msTextFieldFieldBorderRadius
                },
                isDisabled && [
                    fontColorDisabled(theme),
                    {
                        background: "none transparent",
                        border: "none"
                    }
                ]
            ],
            "i.ms-DatePicker-event--with-label": [
                {
                    color: msTextFieldFieldGroupIconColor
                },
                isDisabled && fontColorDisabled(theme)
            ],
            "&:hover, &:focus": {
                "i.ms-DatePicker-event--with-label": [
                    {
                        color: msTextFieldFieldGroupIconHoverColor
                    },
                    isDisabled && fontColorDisabled(theme)
                ]
            }
        },
        theme.name === UnoTheme.LoopLight && {
            borderRadius: "0px 0px 4px 4px"
        }
    ];
    return {
        root,
        fieldGroup
    };
});
