// Constants
// Styles
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
// Styles
import { backgroundColorNeutralPrimary, fontColorNeutralPrimary, themedButtonStyle, buttonStyle } from "../../../utilities/Mixin.styles";
export const checkUnsavedApprovalDialogWidth = "600px";
const buttonBorderRadius = "5px";
/**
 * Helper to get styles for AddApproverDraft
 * @param theme Planner theme
 * @param [customStyles] Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const CheckUnsavedApprovalDialogStyles = {
        dialog: [
            fontColorNeutralPrimary(theme),
            {
                ".ms-Dialog-main": {
                    borderRadius: "10px"
                }
            }
        ],
        dialogButtons: {
            "&.ms-Button--primary": [
                {
                    borderRadius: buttonBorderRadius
                },
                themedButtonStyle(theme)
            ],
            "&.ms-Button--default": [
                {
                    borderRadius: buttonBorderRadius
                },
                buttonStyle(theme)
            ]
        }
    };
    return concatStyleSets(CheckUnsavedApprovalDialogStyles, customStyles);
});
export const getDialogStyles = memoizeFunction((theme)=>{
    const dialogContentStyles = {
        title: [
            fontColorNeutralPrimary(theme),
            backgroundColorNeutralPrimary(theme)
        ],
        topButton: {
            ".ms-Dialog-button": [
                fontColorNeutralPrimary(theme),
                backgroundColorNeutralPrimary(theme),
                {
                    ":hover": [
                        fontColorNeutralPrimary(theme),
                        {
                            backgroundColor: "transparent"
                        }
                    ]
                }
            ]
        },
        inner: [
            fontColorNeutralPrimary(theme),
            backgroundColorNeutralPrimary(theme)
        ]
    };
    return dialogContentStyles;
});
/**
 * Helper to get classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        dialog: mergeStyles("dialog", styles.dialog),
        dialogButtons: mergeStyles("dialogButtons", styles.dialogButtons)
    }));
