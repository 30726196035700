// Models
import { FileTypesWithPreview, SupportedReferenceTypes } from "./ITaskAttachmentType";
import { TaskAttachmentRowItemBuilder } from "./TaskAttachmentRowItemBuilder";
// Utilities
import { compareStringForDisplay } from "@ms/uno-utilities/lib/local/Comparators";
import { getValidUrl } from "@ms/uno-utilities/lib/local/UrlUtilities";
import indexOf from "lodash/indexOf";
/**
 * Model to represent the attachment to be render in task details.
 * It can be a file being uploaded or an existing attachment
 */ export class TaskAttachmentRowItem {
    /** Builder for the Task attachment row item objects */ static get builder() {
        return new TaskAttachmentRowItemBuilder();
    }
    /**
     * Comparator for alias based sort
     * @param item1 First task attachment row item
     * @param item2 Second task attachment row item
     */ static aliasCompare(item1, item2) {
        return compareStringForDisplay(item1?.alias?.toUpperCase(), item2?.alias?.toUpperCase());
    }
    hasKnownExtension() {
        return this.taskAttachmentRowItemType.taskAttachmentType !== SupportedReferenceTypes.Other || !!this.taskAttachmentRowItemType.specificType && indexOf(FileTypesWithPreview, this.taskAttachmentRowItemType.specificType.toLowerCase()) >= 0;
    }
    /**
     * Returns true if the URL is a valid one
     */ isUrlValid() {
        return !!getValidUrl(this.url);
    }
    /**
     * Initializes a new instance of the TaskAttachmentRowItem class
     * @param taskAttachmentRowItemBuilder Builder object to use for construction
     */ constructor(taskAttachmentRowItemBuilder){
        this.id = taskAttachmentRowItemBuilder.id;
        this.alias = taskAttachmentRowItemBuilder.alias;
        this.url = taskAttachmentRowItemBuilder.url;
        this.isShownInTaskCard = taskAttachmentRowItemBuilder.isShownInTaskCard;
        this.documentPreviewThumbnail = taskAttachmentRowItemBuilder.documentPreviewThumbnail;
        this.taskAttachmentRowItemType = taskAttachmentRowItemBuilder.taskAttachmentRowItemType;
        this.uploadProgress = taskAttachmentRowItemBuilder.uploadProgress;
        this.isBeingUploaded = taskAttachmentRowItemBuilder.isBeingUploaded;
    }
}
