/**
 * Flag enum for form permissions
 */ export var FormPermissions;
(function(FormPermissions) {
    /** No permissions disabled */ FormPermissions[FormPermissions["DisableNone"] = 0] = "DisableNone";
    /** Cannot make any changes related to form requirement on the task */ FormPermissions[FormPermissions["DisableUpdateFormRequirement"] = 1] = "DisableUpdateFormRequirement";
    /** Cannot view all form responses */ FormPermissions[FormPermissions["DisableViewAllFormResponses"] = 2] = "DisableViewAllFormResponses";
    /** Cannot submit a form response */ FormPermissions[FormPermissions["DisableSubmitForm"] = 4] = "DisableSubmitForm";
    /** All permissions disabled */ FormPermissions[FormPermissions["DisableAll"] = 7] = "DisableAll";
})(FormPermissions || (FormPermissions = {}));
/**
 * Returns true if the given form permission is disabled
 * @param permissionSet The permission set to check against
 * @param permission The permission to check
 */ export function isFormPermissionDisabled(permissionSet, permission) {
    "use strict";
    return (permissionSet & permission) !== 0;
}
