// Constants
import { VirtualListOrientation } from "./VirtualListWrapper";
// Styles
import { memoizeFunction, mergeStyles } from "@fluentui/react";
/**
 * Helper to get styles for VirtualListWrapper
 * @param orientation VirtualListOrientation
 */ export const getStyles = memoizeFunction((orientation)=>{
    const VirtualListWrapperStyles = {
        anchor: [
            orientation === VirtualListOrientation.Horizontal && {
                // Make sure the anchor isn't 0px by 0px to avoid issues with InView not being called on old versions of Edge
                minWidth: "1px"
            },
            orientation === VirtualListOrientation.Vertical && {
                // Make sure the anchor isn't 0px by 0px to avoid issues with InView not being called on old versions of Edge
                minHeight: "1px"
            }
        ]
    };
    return VirtualListWrapperStyles;
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        anchor: mergeStyles("virtualListAnchor", styles.anchor)
    }));
