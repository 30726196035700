// Models
import { Form } from "../legacyPlanner/form/Form";
import { SupportedReferenceTypes } from "./ITaskAttachmentType";
import { TaskAttachment } from "./TaskAttachment";
import { TaskAttachmentRowItem } from "./TaskAttachmentRowItem";
import { TaskAttachmentType } from "./TaskAttachmentType";
// Utilities
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
/**
 * Configuration and building of TaskAttachmentRowItem objects
 */ export class TaskAttachmentRowItemBuilder {
    /**
     * Build out the TaskAttachmentRowItem
     */ build() {
        return new TaskAttachmentRowItem(this);
    }
    /**
     * Create the task attachment row item from a drive item upload session
     * @param driveItemUploadSession The drive item upload session to create the TaskAttachmentRowItem from
     */ fromDriveItemUploadSession(driveItemUploadSession) {
        this.id = driveItemUploadSession.id;
        this.alias = driveItemUploadSession.itemName ? driveItemUploadSession.itemName.substring(0, TaskAttachment.AliasMaxLength) : "";
        this.url = driveItemUploadSession.uploadUrl;
        this.isShownInTaskCard = false;
        this.documentPreviewThumbnail = "";
        this.taskAttachmentRowItemType = driveItemUploadSession.itemName ? TaskAttachmentType.builder.fromFileName(driveItemUploadSession.itemName).build() : TaskAttachmentType.builder.withPropertyBag({
            taskAttachmentType: SupportedReferenceTypes.Other
        }).build();
        this.isBeingUploaded = true;
        this.uploadProgress = driveItemUploadSession.itemSize ? driveItemUploadSession.bytesSent / driveItemUploadSession.itemSize : 1;
        return this;
    }
    /**
     * Create the task attachment row item from a task attachment
     * @param taskAttachment The task attachment to create the TaskAttachmentRowItem from
     * @param thumbnail The attachment thumbnail
     */ fromTaskAttachment(taskAttachment, thumbnail) {
        this.id = taskAttachment.url;
        this.alias = taskAttachment.alias;
        this.url = taskAttachment.url;
        this.isShownInTaskCard = taskAttachment.isShownInTaskCard;
        this.documentPreviewThumbnail = thumbnail;
        this.taskAttachmentRowItemType = taskAttachment.taskAttachmentType;
        this.isBeingUploaded = false;
        this.uploadProgress = 1;
        return this;
    }
    /**
     * Create the task attachment row item from a form
     * @param form The form to create the TaskAttachmentRowItem from
     */ fromForm(form, urlType) {
        this.id = form.formId;
        this.alias = form.displayName;
        this.url = Form.getFormUrl(form.urlFormId, urlType, form);
        this.isShownInTaskCard = false;
        this.documentPreviewThumbnail = "";
        this.taskAttachmentRowItemType = TaskAttachmentType.builder.withPropertyBag({
            taskAttachmentType: SupportedReferenceTypes.Form
        }).build();
        this.isBeingUploaded = false;
        this.uploadProgress = 1;
        return this;
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.id = propertyBag.id || this.id;
        this.alias = propertyBag.alias || this.alias;
        this.url = propertyBag.url || this.url;
        this.isShownInTaskCard = propertyBag.isShownInTaskCard ? propertyBag.isShownInTaskCard : !!this.isShownInTaskCard;
        this.documentPreviewThumbnail = propertyBag.documentPreviewThumbnail || this.documentPreviewThumbnail;
        this.taskAttachmentRowItemType = propertyBag.taskAttachmentRowItemType || (propertyBag.url ? TaskAttachmentType.builder.withPropertyBag({
            url: propertyBag.url
        }).build() : null) || this.taskAttachmentRowItemType;
        this.isBeingUploaded = propertyBag.isBeingUploaded ? propertyBag.isBeingUploaded : !!this.isBeingUploaded;
        this.uploadProgress = propertyBag.uploadProgress || this.uploadProgress;
        return this;
    }
    /**
     * Initialize an instance of the TaskAttachmentRowItem builder
     */ constructor(){
        this.id = generateGuid();
        this.alias = "";
        this.url = "";
        this.isShownInTaskCard = false;
        this.documentPreviewThumbnail = "";
        this.taskAttachmentRowItemType = TaskAttachmentType.builder.withPropertyBag({
            taskAttachmentType: SupportedReferenceTypes.Other
        }).build();
        this.isBeingUploaded = false;
        this.uploadProgress = -1;
    }
}
