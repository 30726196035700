import { concatStyleSets, concatStyleSetsWithProps, memoizeFunction } from "@fluentui/react";
/**
 * Get styles for callout that contains the tooltip
 * @param theme App theme in planner
 * @param customStyles Optional set of custom styles that override/merge with the default styles applied to the component
 */ export const getCalloutStyles = memoizeFunction((theme, customStyles)=>{
    return concatStyleSets({
        container: {},
        root: {
            color: theme.palette.neutralPrimary,
            backgroundColor: theme.semanticColors.bodyBackground
        },
        beak: [
            {
                backgroundColor: "inherit"
            }
        ],
        beakCurtain: {
            backgroundColor: "inherit"
        },
        calloutMain: {
            backgroundColor: "inherit"
        }
    }, customStyles);
});
/**
 * Gets a style function for resolving the set of custom styles for a Tooltip component
 * @param customStyles Optional set of custom styles that override/merge with the default styles applied to the component
 */ export const getTooltipStyleFunction = memoizeFunction((styles)=>(props)=>{
        return concatStyleSetsWithProps(props, tooltipStyles, styles);
    });
/**
 * Custom styles for tooltip
 */ export const tooltipStyles = {
    root: {},
    content: {
        color: "inherit"
    },
    subText: {}
};
