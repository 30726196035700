// Models
import { SupportedReferenceTypes } from "@ms/uno-models/lib/local/client/taskAttachment/ITaskAttachmentType";
// Styles
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
/**
 * Helper to get styles for DocumentPreviewIcon
 * @param fileType SupportedReferenceTypes
 * @param customStyles Any custom style overrides
 */ export const getDocumentPreviewIconStyles = memoizeFunction((fileType, customStyles)=>{
    let color = Colors.GenericIconColor;
    switch(fileType){
        case SupportedReferenceTypes.Excel:
            color = Colors.ExcelIconColor;
            break;
        case SupportedReferenceTypes.PowerPoint:
            color = Colors.PowerPointIconColor;
            break;
        case SupportedReferenceTypes.Word:
            color = Colors.WordIconColor;
            break;
        case SupportedReferenceTypes.OneNote:
            color = Colors.OneNoteIconColor;
            break;
        case SupportedReferenceTypes.Pdf:
            color = Colors.PdfIconColor;
            break;
        case SupportedReferenceTypes.Project:
            color = Colors.ProjectIconColor;
            break;
        case SupportedReferenceTypes.Visio:
            color = Colors.VisioIconColor;
            break;
        default:
            break;
    }
    const root = {
        color,
        "> svg": {
            fill: color
        }
    };
    const documentPreviewIconStyles = {
        root
    };
    return concatStyleSets(documentPreviewIconStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param fileType SupportedReferenceTypes
 * @param customStyles Any custom style overrides
 */ export const getDocumentPreviewIconClassNames = memoizeFunction((fileType, customStyles)=>{
    const styles = getDocumentPreviewIconStyles(fileType, customStyles);
    return {
        root: mergeStyles("documentPreviewIcon", styles.root)
    };
});
