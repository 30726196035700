/**
 * Flag enum for checklist permissions
 */ export var ChecklistPermissions;
(function(ChecklistPermissions) {
    ChecklistPermissions[ChecklistPermissions["DisableNone"] = 0] = "DisableNone";
    ChecklistPermissions[ChecklistPermissions["DisableCheckedStateToggling"] = 1] = "DisableCheckedStateToggling";
    ChecklistPermissions[ChecklistPermissions["DisableReorder"] = 2] = "DisableReorder";
    ChecklistPermissions[ChecklistPermissions["DisableEditTitle"] = 4] = "DisableEditTitle";
    ChecklistPermissions[ChecklistPermissions["DisablePromote"] = 8] = "DisablePromote";
    ChecklistPermissions[ChecklistPermissions["DisableDelete"] = 16] = "DisableDelete";
    ChecklistPermissions[ChecklistPermissions["DisableCreate"] = 32] = "DisableCreate";
    ChecklistPermissions[ChecklistPermissions["DisableAll"] = 63] = "DisableAll";
})(ChecklistPermissions || (ChecklistPermissions = {}));
/**
 * Returns true if the given checklist permission is disabled
 * @param permissionSet The permission set to check against
 * @param permission The permission to check
 */ export function isChecklistPermissionDisabled(permissionSet, permission) {
    return (permissionSet & permission) !== 0;
}
