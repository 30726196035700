import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
import { flexChildSizeFixed, flexChildSizeFlexible, backgroundColorHover, flexParent, fontColorDisabled, fontColorNeutralPrimary } from "../../../utilities/Mixin.styles";
import { dndGripperWidth } from "./ChecklistContainer.styles";
/**
 * Checklist Container Style constants
 */ const checklistOnTaskDetailsVerticalSize = "30px";
const checklistOnTaskDetailsItemHorizontalPadding = "7px";
const checklistOnTaskDetailsCommandButtonWidth = "24px";
export const checklistItemCheckmarkIconWidth = "12px";
/**
 * Helper to get styles for the Checklist Item
 * @param theme Current app theme
 * @param customStyles Any custom style overrides
 * @param isReadonly Whether the checklist item is read-only or not
 * @param isChecked Whether the checklist item is checked or unchecked
 */ export const getStyles = memoizeFunction((theme, customStyles, isReadonly, isChecked)=>{
    const ChecklistItemStyles = {
        root: [
            {
                margin: "0",
                height: checklistOnTaskDetailsVerticalSize,
                ".ms-Checkbox-checkbox": {
                    borderRadius: "50%"
                },
                ".ms-Checkbox-checkmark": {
                    width: checklistItemCheckmarkIconWidth
                },
                // Align the new checklist item with existing items that have the drag-and-drop gripper
                "&.new": {
                    marginLeft: dndGripperWidth
                }
            },
            theme.name === UnoTheme.TeamsContrast && {
                "> .dndListItem": {
                    ":hover": {
                        color: Colors.HighContrastYellow
                    },
                    "> .listItemContents:hover": {
                        "> .ms-Checkbox, .ms-Checkbox.is-checked:hover": {
                            "> .ms-Checkbox-label > .ms-Checkbox-checkbox": {
                                borderColor: Colors.Black
                            }
                        },
                        "> .ms-Checkbox:hover > .ms-Checkbox-label > .ms-Checkbox-checkbox > .ms-Checkbox-checkmark": !isChecked && {
                            color: Colors.Black
                        },
                        "> .ms-Checkbox.is-checked.is-disabled:hover > .ms-Checkbox-label > .ms-Checkbox-checkbox > .ms-Checkbox-checkmark": {
                            color: Colors.Black
                        }
                    }
                }
            },
            !isReadonly && "&:focus, &:focus-within, &:active" && {
                "> .dndListItem": {
                    "> i": {
                        opacity: "1"
                    }
                }
            }
        ],
        checklistItemTitle: [
            flexChildSizeFlexible(),
            {
                display: "inline-block",
                height: checklistOnTaskDetailsVerticalSize,
                lineHeight: checklistOnTaskDetailsVerticalSize,
                maxWidth: "100%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                padding: `0 ${checklistOnTaskDetailsItemHorizontalPadding}`,
                "&.completed": {
                    textDecoration: "line-through"
                }
            },
            isChecked && {
                textDecoration: "line-through"
            },
            isReadonly && [
                fontColorDisabled(theme),
                {
                    cursor: "default"
                }
            ]
        ],
        checklistItemCommands: [
            flexChildSizeFixed(),
            {
                display: "none",
                height: checklistOnTaskDetailsVerticalSize,
                lineHeight: checklistOnTaskDetailsVerticalSize,
                margin: "0 5px",
                ".ms-Button": {
                    color: "inherit",
                    backgroundColor: "inherit",
                    height: checklistOnTaskDetailsVerticalSize,
                    width: checklistOnTaskDetailsCommandButtonWidth,
                    margin: 0,
                    padding: 0
                },
                "&.forceShow": {
                    display: "block"
                }
            }
        ],
        listItemContents: [
            flexParent(),
            flexChildSizeFlexible(),
            fontColorNeutralPrimary(theme),
            {
                height: checklistOnTaskDetailsVerticalSize,
                position: "relative",
                alignItems: "center",
                ".ms-Checkbox": [
                    flexChildSizeFixed()
                ],
                "> form": [
                    flexChildSizeFlexible(),
                    {
                        "> .ms-TextField.checklistTextField> .ms-TextField-wrapper > div": {
                            height: checklistOnTaskDetailsVerticalSize,
                            "input, textarea": {
                                height: checklistOnTaskDetailsVerticalSize,
                                padding: `0 ${checklistOnTaskDetailsItemHorizontalPadding}`
                            }
                        }
                    },
                    isReadonly && {
                        "> .ms-TextField.checklistTextField > .ms-TextField-wrapper > div": {
                            input: {
                                "::placeholder": [
                                    fontColorDisabled(theme)
                                ]
                            }
                        }
                    }
                ]
            },
            !isReadonly && {
                "&:hover, &:focus, &:active": [
                    backgroundColorHover(theme),
                    {
                        outline: "none",
                        "> .checklistItemCommands": {
                            display: "block"
                        }
                    }
                ]
            }
        ]
    };
    return concatStyleSets(ChecklistItemStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles for the Checklist Item component
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("checklistItem", styles.root),
        checklistItemTitle: mergeStyles("checklistItemTitle", styles.checklistItemTitle),
        checklistItemCommands: mergeStyles("checklistItemCommands", styles.checklistItemCommands),
        listItemContents: mergeStyles("listItemContents", styles.listItemContents)
    };
});
