// Models
import { ChecklistPermissions } from "./ChecklistPermissions";
import { AttachmentPermissions } from "./AttachmentPermissions";
/**
 * Default perms to use when data is not available.
 */ export const DefaultTaskContainerPermissions = {
    attachmentPermissions: AttachmentPermissions.DisableNone,
    canAssign: true,
    canDelete: false,
    checklistPermissions: ChecklistPermissions.DisableNone,
    canEditDescription: true,
    canEditTitle: true,
    canUpdateDueDate: true,
    canUpdatePriority: true,
    canUpdatePriorityToUrgent: true,
    canUpdateProgress: true,
    canUpdateAppliedLabels: true,
    canUpdateBucket: true,
    canUpdateStartDate: true,
    canUpdateRecurrence: true,
    canUpdatePreviewType: true
};
