// Constants
import { SharePointAssociationType, TeamsAppAssociationType } from "@ms/uno-constants/lib/local/AppConstants";
// Models
import { ContextState } from "./ContextState";
// Utilities
import { getBreadcrumbString } from "@ms/uno-utilities/lib/local/StringUtilities";
import { isTeamsPlannerClientEntraAppId, isSharepointAppId } from "@ms/uno-utilities/lib/local/AppUtilities";
/**
 * Takes the display names array from plan context and returns a display string for the context.
 * This is used to display the context in the UI.
 * @param planContext - The plan context to get the display text for.
 * @param containerDisplayname - The current display name of the container (e.g. group name) that the plan is in.
 */ export function getSyncContextDisplayText(planContext, containerDisplayname) {
    return getBreadcrumbString(getLatestDisplayNames(planContext, containerDisplayname));
}
/**
 * Numeric ContextState value to ContextStateName string converter
 * @param contextState State of a plan context
 * Bug 5820481: Remove plan context details' status field's converter once TodoWeb's fix is in
 */ export function ConvertContextStateToContextStateName(contextState) {
    if (contextState < 0 || contextState > 1) {
        throw new Error("ArgumentException: Invalid ContextState value");
    }
    let contextStateName;
    if (contextState === 0) {
        contextStateName = ContextState.Active;
    } else if (contextState === 1) {
        contextStateName = ContextState.Delinked;
    }
    return contextStateName;
}
/**
 * Returns refreshed display names with refreshed values if current containerDisplayname is specified.
 * This will replace proper container display name with the given latest one. For instance, Teams context might have stale group name on stored values for [ <group name>, <channel name> ] .
 * If no containerDisplayname is specified it just returns displayNames.
 * @param planContext - The plan context to get the display text for.
 * @param containerDisplayname - The current display name of the container (e.g. group name) that the plan is in.
 */ export function getLatestDisplayNames(planContext, currentContainerDisplayName) {
    // If the plan is associated with a Teams/SPO, we want to show the current group name since displayNames[0] can have a stale group name not updatable on this client
    const { displayNames, ownerAppId, associationType } = planContext;
    if (!!currentContainerDisplayName && ownerAppId != null) {
        if (isTeamsPlannerClientEntraAppId(ownerAppId) && associationType === TeamsAppAssociationType.Board && displayNames.length === 2 && displayNames[0] !== currentContainerDisplayName) {
            return [
                currentContainerDisplayName,
                displayNames[1]
            ];
        } else if (isSharepointAppId(ownerAppId) && associationType === SharePointAssociationType.SPWeb && displayNames.length === 1 && displayNames[0] !== currentContainerDisplayName) {
            return [
                currentContainerDisplayName
            ];
        }
    }
    return displayNames;
}
