// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Styles
import { memoizeFunction } from "@fluentui/react";
import { backgroundColorHover, backgroundColorNeutralPrimary, fontColorNeutralPrimary, fontColorNeutralTertiary } from "../../../utilities/Mixin.styles";
import { highContrastColor, highContrastHighlightTextColor, highContrastSelectionText } from "../../../utilities/HighContrast.styles";
/**
 * Calendar picker highlighted style
 * @param theme The current theme
 */ const calendarPickerHighlighted = memoizeFunction((theme)=>{
    let color = "";
    let backgroundColor = "";
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.LoopLight:
            color = Colors.White;
            backgroundColor = Colors.ThemeSecondary;
            break;
        case UnoTheme.Dark:
        case UnoTheme.LoopDark:
            color = Colors.White;
            backgroundColor = Colors.ThemeSecondary;
            break;
        case UnoTheme.TeamsLight:
            color = Colors.Black;
            backgroundColor = Colors.TeamsAppBrand14;
            break;
        case UnoTheme.TeamsDark:
            color = Colors.White;
            backgroundColor = Colors.TeamsAppBrand04;
            break;
        case UnoTheme.TeamsContrast:
            color = Colors.Black;
            backgroundColor = Colors.TeamsContrastSelectedColor;
            break;
        case UnoTheme.LoopWorkspaceLight:
            color = Colors.White;
            backgroundColor = Colors.LoopBrand80;
            break;
        case UnoTheme.LoopWorkspaceDark:
            color = Colors.White;
            backgroundColor = Colors.LoopBrand100;
            break;
        case UnoTheme.SharePoint:
        default:
            break;
    }
    return {
        color,
        backgroundColor
    };
});
/**
 * datePicker hover background color
 * @param theme The current theme
 */ const datePickerBackgroundColorHover = memoizeFunction((theme)=>{
    let style = backgroundColorHover(theme);
    switch(theme.name){
        case UnoTheme.LoopWorkspaceLight:
            style = {
                backgroundColor: Colors.LoopBrand140
            };
            break;
        case UnoTheme.LoopWorkspaceDark:
            style = {
                backgroundColor: Colors.LoopBrand50
            };
            break;
        case UnoTheme.Light:
        case UnoTheme.Dark:
        case UnoTheme.TeamsLight:
        case UnoTheme.TeamsDark:
        case UnoTheme.TeamsContrast:
        case UnoTheme.SharePoint:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        default:
            break;
    }
    return style;
});
/**
 * Helper to get styles for the Calendar
 * @param theme The current theme
 */ export const getCalendarStyles = memoizeFunction((theme)=>{
    const root = [
        backgroundColorNeutralPrimary(theme),
        {
            "&:not(.ms-CalendarDay-daySelected)": {
                button: {
                    ":focus, :hover": [
                        fontColorNeutralPrimary(theme),
                        datePickerBackgroundColorHover(theme),
                        highContrastSelectionText(),
                        {
                            "i > svg": highContrastHighlightTextColor()
                        }
                    ]
                }
            }
        }
    ];
    const goTodayButton = [
        fontColorNeutralPrimary(theme),
        {
            marginBottom: "21px",
            ":hover, :focus": [
                datePickerBackgroundColorHover(theme),
                highContrastSelectionText(),
                {
                    borderRadius: "2px"
                }
            ]
        }
    ];
    return {
        root,
        goTodayButton
    };
});
/**
 * Helper to get styles for the Calendar Months section
 * @param theme The current theme
 */ export const getCalendarMonthStyles = memoizeFunction((theme)=>{
    const buttonStyle = [
        fontColorNeutralPrimary(theme),
        {
            ":hover": datePickerBackgroundColorHover(theme),
            "> i": {
                display: "block"
            }
        }
    ];
    return {
        currentItemButton: buttonStyle,
        itemButton: buttonStyle,
        navigationButton: buttonStyle
    };
});
/**
 * Helper to get styles for the Calendar Days section
 * @param theme The current theme
 */ export const getCalendarDayStyles = memoizeFunction((theme)=>{
    let datePickerDayTodayColor = "";
    let datePickerDayTodayBGColor = "";
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.LoopLight:
            datePickerDayTodayColor = Colors.White;
            datePickerDayTodayBGColor = Colors.FontColorThemeDarkAlt;
            break;
        case UnoTheme.Dark:
        case UnoTheme.LoopDark:
            datePickerDayTodayColor = Colors.White;
            datePickerDayTodayBGColor = Colors.FontColorThemeDark;
            break;
        case UnoTheme.TeamsLight:
            datePickerDayTodayColor = Colors.White;
            datePickerDayTodayBGColor = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            datePickerDayTodayColor = Colors.Black;
            datePickerDayTodayBGColor = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            datePickerDayTodayColor = Colors.Black;
            datePickerDayTodayBGColor = Colors.White;
            break;
        case UnoTheme.LoopWorkspaceLight:
            datePickerDayTodayColor = Colors.White;
            datePickerDayTodayBGColor = Colors.LoopBrand80;
            break;
        case UnoTheme.LoopWorkspaceDark:
            datePickerDayTodayColor = Colors.White;
            datePickerDayTodayBGColor = Colors.LoopBrand100;
            break;
        case UnoTheme.SharePoint:
        default:
            break;
    }
    const headerIconButton = [
        fontColorNeutralPrimary(theme),
        {
            ":hover": datePickerBackgroundColorHover(theme),
            "> i": {
                display: "block"
            }
        }
    ];
    const weekDayLabelCell = [
        fontColorNeutralPrimary(theme),
        highContrastColor(),
        {
            cursor: "default"
        }
    ];
    const monthAndYear = fontColorNeutralPrimary(theme);
    const dayOutsideNavigatedMonth = [
        fontColorNeutralTertiary(theme),
        highContrastColor()
    ];
    const dayCell = [
        fontColorNeutralPrimary(theme),
        {
            ":hover": {
                backgroundColor: "transparent !important"
            },
            "> button:hover": [
                datePickerBackgroundColorHover(theme),
                highContrastSelectionText()
            ]
        }
    ];
    const daySelected = [
        {
            "> button": [
                calendarPickerHighlighted(theme),
                highContrastSelectionText()
            ],
            "&.ms-CalendarDay-hoverStyle": {
                backgroundColor: "transparent !important"
            },
            ":hover": {
                backgroundColor: "transparent !important"
            },
            "::before": {
                border: "none !important"
            },
            backgroundColor: "transparent !important"
        }
    ];
    const dayIsToday = {
        color: `${datePickerDayTodayColor} !important`,
        backgroundColor: `${datePickerDayTodayBGColor} !important`,
        ":focus, :hover, :active": {
            borderRadius: "2px"
        }
    };
    return {
        headerIconButton,
        weekDayLabelCell,
        monthAndYear,
        dayOutsideNavigatedMonth,
        dayCell,
        daySelected,
        dayIsToday
    };
});
