// Constants
import { UserPersonaBubbleDiameter } from "../../../constants/StyleConstants";
import { FontWeights } from "@fluentui/react";
// Styles
import { concatStyleSets, FontSizes, memoizeFunction, mergeStyles } from "@fluentui/react";
import { buttonStyle, flexChildSizeFixed, flexChildSizeFlexible, flexParent, fontColorNeutralPrimary, fontColorNeutralSecondary, textClip } from "../../../utilities/Mixin.styles";
// Utilities
import { numberToPx, pxToNumber } from "@ms/uno-utilities/lib/local/StyleUtilities";
// Styling constants
const headerNamePadding = "10px";
const contentPadding = numberToPx(pxToNumber(UserPersonaBubbleDiameter) + pxToNumber(headerNamePadding));
/**
 * Helper to get styles for CommentCard
 * @param theme Planner theme
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const root = [
        flexParent(),
        {
            padding: "20px 0px"
        }
    ];
    const body = [
        flexChildSizeFlexible(),
        flexParent("column")
    ];
    const header = [
        flexChildSizeFixed(),
        flexParent()
    ];
    const commenterIcon = [
        flexChildSizeFixed(),
        {
            height: UserPersonaBubbleDiameter
        }
    ];
    const commenterName = [
        flexChildSizeFlexible(),
        fontColorNeutralSecondary(theme),
        textClip(),
        {
            padding: `0px ${headerNamePadding}`,
            alignSelf: "center",
            fontSize: FontSizes.small
        }
    ];
    const timestamp = [
        flexChildSizeFixed(),
        fontColorNeutralSecondary(theme),
        textClip(),
        {
            alignSelf: "center",
            fontSize: FontSizes.small,
            textAlign: "right"
        }
    ];
    const content = [
        flexChildSizeFixed(),
        fontColorNeutralPrimary(theme),
        {
            fontSize: FontSizes.medium,
            padding: `0px ${contentPadding}`,
            wordWrap: "break-word",
            '> .htmlContent [id$="hideInPlanner"]': {
                display: "none"
            }
        }
    ];
    const reloadCommentButton = [
        flexChildSizeFixed(),
        buttonStyle(theme),
        {
            fontSize: FontSizes.small,
            fontWeight: FontWeights.semibold,
            alignSelf: "flex-start",
            padding: "5px 10px",
            margin: "0px 0px 0px 40px",
            cursor: "pointer"
        }
    ];
    const commentCardStyles = {
        root,
        body,
        header,
        commenterIcon,
        commenterName,
        timestamp,
        content,
        reloadCommentButton
    };
    return concatStyleSets(commentCardStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("commentCard", styles.root),
        body: mergeStyles("body", styles.body),
        header: mergeStyles("header", styles.header),
        commenterIcon: mergeStyles("commenterIcon", styles.commenterIcon),
        commenterName: mergeStyles("commenterName", styles.commenterName),
        timestamp: mergeStyles("timestamp", styles.timestamp),
        content: mergeStyles("content", styles.content),
        reloadCommentButton: mergeStyles("reloadCommentButton", styles.reloadCommentButton)
    }));
