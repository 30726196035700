// Utilities
import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
/**
 * Create the email content for the first comment on a task
 * @param linkToTask Link to task where comment exists
 * @param linkToPlan Link to plan where the task exists
 * @param planTitle Title of plan where the task exists
 */ export function getFirstCommentEmailContent(linkToTask, linkToPlan, planTitle) {
    const secondLineString = Strings.SharedComponents_FirstCommentReplyTextLn2.replace("{0}", '<a href="' + linkToPlan + '">' + escapeCommentText(planTitle) + "</a>");
    return '<table id="jSanity_hideInPlanner"><tbody><tr><td style="padding:40px 0px 0px 0px"><table cellspacing="0" cellpadding="0" border="0" width="224px"><tbody><tr><td style="background:#6264a7; padding:10px 30px 12px 30px"><div align="center" style="text-align:center"><a href="' + linkToTask + '" target="_blank" style="text-decoration:none"><span style="font-size: 14px; font-family: Segoe UI, sans-serif, serif, EmojiFont; color: rgb(255, 255, 255); text-decoration: none;">' + Strings.SharedComponents_FirstCommentButtonText + '</span></a></div></td></tr></tbody></table></td></tr><tr><td style="padding:15px 0px 0px 0px"><div><span style="font-size:10px;color:#666666;font-family:Segoe UI, sans-serif, serif, EmojiFont;font-weight:lighter;">' + Strings.SharedComponents_FirstCommentReplyTextLn1 + '</span></div></td></tr><tr><td style="padding:10px 0px 0px 0px"><div><span style="font-size:10px;color:#666666;font-family:Segoe UI, sans-serif, serif, EmojiFont;font-weight:lighter;">' + secondLineString + "</span></div></td></tr></tbody></table>";
}
/**
 * Get email content for any comment that is not the first
 * @param linkToTask Link to task where comment exists
 * @param taskTitle Title of task where comment exists
 * @param linkToPlan Link to plan where the task exists
 * @param planTitle Title of plan where the task exists
 */ export function getCommentReplyEmailContent(linkToTask, taskTitle, linkToPlan, planTitle) {
    const firstLineString = Strings.SharedComponents_CommentTaglineLn1.replace("{0}", '<a href="' + linkToTask + '">' + escapeCommentText(taskTitle) + "</a>").replace("{1}", '<a href="' + linkToPlan + '">' + escapeCommentText(planTitle) + "</a>");
    const secondLineString = Strings.SharedComponents_CommentTaglineLn2.replace("{0}", '<a href="' + linkToTask + '">' + Strings.SharedComponents_ReplyInMicrosoftPlanner + "</a>");
    return '<table id="jSanity_hideInPlanner"><tbody><tr><td style="padding: 40px 0px 0px 0px"><div><span style="font-size:10px;color:#666666;font-family:Segoe UI, sans-serif, serif, EmojiFont;font-weight:lighter;">' + firstLineString + '</span></div></td></tr><tr><td style="padding: 10px 0px 0px 0px"><div><span style="font-size:10px;color:#666666;font-family:Segoe UI, sans-serif, serif, EmojiFont;font-weight:lighter;">' + secondLineString + "</span></div></td></tr></tbody></table>";
}
/**
 * Get the subject line for a conversation thread.
 * @param taskTitle Title of task the conversation thread will be created for
 */ export function getTaskCommentEmailSubject(taskTitle) {
    // Do not sanitize this string since it is only used as a parameter when creating a conversation thread.
    return Strings.SharedComponents_EmailSubjectText.replace("{0}", taskTitle);
}
/**
 * Convert HTML comment content into a plain text.
 * @param htmlContent HTML comment content
 */ export function getPlainTextFromHTMLContent(htmlContent) {
    // Get rid of HTML tags and return a plain text
    return htmlContent.replace(/(<([^>]+)>)/gi, "")// We must still parse &nbsp; because it still exists in previous comments before we switched to using ASCII code
    .replace(/(&nbsp;|&#32;)/g, " ").trim();
}
/**
 * Escape chars with html encoded value comment content into a plain text.
 * @param text comment text
 */ export function escapeCommentText(text) {
    // Replace < and > with html encoded value to avoid heavy handed exchange sanitizing
    // Newlines in comments get removed after posting, so replace with html line breaks
    return text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\r\n/g, "<br/>").replace(/\r/g, "<br/>").replace(/\n/g, "<br/>")// Use ASCII code for space instead of &nbsp; to avoid line-break issues
    .replace(/ /g, "&#32;");
}
