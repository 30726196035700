// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
// Styles
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { flexParent, fontMedium, fontSmall } from "../../../utilities/Mixin.styles";
/**
 * Helper to get styles for the Dnd List
 * @param [customStyles] Any custom style overrides
 */ export const getDndListStyles = memoizeFunction((customStyles)=>{
    const dndListItem = [
        flexParent(),
        {
            color: Colors.NeutralSecondary,
            alignItems: "center",
            listStyleType: "none",
            cursor: "pointer",
            "> i": {
                opacity: 0
            },
            ":not(.reorderDisabled)": {
                ":hover, :focus": {
                    "> i": {
                        opacity: 1
                    }
                }
            }
        }
    ];
    const errorMessage = [
        fontSmall,
        {
            padding: "5px 5px 5px 15px",
            color: Colors.DarkRed
        }
    ];
    const root = [
        fontMedium,
        {
            overflowY: "auto",
            paddingLeft: 0,
            listStyleType: "none",
            li: {
                margin: "10px 0px 10px 0px",
                "> .dndListItem": dndListItem,
                "> .errorMessage": errorMessage
            }
        }
    ];
    const dndListStyles = {
        root: root
    };
    return concatStyleSets(dndListStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles for the Dnd List component
 * @param customStyles The styles for the component
 */ export const getDndListClassNames = memoizeFunction((customStyles)=>{
    const styles = getDndListStyles(customStyles);
    return {
        root: mergeStyles("dndList", styles.root)
    };
});
