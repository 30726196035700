// Styles
import { mergeStyles, memoizeFunction, FontSizes, FontWeights, concatStyleSetsWithProps } from "@fluentui/react";
import { highContrastSelectionBorder, highContrastSelectionButton } from "../../../../utilities/HighContrast.styles";
import { fontMedium, flexChildSizeFlexible } from "../../../../utilities/Mixin.styles";
const attachmentButtonHeight = "35px";
const marginBetweenDetailsSections = "16px";
/**
 * Helper to get styles for TaskDetailsAttachmentsSection
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((customStyles)=>{
    const root = {
        margin: `${marginBetweenDetailsSections} 0px 0px 0px`
    };
    const emptyAttachmentsSection = {
        marginBottom: marginBetweenDetailsSections,
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.semibold
    };
    const hiddenFileInput = {
        display: "none"
    };
    const addAttachmentButtonWrapper = fontMedium;
    const taskDetailsAttachmentSectionSubComponentStyles = {
        list: {
            root: {
                "> ul": [
                    flexChildSizeFlexible(),
                    {
                        padding: "0px",
                        "> li": {
                            height: "48px",
                            marginBottom: "8px"
                        }
                    }
                ]
            }
        },
        collapsibleSection: {
            root: {
                marginBottom: marginBetweenDetailsSections
            }
        },
        addAttachmentButton: {
            root: [
                fontMedium,
                {
                    height: attachmentButtonHeight,
                    pointerEvents: "auto"
                }
            ],
            rootDisabled: {
                cursor: "default",
                opacity: "0.5"
            },
            rootHovered: [
                highContrastSelectionBorder,
                highContrastSelectionBorder()
            ],
            rootFocused: [
                highContrastSelectionButton,
                {
                    "::after": {
                        border: "none",
                        backgroundColor: "transparent"
                    }
                }
            ]
        }
    };
    const taskDetailsAttachmentsSectionStyles = {
        root,
        emptyAttachmentsSection,
        hiddenFileInput,
        addAttachmentButtonWrapper,
        subComponentStyles: taskDetailsAttachmentSectionSubComponentStyles
    };
    return concatStyleSetsWithProps(undefined, taskDetailsAttachmentsSectionStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("taskAttachments", styles.root),
        emptyAttachmentsSection: mergeStyles("emptyAttachmentsSection", styles.emptyAttachmentsSection),
        hiddenFileInput: mergeStyles("hiddenFileInput", styles.hiddenFileInput),
        addAttachmentButtonWrapper: mergeStyles("addAttachmentButtonWrapper", styles.addAttachmentButtonWrapper)
    }));
