/**
 * Flag enum for approval permissions
 */ export var ApprovalPermissions;
(function(ApprovalPermissions) {
    /** No permissions disabled */ ApprovalPermissions[ApprovalPermissions["DisableNone"] = 0] = "DisableNone";
    /** Cannot make any changes related to approval requirement on the task */ ApprovalPermissions[ApprovalPermissions["DisableUpdateApprovalRequirement"] = 1] = "DisableUpdateApprovalRequirement";
    /** Cannot make any changes related to approval request on the task */ ApprovalPermissions[ApprovalPermissions["DisableUpdateApprovalRequest"] = 2] = "DisableUpdateApprovalRequest";
    /** All permissions disabled */ ApprovalPermissions[ApprovalPermissions["DisableAll"] = 3] = "DisableAll";
})(ApprovalPermissions || (ApprovalPermissions = {}));
/**
 * Returns true if the given approval permission is disabled
 * @param permissionSet The permission set to check against
 * @param permission The permission to check
 */ export function isApprovalPermissionDisabled(permissionSet, permission) {
    "use strict";
    return (permissionSet & permission) !== 0;
}
