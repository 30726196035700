// Constants
import { ContextScenarioConstants } from "@ms/uno-constants/lib/local/ExternalSourceConstants";
// Models
import { TaskServiceEntityType } from "../client/legacyPlanner/ITaskServiceEntity";
import { PlanEntitySet } from "../client/legacyPlanner/plan/PlanEntitySet";
// Utilities
import escape from "lodash/escape";
/**
 * Generate an entity link
 * @param linkRoot Root for the link
 * @param entity Entity to generate link for
 * @param linkType Type of the link
 * @param channel Notification channel for the link
 * @param timestamp Creation timestamp for the link
 */ export function generateEntityHyperlink(linkRoot, entity, linkType, channel, timestamp) {
    switch(entity.entityType){
        case TaskServiceEntityType.Task:
            return generateTaskHyperLink(linkRoot, entity, linkType, channel, timestamp);
        case TaskServiceEntityType.Plan:
            return generatePlanHyperLink(linkRoot, entity, linkType, channel, timestamp);
        default:
            throw new Error("NotSupportedException: entity.type");
    }
}
/**
 * Generate an entity link
 * @param linkRoot Root for the link
 * @param entity Entity to generate link for
 * @param linkType Type of the link
 * @param channel Notification channel for the link
 * @param timestamp Creation timestamp for the link
 */ export function generateEntityLink(linkRoot, entity, linkType, channel, timestamp) {
    switch(entity.entityType){
        case TaskServiceEntityType.Task:
            return generateTaskLink(linkRoot, entity, linkType, channel, timestamp);
        case TaskServiceEntityType.Plan:
            return generatePlanLink(linkRoot, entity, linkType, channel, timestamp);
        default:
            throw new Error("NotSupportedException: entity.type");
    }
}
/**
 * Return external plan url
 * @param plan external plan for which url needs to be fetched
 * @param planDetails external plan details containing the url
 */ export function getPlanExternalUrl(plan, planDetails) {
    const entitySet = PlanEntitySet.builder().withPlan(plan).withDetails(planDetails).build();
    const contextId = entitySet.getCreationContextInfo()?.key;
    let url = null;
    if (contextId) {
        url = planDetails.contextDetails[contextId].url ?? null;
    }
    return url;
}
/**
 * Generate the hyperlink needed for the task
 * @param linkRoot Root for the link
 * @param task Task for which the hyperlink will be generated
 * @param linkType Type of the link
 * @param channel Notification channel for the link
 * @param timestamp Creation timestamp for the link
 */ function generateTaskHyperLink(linkRoot, task, linkType, channel, timestamp) {
    const taskLink = generateTaskLink(linkRoot, task, linkType, channel, timestamp);
    return '<a href="' + taskLink + '" target="_blank" rel="noopener noreferrer">' + escape(task.title) + "</a>";
}
/**
 * Generate the hyperlink needed for the task
 * @param linkRoot Root for the link
 * @param plan Plan for which the hyperlink will be generated
 * @param linkType Type of the link
 * @param channel Notification channel for the link
 * @param timestamp Creation timestamp for the link
 */ function generatePlanHyperLink(linkRoot, plan, linkType, channel, timestamp) {
    const planLink = generatePlanLink(linkRoot, plan, linkType, channel, timestamp);
    return '<a href="' + planLink + '" target="_blank" rel="noopener noreferrer">' + escape(plan.title) + "</a>";
}
/**
 * Generate the link needed for the task
 * @param linkRoot Root for the link
 * @param task Task for which the hyperlink will be generated
 * @param linkType Type of the link
 * @param channel Notification channel for the link
 * @param timestamp Creation timestamp for the link
 */ function generateTaskLink(linkRoot, task, linkType, channel, timestamp) {
    if (task.creationSource.external?.contextScenarioId === ContextScenarioConstants.project && task.creationSource.external?.webUrl) {
        return task.creationSource.external?.webUrl;
    }
    return generatePlannerLink(`${linkRoot}/Home/Task/${task.id}`, linkType, channel, timestamp);
}
/**
 * Generate the link needed for the task
 * @param plan Plan for which the hyperlink will be generated
 * @param linkType Type of the link
 * @param channel Notification channel for the link
 * @param timestamp Creation timestamp for the link
 */ function generatePlanLink(linkRoot, plan, linkType, channel, timestamp) {
    return generatePlannerLink(`${linkRoot}/Home/PlanViews/${plan.id}`, linkType, channel, timestamp);
}
/**
 * Generate the planner entity link with the proper telemetry embeddeed
 * @param linkRoot Root for the link
 * @param url Url for the link
 * @param linkType Type of the link
 * @param channel Notification channel for the link
 * @param timestamp Creation timestamp for the link
 */ function generatePlannerLink(url, linkType, channel, timestamp) {
    const epochTicks = 621355968000000000;
    const createdTime = epochTicks + timestamp.getTime() * 10000;
    return `${url}?Type=${linkType}&Channel=${channel}&CreatedTime=${createdTime}`;
}
