// Constants
import { FileTypeExtensions } from "@ms/uno-constants/lib/local/FileTypeExtensions";
// Models
import { DriveItemBuilder } from "./DriveItemBuilder";
import { SupportedReferenceTypes } from "../taskAttachment/ITaskAttachmentType";
// Utilities
import find from "lodash/find";
/** Represents a DriveItem entity in the client */ export class DriveItem {
    /** Builder for DriveItem objects */ static get builder() {
        return new DriveItemBuilder();
    }
    /**
     * Get the type from an SupportedReferenceTypes
     * @param fileType The type as a string we want to convert as enum
     */ static GetType(fileType) {
        const fileTypeLowerCase = fileType.toLowerCase();
        switch(fileTypeLowerCase){
            case "excel":
                return SupportedReferenceTypes.Excel;
            case "onenote":
                return SupportedReferenceTypes.OneNote;
            case "pdf":
                return SupportedReferenceTypes.Pdf;
            case "powerpoint":
                return SupportedReferenceTypes.PowerPoint;
            case "project":
                return SupportedReferenceTypes.Project;
            case "visio":
                return SupportedReferenceTypes.Visio;
            case "word":
                return SupportedReferenceTypes.Word;
            case "loop":
                return SupportedReferenceTypes.Loop;
            default:
                return SupportedReferenceTypes.Other;
        }
    }
    /**
     * Run a comparison method on each extension for each file type
     * @param fileName Name of the file
     */ static GetFileType(fileName) {
        let fileType = SupportedReferenceTypes.Other;
        const fileExtension = fileName.slice(fileName.lastIndexOf(".") + 1);
        for (const extensionType of Object.keys(FileTypeExtensions)){
            const fileExtensions = FileTypeExtensions[extensionType];
            const extension = find(fileExtensions, (extension)=>{
                return extension.toLowerCase() === fileExtension.toLowerCase();
            });
            if (extension) {
                fileType = DriveItem.GetType(extensionType);
                break;
            }
        }
        return fileType;
    }
    /**
     * Initializes a new instance of the DriveItemBuilder entity
     * @param driveItemBuilder Builder with the initialization data
     */ constructor(driveItemBuilder){
        this.id = driveItemBuilder.id;
        this.name = driveItemBuilder.name;
        this.url = driveItemBuilder.url;
        this.webDavUrl = driveItemBuilder.webDavUrl;
        this.fileType = driveItemBuilder.fileType;
        this.description = driveItemBuilder.description;
        this.modifiedDate = driveItemBuilder.modifiedDate;
        this.childCount = driveItemBuilder.childCount;
        this.itemPath = driveItemBuilder.itemPath;
        this.driveId = driveItemBuilder.driveId;
    }
}
