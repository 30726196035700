// Constants
import { NeutralColors } from "@fluentui/react";
// Styles
import { flexChildSizeFixed, flexParent, fontColorNeutralSecondary, styleButtonAsDiv } from "../../../utilities/Mixin.styles";
import { highContrastHighlightColor, highContrastHighlightStyle, highContrastSelectionText } from "../../../utilities/HighContrast.styles";
// Utilities
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
/**
 * Helper to get styles for the Weekday Picker
 * @param customStyles Any custom style overrides
 * @param isDisabled Whether the component is disabled
 */ export const getStyles = memoizeFunction((theme, customStyles, isDisabled)=>{
    let selectedWeekdayButtonFontColor = Colors.Black;
    let selectedWeekdayButtonHoverFontColor = Colors.Black;
    let selectedWeekdayButtonBackgroundColor = Colors.PlannerPrimary;
    let selectedWeekdayButtonHoverBackgroundColor = NeutralColors.gray80;
    switch(theme.name){
        case UnoTheme.Light:
            selectedWeekdayButtonFontColor = Colors.PlannerDarker;
            selectedWeekdayButtonBackgroundColor = Colors.PlannerPrimary;
            break;
        case UnoTheme.Dark:
            selectedWeekdayButtonFontColor = Colors.White;
            selectedWeekdayButtonBackgroundColor = Colors.PlannerPrimary;
            break;
        case UnoTheme.TeamsLight:
            selectedWeekdayButtonFontColor = Colors.TeamsAppBrand00;
            selectedWeekdayButtonBackgroundColor = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsDark:
            selectedWeekdayButtonFontColor = Colors.White;
            selectedWeekdayButtonBackgroundColor = Colors.TeamsAppBrand00;
            break;
        case UnoTheme.TeamsContrast:
            selectedWeekdayButtonFontColor = Colors.White;
            selectedWeekdayButtonHoverFontColor = Colors.Black;
            selectedWeekdayButtonBackgroundColor = Colors.TeamsContrastSelectedColor;
            selectedWeekdayButtonHoverBackgroundColor = Colors.TeamsContrastHyperlinkColor;
            break;
        case UnoTheme.SharePoint:
            selectedWeekdayButtonFontColor = Colors.Black;
            selectedWeekdayButtonBackgroundColor = Colors.SharepointButtonHoverColor();
            break;
        default:
            break;
    }
    const weekdayPickerStyles = {
        root: [
            flexParent("row")
        ],
        weekdayButton: [
            styleButtonAsDiv,
            flexChildSizeFixed(),
            fontColorNeutralSecondary(theme),
            {
                height: "40px",
                width: "40px",
                fontSize: "14px",
                textAlign: "center",
                position: "relative",
                marginRight: "5px",
                ":before": {
                    // The colored background on each weekday button is always in the ::before pseudo selector
                    // This is because the opacity of the background color varies, whereas the button text itself should always have full opacity.
                    content: "''",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    backgroundColor: "transparent",
                    borderRadius: "50%",
                    zIndex: "0"
                },
                "&.selected": [
                    highContrastSelectionText({
                        backgroundColor: "inherit",
                        borderRadius: "50%"
                    }),
                    {
                        color: selectedWeekdayButtonFontColor,
                        ":before": [
                            highContrastSelectionText({
                                borderRadius: "50%",
                                opacity: 1
                            }),
                            {
                                backgroundColor: selectedWeekdayButtonBackgroundColor,
                                opacity: "0.4"
                            }
                        ]
                    },
                    highContrastSelectionText()
                ],
                span: {
                    zIndex: "1",
                    position: "relative"
                }
            },
            !isDisabled && {
                cursor: "pointer",
                "&.selected:hover:before": {
                    opacity: "0.5"
                },
                ":not(&.selected):hover": [
                    highContrastHighlightColor(),
                    {
                        color: selectedWeekdayButtonHoverFontColor,
                        ":before": [
                            highContrastHighlightStyle({
                                borderWidth: "1px",
                                borderStyle: "solid"
                            }),
                            {
                                backgroundColor: selectedWeekdayButtonHoverBackgroundColor,
                                opacity: "1"
                            }
                        ]
                    }
                ]
            }
        ]
    };
    return concatStyleSets(weekdayPickerStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles for the Weekday Picker
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("weekdayPicker", styles.root),
        weekdayButton: mergeStyles("weekdayButton", styles.weekdayButton)
    };
});
