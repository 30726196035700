// Constants
import { NotificationChannel, NotificationType } from "@ms/uno-constants/lib/local/NotificationConstants";
// Models
import { NotificationEvents } from "../client/legacyPlanner/notificationEvents/NotificationEvents";
import { TaskCreationSourceType } from "../client/legacyPlanner/task/ITask";
import { Task } from "../client/legacyPlanner/task/Task";
import { Post } from "../client/post/Post";
import { UnknownUser } from "../client/user/UnknownUser";
// Utilities
import escape from "lodash/escape";
import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import { getCommentReplyEmailContent, getFirstCommentEmailContent } from "./CommentUtilities";
import { generateEntityHyperlink, generateEntityLink } from "./EntityLinkUtilities";
var MessageTemplateType;
/**
 * Enumeration for identifying which message template to use
 */ (function(MessageTemplateType) {
    /** Object Creation Template */ MessageTemplateType[MessageTemplateType["Creation"] = 0] = "Creation";
    /** Object Assignment Template */ MessageTemplateType[MessageTemplateType["Assignment"] = 1] = "Assignment";
    /** Object Unassignment Template */ MessageTemplateType[MessageTemplateType["Unassignment"] = 2] = "Unassignment";
    /** Object Completion Template */ MessageTemplateType[MessageTemplateType["Completion"] = 3] = "Completion";
    /** Object Publication Template */ MessageTemplateType[MessageTemplateType["Publication"] = 4] = "Publication";
})(MessageTemplateType || (MessageTemplateType = {}));
/**
 * Generate the fake creation post used in UI when creation feed is disabled
 * @param task Task associated with the creation feed post
 * @param getUser Function to get user from store
 */ export function generateFakeCreationPost(task, getUser) {
    let commenter;
    let feedTime = task.createdDate;
    const creationSource = task.creationSource;
    if (creationSource.publication) {
        // Published Task - Get the "Team" user object we generated in the store when reading this task
        const teamUser = getUser(creationSource.publication.sourceTeamId);
        feedTime = creationSource.publication.lastModifiedTime;
        commenter = teamUser != null ? teamUser : UnknownUser.getUnknownUser(creationSource.publication.sourceTeamId);
    } else {
        const createdByUser = getUser(task.createdBy);
        commenter = createdByUser != null ? createdByUser : commenter = UnknownUser.getUnknownUser(task.createdBy);
    }
    const messageTemplate = getCoreMessageTemplate(task.taskCreationSourceType === TaskCreationSourceType.Regular ? 0 : 4);
    const principalString = Strings.TaskActivityFeed_TaskFeedPrincipal;
    // Replace placeholders
    let message = messageTemplate.replace("{0}", principalString);
    message = message.replace("{1}", escape(task.title));
    // Add html formatting to the message
    message = '<div><font size="2"> <span style="font-size:10pt;">' + message + "</span></font></div>";
    return new Post({
        body: Post.generatePostBody(message),
        changeKey: "",
        createdDateTime: feedTime.toDate(),
        fromField: {
            emailAddress: {
                address: commenter.mail ?? "",
                name: commenter.displayName
            }
        },
        id: task.id,
        lastModifiedDateTime: feedTime.toDate(),
        receivedDateTime: feedTime.toDate(),
        sender: {
            emailAddress: {
                address: commenter.mail ?? "",
                name: commenter.displayName
            }
        }
    });
}
/**
 * Generate an activity feed post based on what changed in a task
 * @param linkRoot Root for the link
 * @param originalTaskEntitySet The original task entity set without changes
 * @param updatedTaskEntitySet The updated task entity set with changes
 * @param settings Settings to use for determining which feed should be generated
 * @param plan Plan associated with the task
 * @param getUser Function to get user from store
 */ export function getTaskActivityFeed(linkRoot, currentUser, originalTaskEntitySet, updatedTaskEntitySet, settings, plan, getUser) {
    let message = "";
    const principalString = Strings.TaskActivityFeed_TaskFeedPrincipal;
    let feedPost = null;
    if (updatedTaskEntitySet.task != null) {
        if (originalTaskEntitySet == null) {
            // This is a new task so assume all assignees were added and the percentComplete is changed if set
            if (settings === NotificationEvents.TaskAssignment || settings === NotificationEvents.All) {
                const assignees = updatedTaskEntitySet.task.getAssigneeIds();
                message = appendTaskAssignmentChangeMessage(linkRoot, message, updatedTaskEntitySet, principalString, assignees, false, getUser);
            }
            if (settings === NotificationEvents.TaskCompleted || settings === NotificationEvents.All) {
                message = appendTaskCompletionMessage(linkRoot, message, updatedTaskEntitySet, principalString, getUser);
            }
        } else {
            // Produce a task update activity feed
            if (settings === NotificationEvents.TaskAssignment || settings === NotificationEvents.All) {
                const addedAssignees = Task.getAddedAssignees(originalTaskEntitySet.task, updatedTaskEntitySet.task);
                message = appendTaskAssignmentChangeMessage(linkRoot, message, updatedTaskEntitySet, principalString, addedAssignees, false, getUser);
                const removedAssignees = Task.getRemovedAssignees(originalTaskEntitySet.task, updatedTaskEntitySet.task);
                message = appendTaskAssignmentChangeMessage(linkRoot, message, updatedTaskEntitySet, principalString, removedAssignees, true, getUser);
            }
            if (settings === NotificationEvents.TaskCompleted || settings === NotificationEvents.All) {
                if (originalTaskEntitySet.task.percentComplete !== 100) {
                    message = appendTaskCompletionMessage(linkRoot, message, updatedTaskEntitySet, principalString, getUser);
                }
            }
        }
        if (message.length > 0) {
            const timestamp = new Date();
            const taskLink = generateEntityLink(linkRoot, updatedTaskEntitySet.task, NotificationType.Comment, NotificationChannel.GroupMailbox, timestamp);
            const planLink = plan ? generateEntityLink(linkRoot, plan, NotificationType.Comment, NotificationChannel.GroupMailbox, timestamp) : "";
            // Add html formatting to the message
            const emailContent = updatedTaskEntitySet.task.conversationThreadId == null ? getFirstCommentEmailContent(taskLink, planLink, plan.title) : getCommentReplyEmailContent(taskLink, updatedTaskEntitySet.task.title, planLink, plan.title);
            message = '<div><font size="2"><span style="font-size:10pt;">' + message + "</span></font></div>" + emailContent;
            feedPost = new Post({
                body: Post.generatePostBody(message),
                changeKey: "",
                createdDateTime: new Date(),
                fromField: {
                    emailAddress: {
                        address: currentUser.mail ?? "",
                        name: currentUser.displayName
                    }
                },
                id: LocalIdGenerator.generate(),
                lastModifiedDateTime: new Date(),
                receivedDateTime: new Date(),
                sender: {
                    emailAddress: {
                        address: currentUser.mail ?? "",
                        name: currentUser.displayName
                    }
                }
            });
        }
    }
    return feedPost;
}
/**
 * Get the template message for a given Template Type
 * @param messageType Type of the message template to use for the message
 */ function getCoreMessageTemplate(messageType) {
    let coreMessage = "";
    switch(messageType){
        case 1:
            coreMessage = Strings.TaskActivityFeed_AssignmentFeedTemplate;
            break;
        case 2:
            coreMessage = Strings.TaskActivityFeed_UnassignmentFeedTemplate;
            break;
        case 3:
            coreMessage = Strings.TaskActivityFeed_CompletionFeedTemplate;
            break;
        case 0:
            coreMessage = Strings.TaskActivityFeed_CreationFeedTemplate;
            break;
        case 4:
            coreMessage = Strings.TaskActivityFeed_PublicationFeedTemplate;
            break;
        default:
            break;
    }
    return coreMessage;
}
/**
 * Generate and append an assignment change message to the given message
 * @param linkRoot Root for the link
 * @param message Message to append the assignment message to
 * @param taskEntitySet Task entity set to base the message on
 * @param principalString Principal string to use for the message
 * @param changedAssignees List of ids of assignees whose assignment changed
 * @param isUnassign Value indicating if the assignment change is unassignment
 * @param getUser Function to get user from store
 */ function appendTaskAssignmentChangeMessage(linkRoot, message, taskEntitySet, principalString, changedAssignees, isUnassign, getUser) {
    if (changedAssignees.length <= 0) {
        return message;
    }
    let assignmentChangeString = "";
    changedAssignees.forEach((assignee)=>{
        let assigneeUser = getUser(assignee);
        if (!assigneeUser) {
            // The user id was not found in the store
            assigneeUser = UnknownUser.getUnknownUser(assignee);
        }
        assignmentChangeString = assignmentChangeString.concat((assignmentChangeString.length > 0 ? ", " : "") + assigneeUser.displayName);
    });
    if (message.length > 0) {
        message = message.concat("<br>");
    }
    message = message.concat(getCoreMessageTemplate(isUnassign ? 2 : 1).replace("{0}", principalString));
    message = message.replace("{2}", assignmentChangeString);
    message = message.replace("{1}", generateEntityHyperlink(linkRoot, taskEntitySet.task, NotificationType.Comment, NotificationChannel.GroupMailbox, new Date()));
    return message;
}
/**
 * Generate and append a completion message to the given message
 * @param linkRoot Root for the link
 * @param message Message to append the completion message to
 * @param taskEntitySet Task entity set to base the message on
 * @param principalString Principal string to use for the message
 * @param getUser Function to get user from store
 */ function appendTaskCompletionMessage(linkRoot, message, taskEntitySet, principalString, getUser) {
    if (taskEntitySet.task.percentComplete < 100 || !taskEntitySet.task.completedBy) {
        return message;
    }
    if (message.length > 0) {
        message = message.concat("<br>");
    }
    const completedByUser = getUser(taskEntitySet.task.completedBy) ?? UnknownUser.getUnknownUser(taskEntitySet.task.completedBy);
    message = message.concat(getCoreMessageTemplate(3).replace("{0}", principalString));
    message = message.replace("{2}", completedByUser.displayName);
    message = message.replace("{1}", generateEntityHyperlink(linkRoot, taskEntitySet.task, NotificationType.Comment, NotificationChannel.GroupMailbox, new Date()));
    return message;
}
