// Constants
// Styles
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { flexChildSizeFixed, flexParent, styleButtonAsDiv, ghostTextStyle } from "../../../../utilities/Mixin.styles";
/**
 * ExternalContextLink Style constants
 */ const externalContextIconSize = "24px";
const externalContextLinkMargin = "15px";
const sectionMargin = "32px";
/**
 * Helper to get styles for the ExternalContextLink
 * @param theme App context theme
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const externalContextLinkStyles = {
        root: [
            styleButtonAsDiv,
            flexParent(),
            {
                alignItems: "center",
                marginBottom: sectionMargin
            }
        ],
        externalContextIcon: [
            flexChildSizeFixed(),
            {
                height: externalContextIconSize,
                width: externalContextIconSize
            }
        ],
        externalContextLinkLabel: [
            ghostTextStyle(theme),
            flexChildSizeFixed(),
            {
                marginLeft: externalContextLinkMargin,
                cursor: "pointer",
                ":hover": {
                    textDecoration: "underline"
                }
            }
        ]
    };
    return concatStyleSets(externalContextLinkStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles for the ExternalContextLink component
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("openExternalLinkedContextSection", styles.root),
        externalContextIcon: mergeStyles("externalContextIcon", styles.externalContextIcon),
        externalContextLinkLabel: mergeStyles("linkLabel", styles.externalContextLinkLabel)
    };
});
