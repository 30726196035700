// Styles
import { concatStyleSetsWithProps, FontSizes, FontWeights, memoizeFunction } from "@fluentui/react";
import { highContrastBorders } from "../../utilities/HighContrast.styles";
import { dialogControlPlaceholderStyle, dialogControlSecondaryPlaceholderStyle, dialogControlSecondaryStyle, dialogControlStyle, fontColorNeutralPrimary, selectedControlStyle } from "../../utilities/Mixin.styles";
export var TextFieldType;
(function(TextFieldType) {
    TextFieldType[TextFieldType["Bucket"] = 0] = "Bucket";
    TextFieldType[TextFieldType["Dialog"] = 1] = "Dialog";
    TextFieldType[TextFieldType["Text"] = 2] = "Text";
    TextFieldType[TextFieldType["Title"] = 3] = "Title";
    TextFieldType[TextFieldType["TitleUnderlined"] = 4] = "TitleUnderlined";
})(TextFieldType || (TextFieldType = {}));
/**
 * Text Field Style constants
 */ const dialogTextFieldHeight = "32px";
const titleTextFieldHeight = "32px";
const titleUnderlinedTextFieldHeight = "44px";
const boardColumnTitleHeight = "32px";
/**
 * Helper to get style function for the Text Field
 * @param theme Current app Fluent v8 theme
 * @param styleType Style type of the text field
 * @param customStyles Any custom style overrides
 */ export const getTextFieldStyleFunction = memoizeFunction((theme, styleType, customStyles)=>(props)=>{
        return concatStyleSetsWithProps(props, getBaseTextFieldStyles(theme, styleType), customStyles);
    });
/**
 * Get base styles for Text Field
 * @param styleType Style type of the text field
 */ export const getBaseTextFieldStyles = memoizeFunction((theme, styleType)=>{
    const isBucketStyle = styleType === 0;
    const isDialogStyle = styleType === 1;
    const isTextStyle = styleType === 2;
    const isTitleStyle = styleType === 3;
    const isTitleUnderlinedStyle = styleType === 4;
    let textFieldHeight = "";
    let textFieldFontWeight = "normal";
    switch(styleType){
        case 1:
        case 2:
            textFieldHeight = dialogTextFieldHeight;
            break;
        case 3:
            textFieldHeight = titleTextFieldHeight;
            textFieldFontWeight = FontWeights.semibold;
            break;
        case 4:
            textFieldHeight = titleUnderlinedTextFieldHeight;
            textFieldFontWeight = FontWeights.regular;
            break;
        case 0:
            textFieldHeight = boardColumnTitleHeight;
            textFieldFontWeight = FontWeights.semibold;
            break;
        default:
            break;
    }
    const root = {
        ":not(.ms-TextField--multiline) .ms-TextField-fieldGroup": [
            {
                height: textFieldHeight,
                "> .ms-TextField-field": [
                    {
                        height: textFieldHeight,
                        lineHeight: textFieldHeight
                    },
                    isDialogStyle && {
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "1px"
                    }
                ]
            }
        ]
    };
    const field = [
        fontColorNeutralPrimary(theme),
        {
            backgroundColor: "transparent",
            fontWeight: textFieldFontWeight,
            ".is-disabled &": theme.palette.neutralTertiary
        },
        (isTextStyle || isTitleStyle || isTitleUnderlinedStyle || isBucketStyle) && {
            padding: "0px"
        },
        // Placeholder
        (isDialogStyle || isTitleStyle || isTitleUnderlinedStyle) && dialogControlPlaceholderStyle(theme),
        (isTextStyle || isBucketStyle) && dialogControlSecondaryPlaceholderStyle(theme),
        // Border
        (isTitleStyle || isTitleUnderlinedStyle) && {
            border: "1px solid transparent"
        },
        isTitleStyle && {
            ":focus, :hover": {
                borderColor: theme.palette.neutralTertiary
            }
        },
        isTitleUnderlinedStyle && {
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.themePrimary
        },
        isDialogStyle && {
            borderRadius: "3px"
        },
        {
            ".is-active &": [
                isDialogStyle && highContrastBorders({
                    borderWidth: "3px"
                }),
                isBucketStyle && highContrastBorders({
                    borderWidth: "2px"
                })
            ]
        },
        // Font
        isTitleStyle && {
            fontSize: FontSizes.large,
            fontWeight: FontWeights.semibold
        },
        isTitleUnderlinedStyle && {
            fontSize: FontSizes.xxLarge,
            fontWeight: FontWeights.regular
        },
        isBucketStyle && {
            fontSize: FontSizes.medium,
            fontWeight: FontWeights.semibold
        }
    ];
    const fieldGroup = [
        isDialogStyle && [
            dialogControlStyle(theme),
            {
                ":hover": dialogControlStyle(theme),
                ".is-active &": [
                    selectedControlStyle(theme)
                ]
            }
        ],
        isBucketStyle && [
            dialogControlSecondaryStyle(theme),
            {
                margin: "0px 5px",
                ".is-active &": [
                    selectedControlStyle(theme)
                ]
            }
        ],
        (isTextStyle || isTitleStyle || isTitleUnderlinedStyle) && {
            backgroundColor: "transparent",
            border: "0px solid transparent"
        }
    ];
    const prefix = isDialogStyle && {
        color: theme.palette.neutralSecondary,
        backgroundColor: theme.palette.neutralLighter
    };
    const textFieldStyles = {
        root,
        field,
        fieldGroup,
        prefix
    };
    return textFieldStyles;
});
