// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Models
import { SupportedReferenceTypes } from "@ms/uno-models/lib/local/client/taskAttachment/ITaskAttachmentType";
// Styles
import { memoizeFunction } from "@fluentui/react";
import { linkIconProps, imageIconProps, pdfIconProps, wordLogoIconProps, excelLogoIconProps, powerPointLogoIconProps, oneNoteLogoIconProps, projectLogoIconProps, visioLogoIconProps } from "../../../icons/icons.styles";
/**
 * Helper to get styles for DocumentPreviewIcon
 * @param theme Planner Theme
 * @param fileType SupportedReferenceTypes
 */ export const getDocumentPreviewIconStyles = memoizeFunction((theme, fileType)=>{
    let color = Colors.GenericIconColor;
    switch(fileType){
        case SupportedReferenceTypes.Excel:
            color = Colors.ExcelIconColor;
            break;
        case SupportedReferenceTypes.PowerPoint:
            color = Colors.PowerPointIconColor;
            break;
        case SupportedReferenceTypes.Word:
            color = Colors.WordIconColor;
            break;
        case SupportedReferenceTypes.OneNote:
            color = Colors.OneNoteIconColor;
            break;
        case SupportedReferenceTypes.Pdf:
            color = Colors.PdfIconColor;
            break;
        case SupportedReferenceTypes.Project:
            color = Colors.ProjectIconColor;
            break;
        case SupportedReferenceTypes.Visio:
            color = Colors.VisioIconColor;
            break;
        default:
            if (theme.name === UnoTheme.SharePoint) {
                color = Colors.SharepointTitleFontColor();
            }
            break;
    }
    const root = {
        color,
        "> svg": {
            fill: color
        }
    };
    const DocumentPreviewIconStyles = {
        root
    };
    return DocumentPreviewIconStyles;
});
/** SVG icon overrides */ export const documentPreviewIconsOverrides = {
    word: wordLogoIconProps,
    excel: excelLogoIconProps,
    powerpoint: powerPointLogoIconProps,
    onenote: oneNoteLogoIconProps,
    project: projectLogoIconProps,
    visio: visioLogoIconProps,
    link: linkIconProps,
    photo: imageIconProps,
    pdf: pdfIconProps
};
