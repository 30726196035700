/**
 * Represents an array of numbers, assumed to be 0 if an index is not yet defined
 */ export class SparseNumberArray {
    /**
     * Get a cloned copy of this array
     */ clone() {
        return new SparseNumberArray(this.array.slice());
    }
    /**
     * Get the current length of the array
     */ getCurrentLength() {
        return this.array.length;
    }
    /**
     * Retrieve value from the number array, or 0 if it is undefined.
     * @param index Index of value to retrive from the array
     */ getValueAtIndex(index) {
        return this.array[index] ?? 0;
    }
    /**
     * Set a value in the number array.
     * @param index Index at which to set value in number array
     * @param value new value to set
     */ setValueAtIndex(index, value) {
        this.array[index] = value;
    }
    /**
     * Create an instance of SparseNumberArray
     */ constructor(array){
        this.array = array ?? [];
    }
}
