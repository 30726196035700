// Styles
import { memoizeFunction } from "@fluentui/react";
import { backgroundColorHover, backgroundColorSelected, dialogBackgroundPrimary, fontColorNeutralPrimary } from "../../../utilities/Mixin.styles";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
export const getCustomOneDrivePickerStyles = memoizeFunction((theme)=>{
    const dialogHeaderText = {
        root: fontColorNeutralPrimary(theme)
    };
    // theme color for breadcrumb
    const themeColor = [
        fontColorNeutralPrimary(theme),
        {
            // set font color to override the fluent ui default styles
            ":focus, :hover": [
                fontColorNeutralPrimary(theme),
                backgroundColorHover(theme)
            ],
            ":active": [
                fontColorNeutralPrimary(theme),
                backgroundColorSelected(theme)
            ]
        }
    ];
    const breadcrumb = {
        list: {
            button: themeColor
        },
        chevron: fontColorNeutralPrimary(theme),
        listItem: {
            ":last-child .ms-Breadcrumb-itemLink": themeColor
        },
        itemLink: themeColor
    };
    const listDetails = {
        root: [
            dialogBackgroundPrimary(theme),
            fontColorNeutralPrimary(theme)
        ]
    };
    const listDetailsHeader = {
        root: [
            dialogBackgroundPrimary(theme),
            {
                "> .ms-DetailsHeader-cell": [
                    fontColorNeutralPrimary(theme),
                    {
                        i: fontColorNeutralPrimary(theme)
                    },
                    {
                        ":focus-within, :hover": [
                            fontColorNeutralPrimary(theme),
                            backgroundColorHover(theme),
                            theme.name === UnoTheme.TeamsContrast && {
                                i: {
                                    color: Colors.Black
                                }
                            }
                        ],
                        ":active": [
                            backgroundColorSelected(theme),
                            theme.name === UnoTheme.TeamsContrast && {
                                i: {
                                    color: Colors.Black
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    };
    const listDetailsRow = {
        root: [
            dialogBackgroundPrimary(theme),
            fontColorNeutralPrimary(theme),
            {
                i: [
                    fontColorNeutralPrimary(theme)
                ]
            },
            {
                ":focus-within, :hover": [
                    backgroundColorHover(theme),
                    {
                        ".ms-DetailsRow-cell": [
                            fontColorNeutralPrimary(theme),
                            theme.name === UnoTheme.TeamsContrast && {
                                "> span, i": {
                                    color: Colors.Black
                                }
                            }
                        ]
                    }
                ],
                "&.is-selected, :active, &.is-selected:focus:hover": [
                    backgroundColorSelected(theme),
                    theme.name === UnoTheme.TeamsContrast && {
                        "span, i": {
                            color: Colors.Black
                        }
                    },
                    theme.name === UnoTheme.SharePoint && {
                        backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor
                    }
                ],
                ".plannerWordLogo svg, .plannerExcelLogo svg, .plannerOneNoteLogo svg, .plannerPowerPointLogo svg, .plannerProjectLogo svg, .plannerVisioLogo svg": {
                    height: "16px",
                    width: "16px"
                }
            }
        ]
    };
    const errorFolderIcon = {
        root: fontColorNeutralPrimary(theme)
    };
    const emptyFolderIcon = {
        root: [
            fontColorNeutralPrimary(theme),
            {
                "> svg": {
                    width: "100px",
                    height: "100px"
                }
            }
        ]
    };
    const emptyFolderText = {
        root: fontColorNeutralPrimary(theme)
    };
    const attachmentError = {
        root: [
            theme.name === UnoTheme.TeamsContrast && {
                color: Colors.White
            }
        ]
    };
    const subComponentStyles = {
        dialogHeaderText,
        breadcrumb,
        listDetails,
        listDetailsHeader,
        listDetailsRow,
        errorFolderIcon,
        emptyFolderIcon,
        emptyFolderText,
        attachmentError
    };
    const styles = {
        subComponentStyles
    };
    return styles;
});
