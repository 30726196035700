// Styles
import { memoizeFunction, concatStyleSets, concatStyleSetsWithProps } from "@fluentui/react";
import { backgroundColorHover, backgroundColorNeutralPrimary, backgroundColorSelected, dialogControlSecondaryStyle, dialogControlStyle, flexParent, flexParentCenterAll, fontColorDisabled, fontColorNeutralPrimary, fontColorNeutralSecondary, selectedControlStyle } from "../../../utilities/Mixin.styles";
import { highContrastHighlightStyle, highContrastHighlightTextColor } from "../../../utilities/HighContrast.styles";
// Constants
import { PlannerInputHeight, PlannerBorderRadius, LoopBorderRadius } from "../../../constants/StyleConstants";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Utilities
import { isLoopTheme } from "../../../utilities/ThemingUtilities";
/**
 * Helper to get style function for the Text Field
 * @param theme App context theme
 * @param [isDisabled] Whether or not the dropdown is disabled
 * @param [customStyles] Optional set of custom styles that override the default styling
 */ export const getDropdownStyleFunction = memoizeFunction((theme, isDisabled, customStyles)=>(props)=>{
        return concatStyleSetsWithProps(props, getDropdownStyles(theme, isDisabled), customStyles);
    });
/**
 * The styles for the Dropdown
 * @param theme App context theme
 * @param isDisabled Whether or not the dropdown is disabled
 * @param [customStyles] Optional set of custom styles that override the default styling
 */ export const getDropdownStyles = memoizeFunction((theme, isDisabled, customStyles)=>{
    const isItLoopTheme = isLoopTheme(theme);
    let titlePaddingTop = "";
    let titleBorderRadius = "";
    let hoverColor = "";
    let calloutBorderRadius = PlannerBorderRadius;
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
            hoverColor = Colors.NeutralDark;
            titlePaddingTop = "1px";
            titleBorderRadius = "3px";
            break;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            hoverColor = Colors.NeutralLight;
            titlePaddingTop = "1px";
            titleBorderRadius = "3px";
            break;
        case UnoTheme.TeamsContrast:
            hoverColor = Colors.TeamsContrastHyperlinkColor;
            titlePaddingTop = "1px";
            titleBorderRadius = "3px";
            break;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
            calloutBorderRadius = LoopBorderRadius;
            break;
        case UnoTheme.SharePoint:
        default:
            break;
    }
    const label = fontColorNeutralSecondary(theme);
    const dropdown = [
        fontColorNeutralPrimary(theme),
        isItLoopTheme ? dialogControlSecondaryStyle(theme) : dialogControlStyle(theme),
        {
            height: PlannerInputHeight,
            "> *": {
                backgroundColor: "transparent"
            },
            "&.is-open": selectedControlStyle(theme),
            "&:focus, &:hover": {
                ".ms-Dropdown-title": fontColorNeutralPrimary(theme),
                ".ms-Dropdown-caretDown": {
                    color: hoverColor
                }
            },
            "&:active": {
                ".ms-Dropdown-title": fontColorNeutralPrimary(theme)
            },
            "&:focus::after": {
                border: "none",
                position: "inherit"
            }
        },
        isDisabled && {
            "&:hover, &:focus, &:hover:focus": {
                ".ms-Dropdown-title, .ms-Dropdown-caretDown": fontColorDisabled(theme)
            }
        },
        !isDisabled && {
            "&:focus": [
                selectedControlStyle(theme),
                theme.name === UnoTheme.SharePoint && {
                    "> .ms-Dropdown-title": {
                        border: "1px solid " + Colors.BorderColorNeutralTertiary
                    }
                }
            ]
        }
    ];
    const title = [
        isDisabled ? fontColorDisabled(theme) : fontColorNeutralPrimary(theme),
        {
            backgroundColor: "transparent",
            border: "0px solid transparent",
            borderRadius: titleBorderRadius,
            paddingTop: titlePaddingTop
        }
    ];
    const caretDownWrapper = [
        flexParent(),
        flexParentCenterAll
    ];
    const dropdownItem = [
        backgroundColorNeutralPrimary(theme),
        fontColorNeutralPrimary(theme),
        {
            border: "1px solid transparent",
            "&:focus, &:hover, &:hover:focus": [
                fontColorNeutralPrimary(theme),
                backgroundColorHover(theme),
                highContrastHighlightStyle(),
                {
                    ".icon span": highContrastHighlightStyle()
                }
            ],
            ".ms-Button-flexContainer": {
                width: "100%"
            }
        }
    ];
    const dropdownItemSelected = [
        backgroundColorSelected(theme),
        fontColorNeutralPrimary(theme),
        {
            border: "1px solid transparent",
            "&:focus, &:hover, &:hover:focus": [
                fontColorNeutralPrimary(theme),
                backgroundColorHover(theme),
                theme.name === UnoTheme.TeamsContrast && {
                    ".ms-Icon": {
                        color: Colors.Black
                    }
                }
            ],
            ".ms-Button-flexContainer": {
                width: "100%"
            },
            ".plannerIcon > span": highContrastHighlightTextColor()
        },
        theme.name === UnoTheme.TeamsContrast && {
            color: Colors.Black,
            ".ms-Icon": {
                color: Colors.Black
            }
        },
        theme.name === UnoTheme.SharePoint && {
            backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor(),
            color: Colors.SharepointTitleFontColor(),
            ".ms-Dropdown-optionText": {
                color: Colors.SharepointTitleFontColor()
            }
        }
    ];
    const dropdownItems = [
        backgroundColorNeutralPrimary(theme),
        {
            borderTop: "1px solid transparent"
        }
    ];
    const callout = {
        borderRadius: calloutBorderRadius,
        maxHeight: "280px !important",
        ".ms-Callout-main": [
            {
                borderRadius: calloutBorderRadius
            },
            theme.name === UnoTheme.TeamsContrast && {
                backgroundColor: Colors.Black
            }
        ]
    };
    return concatStyleSets({
        label,
        dropdown,
        title,
        caretDownWrapper,
        dropdownItem,
        dropdownItemSelected,
        dropdownItems,
        callout
    }, customStyles);
});
/**
 * The styles for the caret down icon
 * @param theme App context theme
 * @param isDisabled Whether or not the dropdown is disabled
 */ export const getCaretDownIconStyles = memoizeFunction((theme, isDisabled)=>{
    const root = [
        isDisabled ? fontColorDisabled(theme) : fontColorNeutralSecondary(theme)
    ];
    return {
        root
    };
});
