import { concatStyleSets, memoizeFunction, FontWeights, FontSizes, concatStyleSetsWithProps, ScreenWidthMinMedium } from "@fluentui/react";
// Styles
import { fontColorNeutralPrimary, dialogBackgroundPrimary, buttonStyle, themedButtonStyle, backgroundColorHover } from "./Mixin.styles";
import { highContrastBorders, highContrastSelectionText, highContrastPrimaryButton } from "./HighContrast.styles";
// Constants
import { LoopBorderRadius, PlannerBorderRadius, LoopButtonBorderRadius, MainDialogWidth, MainDialogMaxWidth, MainDialogMinWidth, MaxSecondaryDialogWidth, SecondaryDialogWidth } from "../constants/StyleConstants";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
// Models
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Utilities
import { isLoopTheme } from "./ThemingUtilities";
const TopButtonDialogPadding = "12px";
const buttonDefault = memoizeFunction((theme)=>{
    const isLoop = isLoopTheme(theme);
    return [
        buttonStyle(theme),
        highContrastPrimaryButton(),
        {
            fontSize: FontSizes.medium,
            ":hover": [
                backgroundColorHover(theme),
                highContrastSelectionText(),
                theme.name === UnoTheme.LoopLight && {
                    backgroundColor: Colors.NeutralLight
                }
            ]
        },
        theme.name === UnoTheme.TeamsContrast && {
            color: Colors.White,
            border: `1px solid ${Colors.White}`
        },
        theme.name === UnoTheme.LoopLight && {
            backgroundColor: Colors.White,
            color: Colors.NeutralPrimary,
            border: `1px solid ${Colors.NeutralLight}`
        },
        isLoop && {
            borderRadius: LoopButtonBorderRadius
        }
    ];
});
/**
 * Helper to get styles for buttonPrimary
 * @param theme Planner theme
 */ const buttonPrimary = memoizeFunction((theme)=>{
    const isLoop = isLoopTheme(theme);
    return [
        themedButtonStyle(theme),
        highContrastPrimaryButton(),
        {
            ":hover": highContrastSelectionText(),
            fontSize: FontSizes.medium
        },
        theme.name === UnoTheme.TeamsContrast && {
            border: `1px solid ${Colors.White}`
        },
        isLoop && {
            borderRadius: LoopButtonBorderRadius
        }
    ];
});
export const getPlannerAppDialogStyles = memoizeFunction((theme, customStyles)=>{
    const isLoop = isLoopTheme(theme);
    const main = [
        fontColorNeutralPrimary(theme),
        dialogBackgroundPrimary(theme),
        highContrastBorders(),
        {
            boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 0px",
            borderRadius: isLoop ? LoopBorderRadius : PlannerBorderRadius,
            width: MainDialogWidth,
            maxWidth: MainDialogMaxWidth,
            minWidth: MainDialogMinWidth,
            "> div": {
                flexGrow: "1"
            },
            "@media (min-width: 480px)": {
                width: MainDialogWidth,
                maxWidth: MainDialogMaxWidth,
                minWidth: MainDialogMinWidth
            }
        },
        theme.name === UnoTheme.SharePoint && {
            overflowX: "hidden"
        },
        theme.name === UnoTheme.TeamsContrast && {
            border: `1px solid ${Colors.White}`
        }
    ];
    const root = [
        isLoop && {
            position: "absolute"
        }
    ];
    const dialogStyles = {
        main,
        root
    };
    return concatStyleSets(dialogStyles, customStyles);
});
export const getPlannerAppDialogContentStyles = memoizeFunction((theme, customStyles)=>{
    const content = [
        fontColorNeutralPrimary(theme),
        dialogBackgroundPrimary(theme),
        {
            ".ms-Dialog-content .ms-Dialog-action, .ms-Dialog-actions .ms-Dialog-action": {
                ".ms-Button.ms-Button--primary": buttonPrimary(theme),
                ".ms-Button.ms-Button--default": buttonDefault(theme)
            }
        }
    ];
    const title = [
        fontColorNeutralPrimary(theme),
        {
            cursor: "default",
            fontWeight: FontWeights.semibold
        }
    ];
    const button = [
        fontColorNeutralPrimary(theme),
        {
            "&:hover": {
                backgroundColor: "transparent"
            },
            "&:disabled": [
                {
                    color: Colors.NeutralTertiary,
                    backgroundColor: Colors.NeutralLighter
                },
                theme.name === UnoTheme.TeamsContrast && {
                    color: Colors.TeamsContrastPlaceHolderOrDisabledColor,
                    backgroundColor: Colors.TeamsContrastPlaceHolderOrDisabledColor
                }
            ]
        }
    ];
    const topButton = [
        {
            ".ms-Dialog-button--close": [
                fontColorNeutralPrimary(theme),
                {
                    ":hover": [
                        fontColorNeutralPrimary(theme),
                        {
                            backgroundColor: "transparent"
                        }
                    ]
                }
            ]
        },
        theme.name === UnoTheme.SharePoint && {
            paddingTop: TopButtonDialogPadding,
            paddingRight: TopButtonDialogPadding
        }
    ];
    const subText = fontColorNeutralPrimary(theme);
    const dialogContentStyles = {
        content,
        title,
        button,
        subText,
        topButton
    };
    return concatStyleSets(dialogContentStyles, customStyles);
});
const secondaryDialogStyles = {
    main: {
        width: SecondaryDialogWidth,
        selectors: {
            [`@media (min-width: ${ScreenWidthMinMedium}px)`]: {
                width: "auto",
                maxWidth: MaxSecondaryDialogWidth,
                minWidth: SecondaryDialogWidth
            }
        }
    }
};
/**
 * Helper to get styles function for Dialog
 * @param customStyles Any custom style overrides
 */ export const getDialogStyleFunction = memoizeFunction((customStyles)=>(props)=>concatStyleSetsWithProps(props, secondaryDialogStyles, customStyles));
