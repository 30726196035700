// Styles
import { concatStyleSetsWithProps, memoizeFunction, mergeStyles } from "@fluentui/react";
/**
 * Helper to get styles for TaskAttachmentList
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((customStyles)=>{
    return concatStyleSetsWithProps(undefined, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("taskAttachmentList", styles.root)
    }));
