/**
 * Enum representing the status of report generation.
 */ export var ReportGenerationStatus;
(function(ReportGenerationStatus) {
    /** Report generation has not started */ ReportGenerationStatus["NotStarted"] = "notStarted";
    /** Report is being generated */ ReportGenerationStatus["Generating"] = "generating";
    /** Report has been generated successfully */ ReportGenerationStatus["Generated"] = "generated";
    /** Report generation failed */ ReportGenerationStatus["Failed"] = "failed";
    /** Report generation cancelled */ ReportGenerationStatus["Cancelled"] = "cancelled";
})(ReportGenerationStatus || (ReportGenerationStatus = {}));
export var ProjectManagerWorkflowClientState;
(function(ProjectManagerWorkflowClientState) {
    /** Task is not assigned to Copilot */ ProjectManagerWorkflowClientState["Unassigned"] = "unassigned";
    /** Task is assigned to Copilot, but is still pending execution */ ProjectManagerWorkflowClientState["AssignedToCopilot"] = "assigned";
    /** Task is assigned to Copilot, but cannot execute yet because it has incomplete dependencies */ ProjectManagerWorkflowClientState["Dependent"] = "dependent";
    /** Copilot is working on the task */ ProjectManagerWorkflowClientState["ActivelyWorking"] = "active";
    /** Copilot needs input from the user to work on the task */ ProjectManagerWorkflowClientState["NeedsInput"] = "needsinput";
    /** Copilot has completed the task */ ProjectManagerWorkflowClientState["Done"] = "done";
    /** Copilot encountered an error while working on the task */ ProjectManagerWorkflowClientState["Error"] = "error";
})(ProjectManagerWorkflowClientState || (ProjectManagerWorkflowClientState = {}));
export var ProjectManagerSuggestedTaskCreationState;
(function(ProjectManagerSuggestedTaskCreationState) {
    /** Copilot suggested task creation has not started */ ProjectManagerSuggestedTaskCreationState["NotStarted"] = "notstarted";
    /** Copilot suggested tasks are being created */ ProjectManagerSuggestedTaskCreationState["Active"] = "active";
    /** Copilot suggested tasks have been created successfully */ ProjectManagerSuggestedTaskCreationState["Completed"] = "completed";
    /** Copilot suggested task creation failed */ ProjectManagerSuggestedTaskCreationState["Failed"] = "failed";
    /** Copilot suggested task creation cancelled */ ProjectManagerSuggestedTaskCreationState["Cancelled"] = "cancelled";
})(ProjectManagerSuggestedTaskCreationState || (ProjectManagerSuggestedTaskCreationState = {}));
export var ProjectManagerAssignmentDisplayState;
(function(ProjectManagerAssignmentDisplayState) {
    /** ProjectManager is not displayed in the ResourceEditor */ ProjectManagerAssignmentDisplayState["Disabled"] = "disabled";
    /** ProjectManager appears as unassigned */ ProjectManagerAssignmentDisplayState["Unassigned"] = "unassigned";
    /** ProjectManager appears as assigned */ ProjectManagerAssignmentDisplayState["Assigned"] = "assigned";
})(ProjectManagerAssignmentDisplayState || (ProjectManagerAssignmentDisplayState = {}));
/** Max number of attached resources to be displayed in Copilot page */ export const MaxResourcesToBeDisplayed = 2;
/** Max number of supported resources for grounding */ export const MaxNumberOfAllowedResources = 10;
