//Constants
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { MainDialogWidth, MainDialogMaxWidth, MainDialogMinWidth, PlannerBorderRadius, LoopBorderRadius } from "../../../../constants/StyleConstants";
// Styles
import { concatStyleSets, FontSizes, memoizeFunction, mergeStyles } from "@fluentui/react";
import { dialogBackgroundPrimary, flexChildSizeFixed, flexParent, fontColorNeutralPrimary, styleScrollbar, themedButtonStyle } from "../../../../utilities/Mixin.styles";
import { highContrastBorders } from "../../../../utilities/HighContrast.styles";
// Utilities
import { isLoopTheme } from "../../../../utilities/ThemingUtilities";
/**
 * Helper to get styles for TaskEditor
 * @param theme Planner theme
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const isLoop = isLoopTheme(theme);
    const { name: themeName } = theme;
    const TaskEditorDialogStyles = {
        root: [
            isLoop && {
                position: "absolute"
            },
            {
                ".ms-Overlay": [
                    (themeName === UnoTheme.TeamsLight || themeName === UnoTheme.TeamsDark || themeName === UnoTheme.TeamsContrast) && {
                        backgroundColor: "transparent"
                    }
                ]
            }
        ],
        container: [
            fontColorNeutralPrimary(theme),
            dialogBackgroundPrimary(theme),
            highContrastBorders(),
            {
                overflow: "hidden",
                alignItems: "center",
                width: MainDialogWidth,
                maxWidth: MainDialogMaxWidth,
                minWidth: MainDialogMinWidth,
                borderRadius: PlannerBorderRadius,
                boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 0px"
            },
            themeName === UnoTheme.TeamsContrast && {
                backgroundColor: Colors.Black,
                borderColor: Colors.White,
                borderWidth: "1px",
                borderStyle: "solid"
            },
            isLoop && {
                maxHeight: "calc(100% - 80px)",
                borderRadius: LoopBorderRadius,
                boxShadow: `inset 1px 0px 0px ${themeName === UnoTheme.LoopLight ? Colors.NeutralLightGrey92 : Colors.NeutralDarkGrey12}`
            }
        ],
        scrollable: [
            styleScrollbar(theme),
            {
                maxHeight: "calc(100vh - 32px)"
            },
            isLoop && {
                // the current loop component container is 700px to hold the dialog within 600px is good enough
                maxHeight: "600px"
            }
        ],
        header: {
            position: "relative",
            width: "100%",
            boxSizing: "border-box"
        },
        title: {
            visibility: "collapse",
            margin: "0",
            fontSize: FontSizes.xLarge,
            padding: "32px 36px 20px 28px"
        },
        topButtonContainer: [
            flexParent(),
            {
                position: "absolute",
                top: "0",
                padding: "12px 12px 0 0",
                right: "0",
                "> *": flexChildSizeFixed(),
                "> .ms-Button": [
                    fontColorNeutralPrimary(theme),
                    {
                        ":hover": {
                            backgroundColor: "transparent"
                        }
                    }
                ]
            }
        ],
        content: {
            padding: "0 28px 20px"
        },
        suggestionsFloatingButton: [
            themedButtonStyle(theme),
            {
                position: "absolute",
                zIndex: "1",
                bottom: "32px",
                right: "24px",
                fontSize: "13px",
                height: "36px",
                ".suggestionsButtonText": {
                    marginLeft: "8px"
                }
            }
        ]
    };
    return concatStyleSets(TaskEditorDialogStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("taskEditor-dialog", styles.root),
        container: mergeStyles("taskEditor-dialog-container", styles.container),
        scrollable: mergeStyles("taskEditor-dialog-scrollable", styles.scrollable),
        header: mergeStyles("taskEditor-dialog-header", styles.header),
        title: mergeStyles("taskEditor-dialog-title", styles.title),
        topButtonContainer: mergeStyles("taskEditor-dialog-topButton", styles.topButtonContainer),
        content: mergeStyles("taskEditor-dialog-content", styles.content),
        suggestionsFloatingButton: mergeStyles("suggestionsFloatingButton", styles.suggestionsFloatingButton)
    }));
