// Models
import { ContextDisplayType } from "@ms/uno-models/lib/local/client/legacyPlanner/plan/ContextDisplayType";
// Utilities
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";
/**
 * Helper function used to check if a plan has meeting context or not
 * @param plan The plan to be checked
 * @param planDetails Plan details of the plan to be checked
 */ export function checkIfPlanHasMeetingContext(plan, planDetails) {
    if (isEmpty(plan.contexts)) {
        return false;
    }
    const contextKeys = Object.keys(plan.contexts);
    const meetingContextIndex = findIndex(contextKeys, (key)=>{
        const planDetailsEntity = planDetails.contextDetails[key];
        if (planDetailsEntity == null) {
            return false;
        }
        return planDetailsEntity.displayAs === ContextDisplayType.MeetingNotes;
    });
    if (meetingContextIndex === -1) {
        return false;
    }
    return true;
}
