// Constants
// Styles
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { FontSizes, FontWeights } from "@fluentui/react";
import { buttonStyle, flexChildSizeFlexible, flexParent, fontColorNeutralSecondary } from "../../../../utilities/Mixin.styles";
import { highContrast, highContrastSelectionText } from "../../../../utilities/HighContrast.styles";
// Utilities
import { isLoopTheme, getTeamsMessageColor, getLoopColor, getMeetingNotesColor, getWordColor, getExcelColor, getPowerPointColor, getProjectColor } from "../../../../utilities/ThemingUtilities";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
/**
 * ExternalSourceView Style constants
 */ const iconDimensions = "24px";
/**
 * The class name for the externalUrl anchor tag. Using this to keep template literal in sync with class name to avoid future issues
 */ const externalUrlClassName = "externalUrl";
/**
 * Helper to get styles for the ExternalSourceView
 * @param theme The theme to render the control
 * @param traceLogger trace logger to log telemetry
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, traceLogger, customStyles)=>{
    const isLoop = isLoopTheme(theme);
    const teamsMessageColor = getTeamsMessageColor(theme, traceLogger);
    const loopColor = getLoopColor(theme, traceLogger);
    const meetingNotesColor = getMeetingNotesColor(theme, traceLogger);
    const wordColor = getWordColor(theme, traceLogger);
    const excelColor = getExcelColor(theme, traceLogger);
    const powerPointColor = getPowerPointColor(theme, traceLogger);
    const projectColor = getProjectColor(theme, traceLogger);
    let align = "center";
    switch(theme.name){
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
            align = "flex-start";
            break;
        default:
            break;
    }
    const externalSourceViewStyles = {
        root: [
            flexParent(),
            {
                alignItems: align,
                // Specify these from root to win the specificity war
                [`> .${externalUrlClassName}.teamsColor`]: {
                    color: teamsMessageColor
                },
                [`> .${externalUrlClassName}.loopColor`]: {
                    color: loopColor
                },
                [`> .${externalUrlClassName}.meetingNotesColor`]: {
                    color: meetingNotesColor
                },
                [`> .${externalUrlClassName}.wordColor`]: {
                    color: wordColor
                },
                [`> .${externalUrlClassName}.excelColor`]: {
                    color: excelColor
                },
                [`> .${externalUrlClassName}.powerPointColor`]: {
                    color: powerPointColor
                },
                [`> .${externalUrlClassName}.projectColor`]: {
                    color: projectColor
                }
            }
        ],
        externalSourceContentLeft: [
            flexChildSizeFlexible("auto")
        ],
        externalSourceHeader: [
            flexParent(),
            {
                alignItems: "center",
                fontSize: FontSizes.medium,
                marginBottom: "8px"
            }
        ],
        externalSourceSubHeader: [
            fontColorNeutralSecondary(theme)
        ],
        externalSourceIcon: {
            marginRight: "8px",
            height: iconDimensions,
            width: iconDimensions
        },
        externalUrl: [
            flexParent(),
            {
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                alignItems: "center",
                textDecoration: "none",
                "> i": {
                    marginLeft: "8px"
                },
                "&:hover": [
                    isLoop && highContrastSelectionText(),
                    {
                        textDecoration: "none"
                    }
                ]
            },
            isLoop && [
                buttonStyle(theme),
                highContrast({
                    border: "1px solid !important"
                }),
                {
                    "> i": {
                        display: "none"
                    },
                    borderRadius: "4px",
                    padding: "2px 12px",
                    fontWeight: FontWeights.regular
                }
            ]
        ]
    };
    return concatStyleSets(externalSourceViewStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles for the ExternalSourceView component
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("externalSourceContainer", styles.root),
        externalSourceContentLeft: mergeStyles("externalSourceContentLeft", styles.externalSourceContentLeft),
        externalSourceHeader: mergeStyles("externalSourceHeader", styles.externalSourceHeader),
        externalSourceSubHeader: mergeStyles("externalSourceSubHeader", styles.externalSourceSubHeader),
        externalSourceIcon: mergeStyles("externalSourceIcon", styles.externalSourceIcon),
        externalUrl: mergeStyles(externalUrlClassName, styles.externalUrl)
    };
});
