// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
// Styles
import { concatStyleSets, mergeStyles, memoizeFunction, FontSizes, FontWeights, useTheme } from "@fluentui/react";
import { backgroundColorSelected, backgroundColorHover, flexChildSizeFixed, flexParent, flexParentCenterAll, fontColorNeutralPrimary, dialogBackgroundPrimary } from "../../../utilities/Mixin.styles";
// Utilities
import { numberToPx, pxToNumber } from "@ms/uno-utilities/lib/local/StyleUtilities";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
const listContainerHeight = pxToNumber("380px");
const folderIconSize = "100px";
const listContainerPadding = "10px";
const listContainerHeightStyles = {
    "@media screen and (min-height: 960px)": {
        height: numberToPx(listContainerHeight)
    },
    "@media screen and (max-height: 959px)": {
        height: numberToPx(listContainerHeight - 80)
    },
    "@media screen and (max-height: 734px)": {
        height: numberToPx(listContainerHeight - 160)
    },
    "@media screen and (max-height: 494px)": {
        height: numberToPx(listContainerHeight - 240)
    }
};
/**
 * Helper to get styles for OneDrivePickerForAttachments
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((customStyles)=>{
    const theme = useTheme();
    const root = {
        /**
         * This is getting used for grid icon in one drive picker.
         * Child selector is not used as there is not path guarantee where it will be placed exactly in details list
         */ ".previewIcon": {
            padding: 0,
            paddingTop: "2.5px",
            ":hover": {
                backgroundColor: "transparent !important"
            }
        },
        /**
         * This is getting used for grid text in one drive picker.
         * Child selector is not used as there is not path guarantee where it will be placed exactly in details list
         */ ".folderItemText:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        },
        /**
         * This is getting used for grid text in one drive picker.
         * Child selector is not used as there is not path guarantee where it will be placed exactly in details list
         */ ".itemText": {
            cursor: "default"
        }
    };
    const dialogHeader = [
        flexParent(),
        {
            paddingBottom: "10px"
        }
    ];
    const dialogHeaderText = {
        root: [
            {
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                color: Colors.NeutralPrimary
            },
            fontColorNeutralPrimary(theme)
        ]
    };
    const listContainer = [
        listContainerHeightStyles,
        {
            paddingBottom: listContainerPadding,
            overflowY: "auto",
            overflowX: "hidden"
        }
    ];
    const listDetails = {
        root: [
            dialogBackgroundPrimary(theme),
            fontColorNeutralPrimary(theme)
        ]
    };
    const listDetailsHeader = {
        root: [
            dialogBackgroundPrimary(theme),
            fontColorNeutralPrimary(theme),
            {
                "> .ms-DetailsHeader-cell": [
                    {
                        i: fontColorNeutralPrimary(theme)
                    },
                    {
                        ":focus-within, :hover": [
                            backgroundColorHover(theme),
                            theme.name === UnoTheme.TeamsContrast && {
                                i: {
                                    color: Colors.Black
                                }
                            }
                        ],
                        ":active": [
                            backgroundColorSelected(theme),
                            theme.name === UnoTheme.TeamsContrast && {
                                i: {
                                    color: Colors.Black
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    };
    const listDetailsRow = {
        root: [
            dialogBackgroundPrimary(theme),
            fontColorNeutralPrimary(theme),
            {
                i: [
                    fontColorNeutralPrimary(theme)
                ]
            },
            {
                ":focus-within, :hover": [
                    backgroundColorHover(theme),
                    {
                        ".ms-DetailsRow-cell": [
                            theme.name === UnoTheme.TeamsContrast && {
                                "> span, i": {
                                    color: Colors.Black
                                }
                            }
                        ]
                    }
                ],
                "&.is-selected, :active, &.is-selected:focus:hover": [
                    backgroundColorSelected(theme),
                    theme.name === UnoTheme.TeamsContrast && {
                        "span, i": {
                            color: Colors.Black
                        }
                    },
                    theme.name === UnoTheme.SharePoint && {
                        backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor
                    }
                ],
                ".plannerWordLogo svg, .plannerExcelLogo svg, .plannerOneNoteLogo svg, .plannerPowerPointLogo svg, .plannerProjectLogo svg, .plannerVisioLogo svg": {
                    height: "16px",
                    width: "16px"
                }
            }
        ]
    };
    const errorContainer = [
        flexChildSizeFixed("auto"),
        flexParent(),
        flexParentCenterAll,
        listContainerHeightStyles,
        {
            textAlign: "center",
            wordWrap: "break-word",
            overflow: "hidden"
        }
    ];
    const errorFolder = [
        flexChildSizeFixed("auto"),
        flexParent("column"),
        flexParentCenterAll
    ];
    const errorTitle = {
        root: {
            fontSize: FontSizes.xLarge,
            fontWeight: FontWeights.semibold,
            color: Colors.NeutralPrimary
        }
    };
    const errorMessage = {
        root: [
            {
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semilight,
                color: Colors.NeutralPrimary,
                paddingBottom: "20px"
            },
            fontColorNeutralPrimary(theme)
        ]
    };
    const errorFolderIcon = {
        root: [
            {
                fontSize: folderIconSize,
                color: Colors.NeutralPrimary
            },
            fontColorNeutralPrimary(theme)
        ]
    };
    const emptyContainer = [
        flexChildSizeFixed("auto"),
        flexParent(),
        flexParentCenterAll,
        listContainerHeightStyles,
        {
            textAlign: "center",
            wordWrap: "break-word",
            overflow: "hidden"
        }
    ];
    const emptyFolder = [
        flexChildSizeFixed("auto"),
        flexParent("column"),
        flexParentCenterAll
    ];
    const emptyFolderText = {
        root: [
            {
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semilight,
                color: Colors.NeutralPrimary
            },
            fontColorNeutralPrimary(theme)
        ]
    };
    const emptyFolderIcon = {
        root: [
            fontColorNeutralPrimary(theme),
            {
                "> svg": {
                    width: "100px",
                    height: "100px"
                }
            }
        ]
    };
    const attachmentError = {
        root: {
            fontSize: FontSizes.small,
            fontWeight: FontWeights.regular,
            color: Colors.DarkRed,
            height: "32px",
            padding: "8px 0"
        }
    };
    // theme color for breadcrumb
    const themeColor = [
        fontColorNeutralPrimary(theme),
        {
            // set font color to override the fluent ui default styles
            ":focus, :hover": [
                fontColorNeutralPrimary(theme),
                backgroundColorHover(theme)
            ],
            ":active": [
                fontColorNeutralPrimary(theme),
                backgroundColorSelected(theme)
            ]
        }
    ];
    const breadcrumb = {
        list: {
            button: themeColor
        },
        chevron: fontColorNeutralPrimary(theme),
        listItem: {
            ":last-child .ms-Breadcrumb-itemLink": themeColor
        },
        itemLink: themeColor
    };
    const dialogFooter = {
        actions: {
            margin: "0px"
        }
    };
    const subComponentStyles = {
        dialogHeaderText,
        breadcrumb,
        listDetails,
        listDetailsHeader,
        listDetailsRow,
        errorFolderIcon,
        emptyFolderIcon,
        emptyFolderText,
        attachmentError,
        dialogFooter
    };
    const oneDrivePickerStyles = {
        root,
        dialogHeader,
        listContainer,
        errorContainer,
        errorFolder,
        emptyContainer,
        emptyFolder,
        errorTitle,
        errorMessage,
        subComponentStyles
    };
    // @ts-ignore
    // subComponentStyles is required but concatStyleSets function always sets the subComponentStyles property optional
    // https://github.com/microsoft/fluentui/blob/dab45a2afe609709735b9b6e604a1af40d50e809/packages/merge-styles/src/IStyleSet.ts#L40
    return concatStyleSets(oneDrivePickerStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("oneDrivePicker", styles.root),
        dialogHeader: mergeStyles("dialogHeader", styles.dialogHeader),
        listContainer: mergeStyles("listContainer", styles.listContainer),
        errorContainer: mergeStyles("errorContainer", styles.errorContainer),
        errorFolder: mergeStyles("errorFolder", styles.errorFolder),
        emptyContainer: mergeStyles("emptyContainer", styles.emptyContainer),
        emptyFolder: mergeStyles("emptyFolder", styles.emptyFolder),
        errorTitle: mergeStyles("errorTitle", styles.errorTitle),
        errorMessage: mergeStyles("errorMessage", styles.errorMessage)
    }));
