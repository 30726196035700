// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
import { concatStyleSets, mergeStyles, memoizeFunction, keyframes } from "@fluentui/react";
import { confetti, confettiAnimationDuration } from "../../../utilities/ConfettiAnimation.styles";
/**
 * Helper to get styles for ChecklistProgressBarConfetti
 * @param theme Planner theme
 * @param confetti Flag to include confetti styling
 * @param animate Flag to start the confetti animation
 * @param customStyles Any custom style overrides
 */ export const getChecklistProgressBarConfettiStyles = memoizeFunction((theme, confetti, animate, customStyles)=>{
    const rootStyles = [
        {
            position: "relative",
            left: "-6px",
            ":after": [
                checklistConfetti(1, theme),
                checklistConfettiPosition,
                {
                    content: "''",
                    position: "absolute",
                    borderRadius: "50%"
                }
            ]
        },
        confetti && [
            {
                ":after": {
                    animation: "inherit",
                    animationTimingFunction: "ease-out",
                    animationDuration: confettiAnimationDuration
                }
            },
            animate && [
                {
                    ":after": {
                        animationName: checklistConfettiAnimation(theme)
                    }
                }
            ]
        ]
    ];
    const ChecklistProgressBarConfettiStyles = {
        root: rootStyles
    };
    return concatStyleSets(ChecklistProgressBarConfettiStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 * @param confetti Flag to include confetti styling
 * @param animate Flag to start the confetti animation
 * @param customStyles Any custom style overrides
 */ export const getChecklistProgressBarConfettiClassNames = memoizeFunction((theme, confetti, animate, customStyles)=>{
    const styles = getChecklistProgressBarConfettiStyles(theme, confetti, animate, customStyles);
    return {
        root: mergeStyles("checklistProgressBarConfetti", styles.root)
    };
});
// Animation constants
const bubbleDiameter = 1.2; // rem
const bubbleRadius = 0.5 * bubbleDiameter; // rem
const confettiDiameter = 0.5; // rem
const confettiRadius = 0.5 * confettiDiameter; // rem
const numGroups = 3;
// x/y components of confetti direction vector
const cosines = [
    0.70710678,
    1,
    0.70710678
];
const sines = [
    -0.70710678,
    0,
    0.70710678
];
const defaultColors = [
    Colors.ConfettiMagenta,
    Colors.ConfettiBlue,
    Colors.ConfettiOrange
];
const teamsLightColors = [
    Colors.TeamsAppBrand12,
    Colors.TeamsAppBrand00,
    Colors.TeamsAppBrand12
];
const teamsDarkColors = [
    Colors.TeamsAppBrand12,
    Colors.TeamsDarkThemeConfettiDarkPurple,
    Colors.TeamsAppBrand12
];
const teamsContrastColors = [
    Colors.White,
    Colors.White,
    Colors.White
];
/**
 * Confetti styles
 * @param k Animation interpolation value between 0 - 1
 * @param theme Planner theme
 */ const checklistConfetti = (k, theme)=>{
    let colors;
    switch(theme.name){
        case UnoTheme.TeamsLight:
            colors = teamsLightColors;
            break;
        case UnoTheme.TeamsDark:
            colors = teamsDarkColors;
            break;
        case UnoTheme.TeamsContrast:
            colors = teamsContrastColors;
            break;
        default:
            colors = defaultColors;
            break;
    }
    return confetti(k, bubbleRadius, confettiDiameter, numGroups, cosines, sines, colors);
};
// Confetti animation keyframes
const checklistConfettiAnimation = memoizeFunction((theme)=>keyframes({
        "0%, 20%": {
            opacity: 0
        },
        "25%": {
            opacity: 1,
            ...checklistConfetti(0, theme)
        }
    }));
// Confetti position
const checklistConfettiPosition = {
    margin: 0,
    marginTop: `${confettiRadius / 1.25}rem`,
    width: `${confettiDiameter}rem`,
    height: `${confettiDiameter}rem`
};
