// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { LoopBorderRadius, PlannerBorderRadius, LoopLightRestShadow, LoopDarkRestShadow, LoopSubItemBorderRadius, LoopMenuItemIconMarginRight } from "../../constants/StyleConstants";
// Styles
import { highContrastSelectionText } from "../../utilities/HighContrast.styles";
import { concatStyleSetsWithProps, memoizeFunction } from "@fluentui/react";
import { backgroundColorNeutralPrimary, fontColorNeutralPrimary, fontColorDisabled, styleScrollbar, checkmarkIconColor, backgroundColorSelected, backgroundColorHover, backgroundColorNeutralSecondary } from "../../utilities/Mixin.styles";
import { NeutralColors } from "@fluentui/react";
// Utilities
import { isLoopTheme } from "../../utilities/ThemingUtilities";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
/**
 * Contextual Menu Style constants
 */ const LoopMenuItemEdgeVerticalMargin = "6px";
const LoopMenuItemHorizontalMargin = "4px";
// backgroundColorHover is getting used at many places. Once we get updated colors from designer, we will update colors in backgroundColorHover
// and remove theme specific overrides (see PR #1151506)
const backgroundColorHoverTeamsLight = "#f5f5f5";
/**
 * Helper to get styles for ContextualMenu
 * @param customStyles Any custom style overrides
 */ export const getContextualMenuStyles = memoizeFunction((theme, customStyles)=>(props)=>concatStyleSetsWithProps(props, getThemedContextualMenuStyles(theme), customStyles));
/**
 * The styles for the Callout that hosts the Contextual Menu
 * @param theme App context theme
 */ export const getCalloutStyles = memoizeFunction((theme)=>{
    const isLoop = isLoopTheme(theme);
    return {
        root: [
            borderColor(theme),
            styleScrollbar(theme),
            fontColorNeutralPrimary(theme),
            isLoop && [
                menuBoxShadow(theme),
                {
                    borderRadius: LoopBorderRadius
                }
            ]
        ],
        beak: backgroundColorNeutralPrimary(theme),
        beakCurtain: isLoop && [
            backgroundColorNeutralPrimary(theme),
            {
                borderRadius: LoopBorderRadius
            }
        ],
        calloutMain: [
            backgroundColorNeutralPrimary(theme),
            theme.name === UnoTheme.TeamsContrast && {
                border: `1px solid ${Colors.White}`
            },
            {
                borderRadius: isLoop ? LoopBorderRadius : PlannerBorderRadius
            }
        ]
    };
});
/**
 * Get color styles for menu item text and icon
 * @param theme App context theme
 * @param props Menu item style props
 */ const textAndIconColor = memoizeFunction((theme, props)=>{
    const { disabled } = props;
    if (disabled) {
        return fontColorDisabled(theme);
    }
    return fontColorNeutralPrimary(theme);
});
/**
 * Get color styles for menu item text and icon when hovered
 * @param theme App context theme
 * @param props Menu item style props
 */ const textAndIconHoveredColor = memoizeFunction((theme, props)=>{
    if (theme.name === UnoTheme.TeamsContrast) {
        return {
            color: Colors.Black
        };
    }
    return textAndIconColor(theme, props);
});
/**
 * Get box shadow styles for the contextual menu
 * @param theme App context theme
 */ const menuBoxShadow = memoizeFunction((theme)=>{
    let boxShadow = LoopLightRestShadow;
    switch(theme.name){
        case UnoTheme.LoopDark:
            boxShadow = LoopDarkRestShadow;
            break;
        case UnoTheme.LoopLight:
        default:
            break;
    }
    return {
        boxShadow
    };
});
/**
 * Styles for the Callout border color
 * @param theme App context theme
 */ export const borderColor = memoizeFunction((theme)=>{
    let borderColor = Colors.White;
    switch(theme.name){
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            borderColor = NeutralColors.gray180;
            break;
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        default:
            break;
    }
    return {
        borderColor
    };
});
/**
 * The themed styles for the Contextual Menu to override default PWS colors
 * @param theme App context theme
 */ export const getThemedContextualMenuStyles = memoizeFunction((theme)=>{
    const isLoop = isLoopTheme(theme);
    return {
        root: [
            backgroundColorNeutralPrimary(theme),
            isLoop && {
                ".plannerCheckMark": checkmarkIconColor(theme)
            }
        ],
        subComponentStyles: {
            callout: getCalloutStyles(theme),
            menuItem: getContextualMenuItemStyleFunction(theme)
        }
    };
});
/**
 * The styles for the Contextual Menu Items
 * @param theme App context theme
 */ export const getContextualMenuItemStyleFunction = memoizeFunction((theme)=>(props)=>{
        const { disabled, expanded, checked } = props;
        const isLoop = isLoopTheme(theme);
        const root = [
            textAndIconColor(theme, props),
            disabled && [
                theme.name === UnoTheme.TeamsLight && {
                    backgroundColor: backgroundColorHoverTeamsLight
                }
            ],
            expanded && [
                backgroundColorSelected(theme),
                {
                    ".plannerIcon > span": highContrastSelectionText(),
                    ".ms-ContextualMenu-itemText, .plannerIcon, .textValue": textAndIconHoveredColor(theme, props)
                }
            ],
            checked && {
                ".ms-ContextualMenu-checkmarkIcon": textAndIconHoveredColor(theme, props)
            },
            {
                ":focus, :hover": [
                    textAndIconHoveredColor(theme, props),
                    backgroundColorHover(theme),
                    highContrastSelectionText(),
                    {
                        ".ms-ContextualMenu-checkmarkIcon, .ms-ContextualMenu-itemText, .plannerIcon, .textValue": [
                            textAndIconHoveredColor(theme, props),
                            {
                                span: highContrastSelectionText()
                            }
                        ]
                    },
                    theme.name === UnoTheme.TeamsLight && {
                        backgroundColor: backgroundColorHoverTeamsLight
                    }
                ],
                ".ms-Fabric--isFocusVisible &": {
                    ":focus, :hover, :focus:hover": [
                        backgroundColorHover(theme),
                        highContrastSelectionText()
                    ]
                }
            }
        ];
        const item = [
            checked ? backgroundColorSelected(theme) : backgroundColorNeutralPrimary(theme),
            {
                ":focus, :hover": [
                    backgroundColorHover(theme),
                    isLoop && {
                        borderRadius: LoopSubItemBorderRadius
                    },
                    theme.name === UnoTheme.TeamsLight && {
                        backgroundColor: backgroundColorHoverTeamsLight
                    }
                ],
                button: expanded && highContrastSelectionText()
            },
            isLoop && {
                marginLeft: LoopMenuItemHorizontalMargin,
                marginRight: LoopMenuItemHorizontalMargin,
                ":first-of-type": {
                    marginTop: LoopMenuItemEdgeVerticalMargin
                },
                ":last-of-type": {
                    marginBottom: LoopMenuItemEdgeVerticalMargin
                },
                button: [
                    {
                        borderRadius: LoopSubItemBorderRadius,
                        ":hover": [
                            backgroundColorHover(theme),
                            highContrastSelectionText(),
                            {
                                ".plannerIcon > span": highContrastSelectionText()
                            }
                        ]
                    },
                    expanded && backgroundColorNeutralSecondary(theme)
                ]
            }
        ];
        const label = [
            theme.name === UnoTheme.LoopLight && {
                color: Colors.NeutralLightGrey26
            },
            checked && textAndIconHoveredColor(theme, props)
        ];
        const icon = [
            textAndIconColor(theme, props),
            isLoop && {
                marginRight: LoopMenuItemIconMarginRight
            },
            {
                fontStyle: "normal"
            }
        ];
        const checkmarkIcon = textAndIconColor(theme, props);
        const subMenuIcon = [
            fontColorNeutralPrimary(theme),
            {
                ":hover, :active": fontColorNeutralPrimary(theme)
            }
        ];
        return {
            root,
            item,
            icon,
            label,
            checkmarkIcon,
            subMenuIcon
        };
    });
