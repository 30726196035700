/**
 * Enumeration of all possible workLoads in group
 */ export var GroupWorkLoad;
(function(GroupWorkLoad) {
    /**
     * Conversation url
     */ GroupWorkLoad[GroupWorkLoad["Conversations"] = 0] = "Conversations";
    /**
     * Calendar url
     */ GroupWorkLoad[GroupWorkLoad["Calendar"] = 1] = "Calendar";
    /**
     * Members url
     */ GroupWorkLoad[GroupWorkLoad["People"] = 2] = "People";
    /**
     * Files url
     */ GroupWorkLoad[GroupWorkLoad["Documents"] = 3] = "Documents";
    /**
     * Notebook url
     */ GroupWorkLoad[GroupWorkLoad["Notebook"] = 4] = "Notebook";
    /**
     * Yammer url
     */ GroupWorkLoad[GroupWorkLoad["Yammer"] = 5] = "Yammer";
})(GroupWorkLoad || (GroupWorkLoad = {}));
