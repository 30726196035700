// Styles
import { mergeStyles, memoizeFunction, concatStyleSetsWithProps, NeutralColors } from "@fluentui/react";
import { flexChildSizeFixed, flexChildSizeFlexible, flexParent, flexParentCenterAll, fontMedium, styleButtonAsDiv, textClip } from "../../../../utilities/Mixin.styles";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
const thumbnailSize = "28px";
const rowSize = "48px";
const paddingBetweenElements = "10px";
const fallbackPreviewIconSize = "28px";
const chickletSize = "272px";
const checkboxSize = "16px";
/**
 * Helper to get styles for OneDrivePickerForAttachments
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((props, customStyles)=>{
    const root = [
        flexParent("column"),
        {
            justifyContent: "space-between",
            overflow: "hidden"
        }
    ];
    const content = [
        flexChildSizeFlexible(),
        flexParent(),
        {
            alignItems: "center",
            justifyContent: "space-between"
        }
    ];
    const leftContent = [
        flexChildSizeFixed(chickletSize),
        flexParent("column"),
        {
            backgroundColor: Colors.NeutralLighterAlt,
            border: `solid 1px ${Colors.NeutralQuaternaryAlt}`,
            borderRadius: "2px",
            boxSizing: "border-box",
            boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)",
            height: rowSize,
            maxWidth: "100%",
            ":hover": {
                backgroundColor: Colors.NeutralLighter
            }
        }
    ];
    const attachmentChicklet = [
        flexParent(),
        flexChildSizeFlexible(),
        {
            alignItems: "center",
            "> *": {
                margin: `0px ${paddingBetweenElements}`
            }
        },
        props.unclickable && {
            borderBottom: `solid 2px ${Colors.DarkRed}`
        }
    ];
    const previewListImage = [
        flexChildSizeFixed(thumbnailSize),
        flexParent(),
        flexParentCenterAll,
        {
            cursor: props.unclickable ? "default" : "pointer",
            height: thumbnailSize,
            margin: `0px ${paddingBetweenElements}`
        },
        props.uploading && {
            "> .documentPreviewIcon": {
                color: Colors.NeutralSecondary
            }
        }
    ];
    const fallbackPreviewIcon = [
        flexChildSizeFixed(thumbnailSize),
        {
            fontSize: fallbackPreviewIconSize,
            "&.link > .ms-Image > img": [
                flexChildSizeFixed(fallbackPreviewIconSize),
                {
                    height: fallbackPreviewIconSize,
                    width: fallbackPreviewIconSize
                }
            ],
            "&.thumbnailImage > .ms-Image > img": [
                flexChildSizeFixed(),
                {
                    height: thumbnailSize,
                    width: thumbnailSize
                }
            ]
        }
    ];
    const itemListTitleContainer = [
        flexChildSizeFlexible(),
        flexParent("column"),
        {
            cursor: props.unclickable ? "default" : "pointer"
        }
    ];
    const itemListTitle = [
        flexChildSizeFixed("20px"),
        textClip()
    ];
    const itemListTitleText = {
        fontSize: "14px",
        color: props.uploading ? Colors.NeutralSecondary : NeutralColors.gray180
    };
    const itemListUrl = [
        flexChildSizeFixed("16px"),
        textClip()
    ];
    const itemListUrlText = {
        color: props.uploading ? Colors.NeutralSecondary : NeutralColors.gray140,
        fontSize: "12px"
    };
    const otherCommands = [
        flexChildSizeFixed(),
        flexParent(),
        flexParentCenterAll,
        fontMedium,
        {
            cursor: "pointer",
            "> *": [
                flexChildSizeFixed(),
                flexParent(),
                flexParentCenterAll
            ]
        },
        props.uploading && {
            display: "none"
        }
    ];
    const actionButton = [
        styleButtonAsDiv,
        flexParent(),
        flexParentCenterAll,
        fontMedium,
        {
            cursor: "pointer"
        },
        props.uploading && {
            display: "none"
        }
    ];
    const attachmentMoreButton = [
        styleButtonAsDiv,
        flexParent(),
        flexParentCenterAll,
        {
            cursor: "pointer"
        }
    ];
    const uploadProgress = {
        root: [
            flexChildSizeFixed("2px"),
            props.uploading && {
                opacity: 1
            }
        ],
        itemName: {
            display: "none"
        },
        itemProgress: {
            padding: "0px"
        }
    };
    const showOnCard = {
        root: [
            flexParent(),
            {
                alignSelf: "center",
                padding: `2px ${paddingBetweenElements} 0px ${paddingBetweenElements}`,
                opacity: 1
            },
            props.uploading && {
                display: "none"
            }
        ],
        checkbox: {
            borderRadius: "3px",
            height: checkboxSize,
            width: checkboxSize
        },
        label: {
            alignItems: "center"
        }
    };
    const brokenLinkIcon = [
        fontMedium,
        {
            margin: "2px 0px 0px 0px",
            cursor: "default",
            color: Colors.DarkRed
        }
    ];
    const taskAttachmentRowSubComponentStyles = {
        uploadProgress,
        showOnCard
    };
    const taskAttachmentRowStyles = {
        root,
        content,
        leftContent,
        attachmentChicklet,
        previewListImage,
        itemListTitleContainer,
        itemListTitle,
        itemListTitleText,
        itemListUrl,
        itemListUrlText,
        otherCommands,
        attachmentMoreButton,
        brokenLinkIcon,
        fallbackPreviewIcon,
        actionButton,
        subComponentStyles: taskAttachmentRowSubComponentStyles
    };
    return concatStyleSetsWithProps(props, taskAttachmentRowStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("taskAttachmentRow", styles.root),
        content: mergeStyles("content", styles.content),
        leftContent: mergeStyles("leftContent", styles.leftContent),
        attachmentChicklet: mergeStyles("attachmentChicklet", styles.attachmentChicklet),
        previewListImage: mergeStyles("preview-list-image", styles.previewListImage),
        itemListTitleContainer: mergeStyles("item-list-title-container", styles.itemListTitleContainer),
        itemListTitle: mergeStyles("item-list-title", styles.itemListTitle),
        itemListTitleText: mergeStyles("item-list-title-text", styles.itemListTitleText),
        itemListUrl: mergeStyles("item-list-url", styles.itemListUrl),
        itemListUrlText: mergeStyles("item-list-url-text", styles.itemListUrlText),
        otherCommands: mergeStyles("other-commands", styles.otherCommands),
        attachmentMoreButton: mergeStyles("attachmentMoreButton", styles.attachmentMoreButton),
        brokenLinkIcon: mergeStyles("broken-link-icon", styles.brokenLinkIcon),
        fallbackPreviewIcon: mergeStyles("fallbackPreviewIcon", styles.fallbackPreviewIcon),
        actionButton: mergeStyles("actionButton", styles.actionButton)
    }));
