// Models
import { DefaultTaskContainerPermissions } from "./ITaskContainerPermissions";
/**
 * Default perms to use when data is not available.
 */ export const DefaultTaskPermissions = {
    ...DefaultTaskContainerPermissions,
    canComment: false,
    canCopy: true,
    canEdit: true,
    canMove: true,
    canUploadFiles: false
};
