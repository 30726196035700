// Styles
import { mergeStyles, memoizeFunction, FontSizes, FontWeights, concatStyleSetsWithProps } from "@fluentui/react";
import { highContrastColor, highContrastSelectionBorder, highContrastSelectionTextInverted } from "../../../utilities/HighContrast.styles";
// Types
import { fontSmall } from "../../../utilities/Mixin.styles";
/**
 * Helper to get styles for CollapsibleSection
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((customStyles)=>{
    const root = {
        marginTop: "32px",
        ":first-child": {
            marginTop: "0px"
        }
    };
    const expandSectionButton = [
        {
            fontSize: FontSizes.medium,
            fontWeight: FontWeights.semibold,
            background: "transparent",
            border: "none",
            cursor: "pointer",
            paddingLeft: "0px",
            paddingTop: "0px",
            "> i": [
                fontSmall,
                {
                    marginRight: "8px",
                    verticalAlign: "middle"
                }
            ],
            ":hover": [
                highContrastSelectionBorder(),
                highContrastSelectionTextInverted
            ]
        },
        highContrastColor
    ];
    const collapsibleSectionStyles = {
        root,
        expandSectionButton
    };
    return concatStyleSetsWithProps(undefined, collapsibleSectionStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("collapsibleSection", styles.root),
        expandSectionButton: mergeStyles("expandSectionButton", styles.expandSectionButton)
    }));
