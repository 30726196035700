// Constants
import { FontSizes, FontWeights, NeutralColors } from "@fluentui/react";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Styles
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { highContrastBorders, highContrastHighlightStyle, highContrastLine, highContrastLineInverted, highContrastSelectionText } from "../../../../utilities/HighContrast.styles";
import { activeButtonColor, backgroundColorHover, dialogBackgroundPrimary, dialogControlPlaceholderStyle, dialogControlStyle, errorColor, flexChildSizeFixed, flexChildSizeFlexible, flexParent, flexParentCenterAll, fontColorDisabled, fontColorNeutralPrimary, fontColorNeutralSecondary, fontColorNeutralTertiary, fontColorThemePrimary, linkColorNeutralPrimary, textClip, toggleBackgroundColor, toggleBorderColor } from "../../../../utilities/Mixin.styles";
// Utilities
import { numberToPx, pxToNumber } from "@ms/uno-utilities/lib/local/StyleUtilities";
import { isLoopTheme } from "../../../../utilities/ThemingUtilities";
/**
 * Task Editor local style constants
 */ const assignmentMargins = "5px";
const checklistProgressBarBorderRadius = "100px";
const checklistProgressBarColorStop1 = "#00ab54";
const checklistProgressBarColorStop2 = "#094624";
const checklistProgressBarHeight = "4px";
const checklistProgressBarPadding = "25px";
const completeButtonAnimationScale = "scale(1.111111)";
const completeButtonHeight = "20px";
const completeButtonWidth = "20px";
const descriptionHeight = "20px";
const fabricVerticalPadding = "20px";
const labelIconRightPadding = "5px";
const labelPickerMinHeight = "32px";
const marginBetweenDetailsSections = "20px";
const marginBetweenPropertySections = "10px";
const minWidthOfTextField = "175px";
const sectionMargin = "32px";
const setPreviewCheckboxPadding = "30px";
const taskEditorPadding = "0px 25px";
const taskEditorPaddingLoop = "0px 92px";
const totalHorizontalPadding = "53px";
const notesPreviewHeight = "146px";
// We need to take into  account the default padding for RTE component which is 10px.
// Hence the minimum haight of RTE is 136px.
const notesRTEPreviewHeight = "136px";
const calloutPadding = "8px";
const calloutPaddingLeft = "20px";
const requiredLabelPadding = "10px";
/**
 * Label style for task editor
 */ export const taskEditorLabelStyle = memoizeFunction((theme)=>{
    let fontWeight = FontWeights.semibold;
    switch(theme.name){
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
            fontWeight = FontWeights.regular;
            break;
        default:
            break;
    }
    const style = [
        fontColorNeutralSecondary(theme),
        {
            fontSize: FontSizes.medium,
            fontWeight: fontWeight
        }
    ];
    return style;
});
/**
 * Helper to get styles for TaskEditor
 * @param theme Planner theme
 * @param isReadonly Whether the control is in readonly mode
 * @param isTitleReadonly Whether the title in control is in readonly mode
 * @param isComplete Whether task is completed
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, config, isComplete, customStyles)=>{
    const isReadonly = !config.canEdit;
    const isTitleReadonly = isReadonly || !config.canEditTitle;
    const isThemeLoop = isLoopTheme(theme);
    let progressBarColorStops = [
        `${checklistProgressBarColorStop1} 0%`,
        `${checklistProgressBarColorStop2} 100%`
    ];
    let progressTrackBackgroundColor = Colors.NeutralLighter;
    let progressTrackBorder = "";
    let rootPadding = taskEditorPadding;
    switch(theme.name){
        case UnoTheme.TeamsLight:
            progressBarColorStops = [
                `${Colors.Teams12} 0%`,
                `${Colors.TeamsAppBrand00} 100%`
            ];
            progressTrackBackgroundColor = Colors.NeutralLighter;
            break;
        case UnoTheme.TeamsDark:
            progressBarColorStops = [
                `${Colors.Teams12} 0%`,
                `${Colors.TeamsAppBrand00} 100%`
            ];
            progressTrackBackgroundColor = Colors.NeutralDark;
            break;
        case UnoTheme.TeamsContrast:
            progressBarColorStops = [
                `${Colors.White} 0%`,
                `${Colors.White} 100%`
            ];
            progressTrackBackgroundColor = Colors.Black;
            progressTrackBorder = `1px solid ${Colors.White}`;
            break;
        case UnoTheme.LoopWorkspaceLight:
        case UnoTheme.LoopWorkspaceDark:
            progressBarColorStops = [
                `${Colors.LoopBrand140} 0%`,
                `${Colors.LoopBrand50} 100%`
            ];
            rootPadding = taskEditorPaddingLoop;
            break;
        case UnoTheme.SharePoint:
        case UnoTheme.Light:
        case UnoTheme.Dark:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        default:
            break;
    }
    const progressBarBackgroundImage = `linear-gradient(270deg, ${progressBarColorStops[0]}, ${progressBarColorStops[1]})`;
    const root = [
        theme.name === UnoTheme.SharePoint && {
            backgroundColor: Colors.SharepointBackgroundColor()
        },
        isThemeLoop && {
            ".taskAttachments": {
                ".addAttachmentButtonWrapper > .addAttachmentButton": {
                    border: "1px solid " + Colors.NeutralLightGrey82,
                    borderRadius: "8px"
                }
            }
        },
        {
            fontSize: FontSizes.small,
            padding: rootPadding,
            "> .removeMargin": {
                marginBottom: "16px",
                "> .ms-TextField .ms-TextField-wrapper textarea": dialogControlPlaceholderStyle(theme),
                ".taskAttachments": {
                    ".emptyAttachmentsSection, .ms-Label.attachmentLabel": taskEditorLabelStyle(theme),
                    ".addAttachmentButtonWrapper > .addAttachmentButton": {
                        ":focus, :hover": [
                            backgroundColorHover(theme),
                            highContrastHighlightStyle()
                        ]
                    }
                },
                "> .labelPickerArea": fontColorNeutralSecondary(theme),
                "> .AppPoweredTasksEditorView": {
                    opacity: 0.5
                },
                ".completeIcon": {
                    margin: "auto 10px auto auto"
                }
            }
        }
    ];
    const messageBanner = {
        marginBottom: "10px",
        marginTop: "5px"
    };
    const completeIcon = [
        flexParent()
    ];
    const planTitle = [
        fontColorThemePrimary(theme),
        {
            fontSize: FontSizes.small,
            fontWeight: FontWeights.semibold,
            marginBottom: "4px",
            "&.link": [
                linkColorNeutralPrimary(theme),
                {
                    cursor: "pointer",
                    ":hover": [
                        theme.name === UnoTheme.Dark && {
                            color: Colors.FontColorThemeLight
                        },
                        theme.name === UnoTheme.Light && {
                            color: Colors.FontColorThemeDarker
                        }
                    ]
                }
            ]
        }
    ];
    const taskTitle = [
        flexParent(),
        isComplete && {
            "> .edit input": [
                !isTitleReadonly && fontColorNeutralSecondary(theme),
                {
                    textDecoration: "line-through"
                }
            ]
        },
        {
            ".ms-TextField": [
                flexChildSizeFlexible(),
                {
                    ".ms-TextField-field": [
                        theme.name === UnoTheme.SharePoint && {
                            color: Colors.SharepointTitleFontColor()
                        },
                        {
                            padding: "0 4px"
                        }
                    ]
                }
            ],
            "> .completeButtonWithAnimation": {
                transform: completeButtonAnimationScale,
                height: completeButtonHeight,
                width: completeButtonWidth,
                marginTop: "6px",
                marginRight: "12px"
            }
        },
        isTitleReadonly && {
            "> .ms-TextField > .ms-TextField-wrapper > .ms-TextField-fieldGroup > .ms-TextField-field": {
                outline: "none",
                border: "none"
            }
        }
    ];
    const lastModifiedSection = [
        fontColorNeutralSecondary(theme),
        {
            marginBottom: sectionMargin
        }
    ];
    const assignedToUsers = [
        flexParent(),
        {
            marginTop: `${numberToPx(pxToNumber(marginBetweenDetailsSections) / 2)}`,
            marginBottom: `${numberToPx(pxToNumber(marginBetweenDetailsSections) - pxToNumber(assignmentMargins))}`,
            marginRight: "0px",
            marginLeft: "0px"
        }
    ];
    const dropdowns = {
        ".ms-TextField-field": [
            theme.name === UnoTheme.SharePoint && {
                backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor()
            }
        ],
        "> .removeMargin": [
            flexParent("row", "wrap"),
            {
                margin: `${numberToPx(pxToNumber(marginBetweenDetailsSections) / 2)} -${marginBetweenPropertySections}`,
                "> *": {
                    margin: `0px ${marginBetweenPropertySections}`
                },
                ".dropdownTuple": [
                    flexChildSizeFlexible(minWidthOfTextField),
                    {
                        ".ms-Label, .ms-TextField .ms-Label": [
                            taskEditorLabelStyle(theme),
                            {
                                marginBottom: "0px"
                            }
                        ],
                        "> .ms-Dropdown-container > *:nth-child(2)": [
                            theme.name === UnoTheme.SharePoint && {
                                border: "1px",
                                borderStyle: "solid",
                                borderColor: Colors.SharepointCardSeparator()
                            }
                        ],
                        ".ms-DatePicker": {
                            fontSize: FontSizes.small,
                            ".ms-Icon": {
                                fontSize: FontSizes.size16,
                                "::before": [
                                    fontColorNeutralPrimary(theme)
                                ]
                            }
                        }
                    },
                    isThemeLoop && {
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        ".plannerIcon.plannerCalendar": fontColorNeutralTertiary(theme)
                    }
                ]
            }
        ]
    };
    const bucketRecurrenceDropdown = [
        flexChildSizeFlexible(),
        flexParent(),
        {
            justifyContent: "left",
            alignItems: "center"
        },
        textClip("ellipsis")
    ];
    const dropdownsItems = [
        flexChildSizeFlexible(),
        {
            overflow: "hidden",
            "> *": [
                flexParent(),
                flexParentCenterAll,
                {
                    margin: "-4px",
                    "> *": [
                        flexChildSizeFixed(),
                        {
                            margin: "4px"
                        }
                    ],
                    "> .textValue": [
                        flexChildSizeFlexible(),
                        textClip("ellipsis")
                    ],
                    "> .icon": [
                        theme.name === UnoTheme.SharePoint && {
                            ".plannerProgressInProgress, .plannerProgressInProgressSmall": {
                                color: Colors.SharepointBlueIconColor()
                            },
                            ".plannerProgressComplete, .plannerProgressCompleteSmall": {
                                color: Colors.SharepointCompleteColor()
                            },
                            ".plannerPriorityUrgent, .plannerPriorityUrgentSmall": {
                                color: Colors.SharepointHighPriIconColor()
                            },
                            ".plannerPriorityImportant, .plannerPriorityImportantSmall": {
                                color: Colors.SharepointHighPriIconColor()
                            },
                            ".plannerPriorityMedium": {
                                color: Colors.SharepointCompleteColor()
                            },
                            ".plannerPriorityLow": {
                                color: Colors.SharepointBlueIconColor()
                            },
                            ".plannerRecurrence, .plannerRecurrenceSmall ": {
                                color: Colors.SharepointHighPriIconColor()
                            }
                        }
                    ]
                }
            ]
        }
    ];
    const checklist = [
        {
            margin: `${marginBetweenDetailsSections} 0px`,
            "> .checklistInfo": [
                flexParent(),
                flexParentCenterAll,
                {
                    height: "30px",
                    ".checklistDetails": [
                        taskEditorLabelStyle(theme),
                        flexChildSizeFlexible(),
                        flexParent(),
                        {
                            paddingRight: checklistProgressBarPadding,
                            "> .labelTag": {
                                marginRight: requiredLabelPadding,
                                marginLeft: requiredLabelPadding
                            },
                            "> .checklistFraction": {
                                paddingLeft: "5px"
                            }
                        },
                        theme.name === UnoTheme.TeamsContrast && {
                            color: Colors.White
                        }
                    ],
                    "> .checklistCompletionBar": [
                        flexChildSizeFlexible(),
                        {
                            ".ms-ProgressIndicator-progressTrack": [
                                highContrastLineInverted(),
                                highContrastBorders(),
                                {
                                    backgroundColor: progressTrackBackgroundColor,
                                    border: progressTrackBorder,
                                    boxSizing: "border-box",
                                    borderRadius: checklistProgressBarBorderRadius,
                                    height: checklistProgressBarHeight
                                }
                            ],
                            ".ms-ProgressIndicator-progressBar": [
                                highContrastLine(),
                                {
                                    MsHighContrastAdjust: "none",
                                    backgroundImage: progressBarBackgroundImage,
                                    borderRadius: `${checklistProgressBarBorderRadius} 0px 0px ${checklistProgressBarBorderRadius}`,
                                    height: checklistProgressBarHeight
                                }
                            ]
                        }
                    ],
                    "> .checklistCompleted .ms-ProgressIndicator-progressBar": {
                        borderRadius: checklistProgressBarBorderRadius
                    },
                    "> .setPreviewCheckbox": [
                        flexParent(),
                        flexChildSizeFixed(),
                        {
                            fontSize: FontSizes.small,
                            paddingLeft: setPreviewCheckboxPadding,
                            "> .ms-Checkbox": [
                                theme.name === UnoTheme.SharePoint && {
                                    ".ms-Checkbox-text": {
                                        color: Colors.SharepointTitleFontColor()
                                    }
                                },
                                theme.name === UnoTheme.TeamsContrast && {
                                    ".ms-Checkbox-text": {
                                        color: Colors.White
                                    },
                                    ".ms-Checkbox-checkbox": {
                                        color: Colors.White,
                                        "> .ms-Checkbox-checkmark": {
                                            color: Colors.White
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];
    const mandatoryCheckListInfoCalloutContent = [
        flexParent("column"),
        dialogBackgroundPrimary(theme),
        {
            boxSizing: "border-box",
            width: "288px",
            padding: `${calloutPadding} ${calloutPadding} 0 ${calloutPaddingLeft}`
        }
    ];
    const mandatoryChecklistInfoTooltip = [
        flexParent("column"),
        {
            ".ms-Callout-beak": [
                theme.name === UnoTheme.TeamsDark && {
                    backgroundColor: NeutralColors.gray170
                },
                theme.name === UnoTheme.TeamsContrast && {
                    backgroundColor: Colors.Black
                }
            ]
        },
        {
            ".infoSection": [
                {
                    "> .description": {
                        fontSize: FontSizes.small,
                        fontWeight: FontWeights.semilight,
                        padding: "8px",
                        textAlign: "center"
                    }
                }
            ]
        }
    ];
    const mandatoryChecklistInfo = [
        {
            marginLeft: "5px"
        }
    ];
    const mandatoryChecklistToggle = [
        {
            borderColor: "inherit",
            "> .ms-Toggle": [
                {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    marginBottom: "0px",
                    "> .ms-Label": [
                        taskEditorLabelStyle(theme),
                        {
                            paddingRight: "8px",
                            paddingBottom: "0px",
                            paddingTop: "0px"
                        }
                    ]
                }
            ],
            ".ghostTasksToggle.toggleOn": [
                {
                    button: [
                        toggleBackgroundColor(theme)
                    ]
                }
            ],
            ".ghostTasksToggle.toggleOff": [
                {
                    ".ms-Toggle-thumb": [
                        toggleBackgroundColor(theme)
                    ],
                    button: [
                        toggleBorderColor(theme),
                        {
                            backgroundColor: "inherit"
                        }
                    ]
                }
            ],
            "button.activeView": [
                activeButtonColor(theme),
                {
                    fontWeight: FontWeights.bold
                }
            ]
        }
    ];
    const setPreviewCheckbox = [
        flexChildSizeFixed(),
        {
            cursor: "pointer",
            "> .ms-Checkbox": [
                {
                    "> .ms-Checkbox-label": {
                        alignItems: "center",
                        "> *": {
                            fontSize: FontSizes.small
                        }
                    },
                    ".ms-Checkbox-checkbox": {
                        borderRadius: "3px"
                    }
                },
                theme.name === UnoTheme.SharePoint && {
                    "&.is-checked.is-enabled > .ms-Checkbox-label > .ms-Checkbox-checkbox": {
                        backgroundColor: Colors.SharepointCompleteColor(),
                        borderColor: Colors.SharepointCompleteColor(),
                        "> .ms-Icon": {
                            color: Colors.White
                        }
                    }
                }
            ]
        }
    ];
    const descriptionHyperLinks = [
        highContrastBorders(),
        dialogControlStyle(theme),
        fontColorNeutralPrimary(theme),
        dialogControlPlaceholderStyle(theme),
        {
            fontFamily: "'Segoe UI Regular WestEuropean', 'Segoe UI', Tahoma, Arial, sans-serif",
            fontSize: FontSizes.size14,
            lineHeight: "17px",
            minHeight: "54px",
            paddingTop: "6px",
            paddingLeft: "8px",
            paddingRight: "12px"
        },
        isReadonly && fontColorDisabled(theme),
        theme.name === UnoTheme.TeamsContrast && {
            backgroundColor: Colors.Black,
            borderColor: Colors.White,
            borderWidth: "1px",
            borderStyle: "solid"
        },
        theme.name !== UnoTheme.SharePoint && {
            borderRadius: isThemeLoop ? "3px" : "4px"
        },
        theme.name === UnoTheme.SharePoint && {
            backgroundColor: Colors.SharepointBackgroundColor()
        },
        theme.name === UnoTheme.SharePoint && {
            backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor()
        }
    ];
    const description = [
        {
            boxSizing: "border-box",
            minHeight: descriptionHeight,
            margin: `${numberToPx(pxToNumber(marginBetweenDetailsSections) / 2)} 0px ${marginBetweenDetailsSections} 0px`,
            wordWrap: "break-word",
            "> .descriptionHeader": [
                flexParent(),
                {
                    height: "30px",
                    alignItems: "center",
                    ".descriptionTitle": [
                        flexChildSizeFlexible(),
                        textClip("ellipsis"),
                        taskEditorLabelStyle(theme),
                        {
                            float: "left",
                            width: "auto",
                            paddingTop: "0px",
                            wordWrap: "break-word"
                        }
                    ]
                }
            ],
            "> .description-hyperlinks": descriptionHyperLinks,
            "> .edit": [
                {
                    "> textarea": [
                        theme.name === UnoTheme.SharePoint && {
                            color: Colors.SharepointTitleFontColor()
                        }
                    ]
                }
            ]
        }
    ];
    const commentsSection = {
        margin: `0px -${totalHorizontalPadding} -${fabricVerticalPadding} -${totalHorizontalPadding}`,
        padding: `0px ${totalHorizontalPadding} ${fabricVerticalPadding} ${totalHorizontalPadding}`,
        ul: {
            margin: "0",
            padding: "0"
        },
        li: {
            listStyleType: "none"
        }
    };
    const incompleteTaskRequiredChecklistHeaderItem = {
        color: errorColor(theme)
    };
    const completeTaskRequiredChecklistHeaderItem = {
        color: checklistProgressBarColorStop1
    };
    const labelPickerArea = [
        flexParent("row", "nowrap"),
        {
            alignItems: "center",
            "> .plannerIcon": [
                flexChildSizeFixed(),
                {
                    paddingRight: labelIconRightPadding,
                    cursor: "default",
                    lineHeight: labelPickerMinHeight
                }
            ],
            ".labelPicker": [
                flexChildSizeFlexible()
            ]
        }
    ];
    const TaskEditorStyles = {
        root,
        messageBanner,
        planTitle,
        completeIcon,
        taskTitle,
        lastModifiedSection,
        assignedToUsers,
        dropdowns,
        dropdownsItems,
        checklist,
        setPreviewCheckbox,
        setMandatoryChecklistToggle: mandatoryChecklistToggle,
        mandatoryCheckListToggleInfo: mandatoryChecklistInfo,
        mandatoryChecklistInfoTooltip,
        mandatoryCalloutContent: mandatoryCheckListInfoCalloutContent,
        description,
        commentsSection,
        labelPickerArea,
        incompleteTaskRequiredChecklistHeaderItem,
        completeTaskRequiredChecklistHeaderItem,
        bucketRecurrenceDropdown
    };
    return concatStyleSets(TaskEditorStyles, customStyles);
});
/**
 * Get custom style for the AttachmentSection control
 * @param theme App context theme
 */ export const customAttachmentSectionStyles = memoizeFunction((theme)=>{
    return {
        subComponentStyles: {
            addAttachmentButton: {
                rootHovered: [
                    backgroundColorHover(theme),
                    highContrastSelectionText()
                ],
                rootFocused: [
                    backgroundColorHover(theme),
                    highContrastSelectionText()
                ],
                rootPressed: [
                    backgroundColorHover(theme),
                    highContrastSelectionText()
                ]
            },
            attachmentLabel: {
                root: taskEditorLabelStyle(theme)
            }
        },
        emptyAttachmentsSection: taskEditorLabelStyle(theme)
    };
});
/**
 * Get custom style for the RichTextEditor control
 * @param theme App context theme
 */ export const customRichTextEditorStyles = memoizeFunction((theme)=>(props)=>{
        const { readOnly } = props;
        const root = [
            highContrastBorders(),
            {
                fontSize: FontSizes.size14,
                paddingTop: "6px",
                paddingLeft: "8px",
                paddingRight: "12px"
            }
        ];
        const editorWrapper = [
            dialogControlStyle(theme),
            fontColorNeutralPrimary(theme),
            dialogControlPlaceholderStyle(theme),
            {
                minHeight: notesPreviewHeight,
                marginRight: "-10px !important",
                /*When you copy paste content in Rich text editor, some instances, it copies the background color in the html
                     this fix is required to override all the children background color to be transparent */ "div *:not(.child):not(.ribbon):not(.ms-Button):not(.ms-Button *)": {
                    backgroundColor: "transparent !important",
                    "& a": {
                        color: "inherit !important"
                    }
                }
            }
        ];
        const notesEditor = [
            {
                backgroundColor: "transparent !important",
                minHeight: notesRTEPreviewHeight
            },
            readOnly && {
                color: "inherit !important"
            },
            /* When you copy paste the in Rich Text Editor, bullets are trimmed by PWS overflow styles due to notes padding.
                 So we have to reset the padding and restore the list style to see the bullets properly*/ {
                ul: {
                    padding: "revert"
                }
            },
            {
                li: {
                    listStyleType: "initial"
                }
            },
            !readOnly && {
                "._EId_WATERMARK_WRAPPER": [
                    theme.name !== UnoTheme.TeamsContrast && {
                        color: "inherit !important"
                    },
                    theme.name === UnoTheme.TeamsContrast && {
                        color: Colors.TeamsContrastPlaceHolderOrDisabledColor + "!important"
                    }
                ]
            }
        ];
        const expandNotesButton = [
            (theme.name === UnoTheme.TeamsLight || theme.name === UnoTheme.TeamsDark) && {
                color: Colors.TeamsAppBrand00
            },
            theme.name === UnoTheme.TeamsContrast && {
                color: Colors.TeamsContrastHyperlinkColor
            }
        ];
        const styles = {
            root,
            expandNotesButton,
            editorWrapper,
            notesEditor
        };
        return styles;
    });
/**
 * Helper to get styles for Message Bar
 * @param theme Planner theme
 */ const getMessageBarLinkColor = memoizeFunction((theme)=>{
    switch(theme.name){
        case UnoTheme.Light:
            return Colors.ThemeTertiary;
        case UnoTheme.Dark:
            return Colors.ThemePrimary;
        case UnoTheme.TeamsLight:
            return Colors.TeamsDarkPurple;
        case UnoTheme.TeamsDark:
            return Colors.TeamsPurple;
        case UnoTheme.TeamsContrast:
            return Colors.TeamsContrastHyperlinkColor;
        case UnoTheme.SharePoint:
            return Colors.SharepointBackgroundColor();
        default:
            return Colors.ThemeTertiary;
    }
});
/**
 * Styles for the MessageBar content
 * @param { Theme } theme The applied theme
 */ export const customMessageBarStyles = memoizeFunction((theme)=>{
    const themeName = theme.name;
    const root = [
        {
            "a.ms-Link": {
                ":link, :visited, :hover, :active": getMessageBarLinkColor(theme)
            }
        },
        (themeName === UnoTheme.Light || themeName === UnoTheme.TeamsLight || themeName === UnoTheme.Dark || themeName === UnoTheme.TeamsDark) && {
            backgroundColor: Colors.NeutralLighter,
            color: Colors.NeutralPrimary
        },
        themeName === UnoTheme.TeamsContrast && {
            backgroundColor: Colors.Black,
            color: Colors.White,
            border: `1px solid ${Colors.White}`
        },
        themeName === UnoTheme.SharePoint && {
            backgroundColor: Colors.SharepointTitleFontColor(),
            color: Colors.SharepointBackgroundColor()
        }
    ];
    const icon = [
        (themeName === UnoTheme.Light || themeName === UnoTheme.TeamsLight || themeName === UnoTheme.Dark || themeName === UnoTheme.TeamsDark) && {
            color: Colors.NeutralPrimary
        },
        themeName === UnoTheme.TeamsContrast && {
            color: Colors.White
        },
        themeName === UnoTheme.SharePoint && {
            color: Colors.SharepointBackgroundColor()
        }
    ];
    const messageBarStyles = {
        root,
        icon
    };
    return messageBarStyles;
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("taskEditor", styles.root),
        messageBanner: mergeStyles("messageBanner", styles.messageBanner),
        planTitle: mergeStyles("planTitle", styles.planTitle),
        taskTitle: mergeStyles("title", styles.taskTitle),
        lastModifiedSection: mergeStyles("lastModifiedSection", styles.lastModifiedSection),
        assignedToUsers: mergeStyles("assignedToUsers", styles.assignedToUsers),
        dropdowns: mergeStyles("dropdowns", styles.dropdowns),
        dropdownsItems: mergeStyles("dropdownsItems", styles.dropdownsItems),
        checklist: mergeStyles("checklist", styles.checklist),
        setPreviewCheckbox: mergeStyles("setPreviewCheckbox", styles.setPreviewCheckbox),
        mandatoryCheckListToggleInfo: mergeStyles("mandatoryCheckListToggleInfo", styles.mandatoryCheckListToggleInfo),
        setMandatoryChecklistToggle: mergeStyles("setMandatoryChecklistToggle", styles.setMandatoryChecklistToggle),
        description: mergeStyles("description", styles.description),
        commentsSection: mergeStyles("commentsSection", styles.commentsSection),
        labelPickerArea: mergeStyles("labelPickerArea", styles.labelPickerArea),
        mandatoryChecklistInfoTooltip: mergeStyles("mandatoryChecklistInfoTooltip", styles.mandatoryChecklistInfoTooltip),
        mandatoryCalloutContent: mergeStyles("mandatoryCalloutContent", styles.mandatoryCalloutContent),
        completeIcon: mergeStyles("completeIcon", styles.completeIcon),
        incompleteTaskRequiredChecklistHeaderItem: mergeStyles("incompleteTaskRequiredChecklistHeaderItem", styles.incompleteTaskRequiredChecklistHeaderItem),
        completeTaskRequiredChecklistHeaderItem: mergeStyles("completeTaskRequiredChecklistHeaderItem", styles.completeTaskRequiredChecklistHeaderItem),
        bucketRecurrenceDropdown: mergeStyles("bucketRecurrenceDropdown", styles.bucketRecurrenceDropdown)
    }));
