// Models
import { ResourceType } from "../../service/project/xrm/IXrmResource";
import { User } from "./User";
import { AadUserType, UserType } from "./UserType";
// Utility
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
/**
 * Configuration and building of user objects
 */ export class UserBuilder {
    /**
     * Build out the user
     */ build() {
        return new User(this);
    }
    /**
     * Build out the user with a lower case id
     * @deprecated Use build instead. Remove with the removal of CaseSensitiveId flight in pexui
     */ buildWithLowerCaseId() {
        const pristineId = this.id;
        this.id = this.id.toLowerCase();
        const user = new User(this);
        this.id = pristineId;
        return user;
    }
    /**
     * Build from a user resource entity as initialization data.
     * @param userResource User resource entity to use for initializing the user builder
     */ fromUserResource(userResource) {
        let userType = null;
        if (userResource.userType === "Guest") {
            userType = UserType.Guest;
        } else if (userResource.userType === "Member") {
            userType = UserType.Member;
        }
        return this.withPropertyBag({
            displayName: userResource.displayName || userResource.userPrincipalName || "",
            givenName: userResource.givenName || "Guest",
            id: userResource.id,
            mail: userResource.mail ?? undefined,
            proxyAddresses: userResource.proxyAddresses || [],
            surname: userResource.surname || undefined,
            userPrincipalName: userResource.userPrincipalName,
            jobTitle: userResource.jobTitle || undefined,
            userType: userType || undefined,
            preferredLanguage: userResource.preferredLanguage || null,
            usageLocation: userResource.usageLocation || null
        });
    }
    /**
     * Convert from Xrm Resource type to User type
     * @param resource The resource to convert from
     */ fromXrmResource(resource) {
        /**
         * If the user is an AAD user then their AAD ID will be their id and aadId will possibly be null or all zeros.
         * If the user's type is not set then check their aadId first otherwise use id.
         */ return this.withPropertyBag({
            id: resource.resourceType === ResourceType.AadUser ? resource.id : resource.aadId || resource.id,
            displayName: resource.name,
            jobTitle: resource.jobTitle,
            userPrincipalName: resource.userPrincipalName || "",
            userType: resource.aadUserType === AadUserType.Guest ? UserType.Guest : UserType.Member,
            mail: resource.email || ""
        });
    }
    /**
     * Build from a Graph user resource entity as initialization data.
     * @param userResource Graph user resource entity to use for initializing the user builder
     */ fromGraphUserResource(userResource) {
        return this.withPropertyBag({
            displayName: userResource.displayName || userResource.userPrincipalName || "",
            givenName: userResource.givenName || "Guest",
            id: userResource.id,
            mail: userResource.mail || undefined,
            proxyAddresses: userResource.proxyAddresses || [],
            surname: userResource.surname || undefined,
            userPrincipalName: userResource.userPrincipalName,
            jobTitle: userResource.jobTitle || undefined,
            userType: this.userTypeStringToEnum(userResource.userType) || undefined,
            preferredLanguage: userResource.preferredLanguage || null,
            accountEnabled: userResource.accountEnabled || false,
            usageLocation: userResource.usageLocation || null
        });
    }
    /**
     * Build from a user resource entity as initialization data.
     * @param userResource SP user resource entity to use for initializing the user builder
     */ fromSPUserResource(userResource) {
        return this.withPropertyBag({
            displayName: userResource.displayName || userResource.principalName || "",
            id: userResource.id,
            mail: userResource.mail,
            userPrincipalName: userResource.principalName
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.displayName = propertyBag.displayName || this.displayName;
        this.givenName = propertyBag.givenName || this.givenName;
        this.id = propertyBag.id;
        this.mail = propertyBag.mail || this.mail;
        this.proxyAddresses = propertyBag.proxyAddresses || this.proxyAddresses;
        this.surname = propertyBag.surname || this.surname;
        this.userPrincipalName = propertyBag.userPrincipalName || this.userPrincipalName;
        this.jobTitle = propertyBag.jobTitle || this.jobTitle;
        this.userType = propertyBag.userType || this.userType;
        this.preferredLanguage = propertyBag.preferredLanguage !== undefined ? propertyBag.preferredLanguage : this.preferredLanguage; // Triple check since this is special case of nullable
        this.accountEnabled = propertyBag.accountEnabled || this.accountEnabled;
        this.usageLocation = propertyBag.usageLocation || this.usageLocation;
        return this;
    }
    /**
     * Add a locally unique id to the user
     */ withLocallyUniqueId() {
        this.id = LocalIdGenerator.generate();
        return this;
    }
    /**
     * Convert the user type from string to enum
     * @param stringType The string user type to convert to enum
     */ userTypeStringToEnum(stringType) {
        let enumType = null;
        if (stringType === "Guest") {
            enumType = UserType.Guest;
        } else if (stringType === "Member") {
            enumType = UserType.Member;
        }
        return enumType;
    }
    /**
     * Initialize an instance of the user builder
     */ constructor(){
        this.id = "";
        this.displayName = "";
        this.givenName = "";
        this.jobTitle = "";
        this.mail = "";
        this.proxyAddresses = [];
        this.surname = "";
        this.userPrincipalName = "";
        this.userType = null;
        this.preferredLanguage = null;
        this.accountEnabled = false;
        this.usageLocation = null;
    }
}
