// Constants
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { HighContrastMediaQuery, HighContrastWhiteOnBlackMediaQuery } from "../constants/StyleConstants";
// Utilities
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
/**
 * Determines whether Windows high contrast theme is on
 * @returns true/false boolean
 */ export function isWindowsHighContrast() {
    if (window.matchMedia != null) {
        const isHighContrast = window.matchMedia(HighContrastMediaQuery).matches;
        return isHighContrast;
    }
    return false;
}
/**
 * Determines whether the Windows high contrast theme is a white-on-black variation
 * @returns true/false boolean
 */ export function isWindowsHighContrastWhiteOnBlack() {
    if (window.matchMedia != null) {
        const isWhiteOnBlack = window.matchMedia(HighContrastWhiteOnBlackMediaQuery).matches;
        return isWhiteOnBlack;
    }
    return false;
}
/**
 * Determines whether the current theme is a Loop theme
 * @param theme current app theme
 */ export function isLoopTheme(theme) {
    switch(theme.name){
        case UnoTheme.LoopLight:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceLight:
        case UnoTheme.LoopWorkspaceDark:
            return true;
        default:
            return false;
    }
}
/**
 * Gets the TeamsMessage color for a given theme
 * @param theme The current theme
 */ export function getTeamsMessageColor(theme, traceLogger) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.SharePoint:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            return Colors.TeamsAppBrand00;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            return Colors.TeamsMessageDarkColor;
        case UnoTheme.TeamsContrast:
            return Colors.HighContrastYellow;
        default:
            ErrorUtilities.unreachableCase(theme.name, (log)=>{
                traceLogger.logTrace(0x1e463308 /* tag_4r9mi */ , TraceLevel.Warning, log);
            });
            return Colors.TeamsAppBrand00;
    }
}
/**
 * Gets the Fluid color for a given theme
 * @param theme The current theme
 */ export function getLoopColor(theme, traceLogger) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.SharePoint:
            return Colors.NeutralLightGrey26;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
            return Colors.LoopBrand100;
        case UnoTheme.TeamsContrast:
            return Colors.HighContrastYellow;
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            return Colors.NeutralLightGrey14;
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            return Colors.White;
        default:
            ErrorUtilities.unreachableCase(theme.name, (log)=>{
                traceLogger.logTrace(0x1e463307 /* tag_4r9mh */ , TraceLevel.Warning, log);
            });
            return Colors.LoopLightP0;
    }
}
/**
 * Gets the MeetingNotes color for a given theme
 * @param theme The current theme
 */ export function getMeetingNotesColor(theme, traceLogger) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.SharePoint:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            return Colors.MeetingNotesColorLight;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            return Colors.MeetingNotesColorDark;
        case UnoTheme.TeamsContrast:
            return Colors.HighContrastYellow;
        default:
            ErrorUtilities.unreachableCase(theme.name, (log)=>{
                traceLogger.logTrace(0x1e463306 /* tag_4r9mg */ , TraceLevel.Warning, log);
            });
            return Colors.MeetingNotesColorLight;
    }
}
/**
 * Gets the Word color for a given theme
 * @param theme The current theme
 */ export function getWordColor(theme, traceLogger) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.SharePoint:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            return Colors.WordColorLight;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            return Colors.WordColorDark;
        case UnoTheme.TeamsContrast:
            return Colors.HighContrastYellow;
        default:
            ErrorUtilities.unreachableCase(theme.name, (log)=>{
                traceLogger.logTrace(0x1e463305 /* tag_4r9mf */ , TraceLevel.Warning, log);
            });
            return Colors.WordColorLight;
    }
}
/**
 * Gets the Excel color for a given theme
 * @param theme The current theme
 */ export function getExcelColor(theme, traceLogger) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.SharePoint:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            return Colors.ExcelColorLight;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            return Colors.ExcelColorDark;
        case UnoTheme.TeamsContrast:
            return Colors.HighContrastYellow;
        default:
            ErrorUtilities.unreachableCase(theme.name, (log)=>{
                traceLogger.logTrace(0x1e463304 /* tag_4r9me */ , TraceLevel.Warning, log);
            });
            return Colors.ExcelColorLight;
    }
}
/**
 * Gets the PowerPoint color for a given theme
 * @param theme The current theme
 */ export function getPowerPointColor(theme, traceLogger) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.SharePoint:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            return Colors.PowerPointColorLight;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            return Colors.PowerPointColorDark;
        case UnoTheme.TeamsContrast:
            return Colors.HighContrastYellow;
        default:
            ErrorUtilities.unreachableCase(theme.name, (log)=>{
                traceLogger.logTrace(0x1e463303 /* tag_4r9md */ , TraceLevel.Warning, log);
            });
            return Colors.PowerPointColorLight;
    }
}
/**
 * Gets the Project color for a given theme
 * @param theme The current theme
 */ export function getProjectColor(theme, traceLogger) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.SharePoint:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            return Colors.ProjectColorLight;
        case UnoTheme.Dark:
        case UnoTheme.TeamsDark:
        case UnoTheme.LoopDark:
        case UnoTheme.LoopWorkspaceDark:
            return Colors.ProjectColorDark;
        case UnoTheme.TeamsContrast:
            return Colors.HighContrastYellow;
        default:
            ErrorUtilities.unreachableCase(theme.name, (log)=>{
                traceLogger.logTrace(0x1e463302 /* tag_4r9mc */ , TraceLevel.Warning, log);
            });
            return Colors.ProjectColorLight;
    }
}
/**
 * Gets the chart font color for a given theme
 * @param theme The current theme
 */ export function getChartFontColorForTheme(theme) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            // This is to handle a bug where Windows high contrast theme for white-on-black is returning the
            // font color for the app context theme, so the font color on the charts is not visible in this case
            if (isWindowsHighContrastWhiteOnBlack()) {
                return theme.palette.white;
            }
            return theme.palette.neutralPrimary;
        case UnoTheme.SharePoint:
            if (isWindowsHighContrastWhiteOnBlack()) {
                return theme.palette.white;
            }
            return Colors.SharepointTextColorForNonProcessableStyles();
        default:
            return theme.palette.neutralPrimary;
    }
}
/**
 * Gets the chart grid color for a given theme
 * @param theme The current theme
 */ export function getChartGridColorForTheme(theme) {
    switch(theme.name){
        case UnoTheme.SharePoint:
            return Colors.SharepointTextColorForNonProcessableStyles();
        default:
            return theme.semanticColors.bodyFrameDivider;
    }
}
/**
 * Gets the chart tooltip background color for a given theme
 * @param theme The current theme
 */ export function getChartTooltipBgColorForTheme(theme) {
    switch(theme.name){
        case UnoTheme.Light:
        case UnoTheme.TeamsLight:
        case UnoTheme.LoopLight:
        case UnoTheme.LoopWorkspaceLight:
            // This is to handle a bug where Windows high contrast theme for white-on-black is returning the
            // background color for the app context theme, and it is inaccessible in this case
            if (isWindowsHighContrastWhiteOnBlack()) {
                return theme.palette.black;
            }
            return theme.semanticColors.bodyStandoutBackground;
        case UnoTheme.SharePoint:
            if (isWindowsHighContrastWhiteOnBlack()) {
                return theme.palette.black;
            }
            return Colors.SharepointBGColorForNonProcessableStyles();
        default:
            return theme.semanticColors.bodyStandoutBackground;
    }
}
