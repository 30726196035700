/**
 * Enum representing the toggle permissions for mandatory checklist items.
 * This enum is used to define different states for toggling the mandatory checklist items.
 * The enum values can be used to control the behavior of the toggle functionality based on the permission level.
 */ export var MandatoryChecklistTogglePermissions;
(function(MandatoryChecklistTogglePermissions) {
    /** No restrictions on toggling. All checklist items can be toggled. */ MandatoryChecklistTogglePermissions[MandatoryChecklistTogglePermissions["DisableNone"] = 0] = "DisableNone";
    /** Toggling of mandatory checklist items is disabled. */ MandatoryChecklistTogglePermissions[MandatoryChecklistTogglePermissions["DisableMandatoryToggle"] = 1] = "DisableMandatoryToggle";
    /** The toggle for mandatory checklist items is hidden, but can still be accessed programmatically. */ MandatoryChecklistTogglePermissions[MandatoryChecklistTogglePermissions["HideMandatoryToggle"] = 2] = "HideMandatoryToggle";
})(MandatoryChecklistTogglePermissions || (MandatoryChecklistTogglePermissions = {}));
