// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { ButtonShadow } from "../../../constants/StyleConstants";
// Models
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Styles
import { concatStyleSets, FontSizes, FontWeights, memoizeFunction, mergeStyles } from "@fluentui/react";
import { flexChildSizeFixed, flexParent, fontColorNeutralSecondary, submitFormButton, textClip, labelStyle } from "../../../utilities/Mixin.styles";
import { highContrastBorders } from "../../../utilities/HighContrast.styles";
/**
 * Helper to get styles for CommentThreadView
 * @param theme Planner theme
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const root = [
        flexParent("column"),
        {
            paddingTop: "20px",
            paddingBottom: "5px",
            "> *": flexChildSizeFixed()
        },
        theme.name === UnoTheme.SharePoint && {
            backgroundColor: Colors.SharepointBackgroundColor()
        }
    ];
    const threadViewLabel = labelStyle(theme);
    const newCommentSection = [
        flexParent("column"),
        {
            "> *": flexChildSizeFixed(),
            "> .commentLoadError": [
                fontColorNeutralSecondary(theme),
                {
                    fontSize: FontSizes.small
                }
            ]
        }
    ];
    const commentBox = [
        highContrastBorders(),
        {
            "> textarea": [
                theme.name === UnoTheme.SharePoint && {
                    color: Colors.SharepointTitleFontColor()
                }
            ]
        }
    ];
    const sendCommentButton = [
        flexChildSizeFixed(),
        submitFormButton(theme),
        {
            alignSelf: "flex-end",
            margin: "12px 0px 15px",
            fontSize: FontSizes.small,
            fontWeight: FontWeights.semibold
        },
        theme.name === UnoTheme.SharePoint && [
            {
                color: Colors.SharepointTitleFontColor(),
                backgroundColor: Colors.SharepointAddTaskButtonBackgroundColor(),
                ":enabled": {
                    backgroundColor: Colors.SharepointLightBackgroundColor(),
                    borderColor: Colors.NeutralLight,
                    boxShadow: ButtonShadow,
                    ":focus, :hover": {
                        color: Colors.SharepointTitleFontColor(),
                        backgroundColor: Colors.SharepointBackgroundColor()
                    }
                }
            }
        ]
    ];
    const expandCommentsSection = [
        textClip(),
        {
            marginTop: "20px",
            padding: "10px 0px",
            textAlign: "center",
            color: Colors.ThemeSecondary,
            borderTop: "1px solid",
            cursor: "pointer"
        },
        theme.name === UnoTheme.Light && {
            borderColor: Colors.NeutralSecondary
        },
        theme.name === UnoTheme.Dark && {
            borderColor: Colors.NeutralLighterAlt
        }
    ];
    const CommentThreadViewStyles = {
        root,
        threadViewLabel,
        newCommentSection,
        commentBox,
        sendCommentButton,
        expandCommentsSection
    };
    return concatStyleSets(CommentThreadViewStyles, customStyles);
});
/**
 * Get the classnames associated with the computed styles
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("commentThreadView", styles.root),
        threadViewLabel: mergeStyles("threadViewLabel", styles.threadViewLabel),
        newCommentSection: mergeStyles("newCommentSection", styles.newCommentSection),
        commentBox: mergeStyles("commentBox", styles.commentBox),
        sendCommentButton: mergeStyles("sendCommentButton", styles.sendCommentButton),
        expandCommentsSection: mergeStyles("expandCommentsSection", styles.expandCommentsSection)
    }));
