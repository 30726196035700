//Models
// Translations
import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
// Constants
import { ProjectManagerWorkflowClientState } from "@ms/uno-constants/lib/local/WorkflowConstants";
/**
 * Create an array of label structs with the given names and counts.
 * @param labelNames array of label names
 * @param labelUsageCounts label counts
 */ export function getILabelArray(labelNames, labelUsageCounts) {
    return labelNames.map((name, index)=>({
            name,
            index,
            usageCount: labelUsageCounts.getValueAtIndex(index),
            id: index.toString(),
            labelFormatting: {
                backgroundColor: LabelConstants.LabelFormattingData[index].backgroundColor,
                hoverBackgroundColor: LabelConstants.LabelFormattingData[index].hoverBackgroundColor,
                hoverBorderColor: LabelConstants.LabelFormattingData[index].hoverBorderColor,
                textColor: LabelConstants.LabelFormattingData[index].textColor
            },
            placeholderText: getLabelPlaceholderByIndex(index)
        }));
}
const LabelPlaceholders = [
    Strings.Labels_PinkLabelPlaceholderText,
    Strings.Labels_RedLabelPlaceholderText,
    Strings.Labels_YellowLabelPlaceholderText,
    Strings.Labels_GreenLabelPlaceholderText,
    Strings.Labels_BlueLabelPlaceholderText,
    Strings.Labels_PurpleLabelPlaceholderText,
    Strings.Labels_BronzeLabelPlaceholderText,
    Strings.Labels_LimeLabelPlaceholderText,
    Strings.Labels_AquaLabelPlaceholderText,
    Strings.Labels_GrayLabelPlaceholderText,
    Strings.Labels_SilverLabelPlaceholderText,
    Strings.Labels_BrownLabelPlaceholderText,
    Strings.Labels_CranberryLabelPlaceholderText,
    Strings.Labels_OrangeLabelPlaceholderText,
    Strings.Labels_PeachLabelPlaceholderText,
    Strings.Labels_MarigoldLabelPlaceholderText,
    Strings.Labels_LightGreenLabelPlaceholderText,
    Strings.Labels_DarkGreenLabelPlaceholderText,
    Strings.Labels_TealLabelPlaceholderText,
    Strings.Labels_LightBlueLabelPlaceholderText,
    Strings.Labels_DarkBlueLabelPlaceholderText,
    Strings.Labels_LavenderLabelPlaceholderText,
    Strings.Labels_PlumLabelPlaceholderText,
    Strings.Labels_LightGrayLabelPlaceholderText,
    Strings.Labels_DarkGrayLabelPlaceholderText
];
/**
 * Get the color placeholder string for a label by index
 * @param labelIndex Label Index number
 */ export function getLabelPlaceholderByIndex(labelIndex) {
    if (labelIndex < 0 || labelIndex >= LabelPlaceholders.length) return "";
    return LabelPlaceholders[labelIndex];
}
export class LabelConstants {
}
LabelConstants.LabelFormattingData = [
    {
        // label 0
        backgroundColor: "#fbddf0",
        textColor: "#ac2d7e",
        hoverBorderColor: "#ac2d7e",
        hoverBackgroundColor: "#f7c0e3"
    },
    {
        // label 1
        backgroundColor: "#e9c7cd",
        textColor: "#750b1c",
        hoverBorderColor: "#750b1c",
        hoverBackgroundColor: "#d69ba5"
    },
    {
        // label 2
        backgroundColor: "#f5edce",
        textColor: "#6d5700",
        hoverBorderColor: "#6d5700",
        hoverBackgroundColor: "#eddea6"
    },
    {
        // label 3
        backgroundColor: "#dbebc7",
        textColor: "#386304",
        hoverBorderColor: "#386304",
        hoverBackgroundColor: "#bdda9b"
    },
    {
        // label 4
        backgroundColor: "#d0e7f8",
        textColor: "#005ba1",
        hoverBorderColor: "#005ba1",
        hoverBackgroundColor: "#a9d3f2"
    },
    {
        // label 5
        backgroundColor: "#d8cce7",
        textColor: "#401b6c",
        hoverBorderColor: "#401b6c",
        hoverBackgroundColor: "#b9a3d3"
    },
    {
        // label 6
        backgroundColor: "#f1d9cc",
        textColor: "#a74109",
        hoverBorderColor: "#a74109",
        hoverBackgroundColor: "#e4baa3"
    },
    {
        // label 7
        backgroundColor: "#e5f2d3",
        textColor: "#406014",
        hoverBorderColor: "#406014",
        hoverBackgroundColor: "#cfe6af"
    },
    {
        // label 8
        backgroundColor: "#c2e7e7",
        textColor: "#006666",
        hoverBorderColor: "#006666",
        hoverBackgroundColor: "#92d1d1"
    },
    {
        // label 9
        backgroundColor: "#e5e4e3",
        textColor: "#5d5a58",
        hoverBorderColor: "#5d5a58",
        hoverBackgroundColor: "#cecccb"
    },
    {
        // label 10
        backgroundColor: "#eaeeef",
        textColor: "#4b5356",
        hoverBorderColor: "#4b5356",
        hoverBackgroundColor: "#d8dfe0"
    },
    {
        // label 11
        backgroundColor: "#e2d1cb",
        textColor: "#4d291c",
        hoverBorderColor: "#4d291c",
        hoverBackgroundColor: "#c9ada3"
    },
    {
        // label 12
        backgroundColor: "#c50f1f",
        textColor: "#ffffff",
        hoverBorderColor: "#950c17",
        hoverBackgroundColor: "#b10e1c"
    },
    {
        // label 13
        backgroundColor: "#da3b01",
        textColor: "#ffffff",
        hoverBorderColor: "#a52c00",
        hoverBackgroundColor: "#c33400"
    },
    {
        // label 14
        backgroundColor: "#ff8c00",
        textColor: "#000000",
        hoverBorderColor: "#c26b00",
        hoverBackgroundColor: "#e67e00"
    },
    {
        // label 15
        backgroundColor: "#eaa300",
        textColor: "#000000",
        hoverBorderColor: "#b27d00",
        hoverBackgroundColor: "#d39400"
    },
    {
        // label 16
        backgroundColor: "#13a10e",
        textColor: "#000000",
        hoverBorderColor: "#0f7a0b",
        hoverBackgroundColor: "#11910d"
    },
    {
        // label 17
        backgroundColor: "#0b6a0b",
        textColor: "#ffffff",
        hoverBorderColor: "#085108",
        hoverBackgroundColor: "#0a600a"
    },
    {
        // label 18
        backgroundColor: "#00b7c3",
        textColor: "#000000",
        hoverBorderColor: "#008993",
        hoverBackgroundColor: "#00a3ae"
    },
    {
        // label 19
        backgroundColor: "#0078d4",
        textColor: "#ffffff",
        hoverBorderColor: "#20547c",
        hoverBackgroundColor: "#2c73a9"
    },
    {
        // label 20
        backgroundColor: "#003966",
        textColor: "#ffffff",
        hoverBorderColor: "#002c4e",
        hoverBackgroundColor: "#00345c"
    },
    {
        // label 21
        backgroundColor: "#7160eb",
        textColor: "#ffffff",
        hoverBorderColor: "#5549b2",
        hoverBackgroundColor: "#6557d3"
    },
    {
        // label 22
        backgroundColor: "#77004d",
        textColor: "#ffffff",
        hoverBorderColor: "#5b003b",
        hoverBackgroundColor: "#6c0046"
    },
    {
        // label 23
        backgroundColor: "#7a7574",
        textColor: "#ffffff",
        hoverBorderColor: "#5d5958",
        hoverBackgroundColor: "#6e6a69"
    },
    {
        // label 24
        backgroundColor: "#394146",
        textColor: "#ffffff",
        hoverBorderColor: "#2a3134",
        hoverBackgroundColor: "#32393e"
    }
];
export const getLabelDataByCopilotWorkflowStatus = (status)=>{
    switch(status){
        case ProjectManagerWorkflowClientState.AssignedToCopilot:
            return {
                name: Strings.Label_CopilotWorkflow_Queued,
                index: 9
            };
        case ProjectManagerWorkflowClientState.ActivelyWorking:
            return {
                name: Strings.Label_CopilotWorkflow_InProgress,
                index: 4
            };
        case ProjectManagerWorkflowClientState.NeedsInput:
            return {
                name: Strings.Label_CopilotWorkflow_NeedsYourInput,
                index: 1
            };
        case ProjectManagerWorkflowClientState.Done:
            return {
                name: Strings.Label_CopilotWorkflow_Ready,
                index: 3
            };
        case ProjectManagerWorkflowClientState.Unassigned:
        default:
            return null;
    }
};
