import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
import { NeutralColors } from "@fluentui/react";
// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
import { HighContrastGrayText, HighContrastHighlight } from "@ms/uno-constants/lib/local/HighContrastModeConstants";
// Models
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Styles
import { buttonStyle, choiceGroupOptionNeutralPrimary, dialogBackgroundPrimary, dialogControlStyle, errorColor, flexChildSizeFixed, flexChildSizeFlexible, flexParent, fontColorDisabled, fontColorNeutralPrimary, fontColorNeutralSecondary, fontColorNeutralTertiary, neutralSecondaryAltColor, selectedControlStyle, themedButtonStyle } from "../../../utilities/Mixin.styles";
import { highContrast } from "../../../utilities/HighContrast.styles";
/**
 * Helper to get styles for the Custom Recurrence Dialog
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const labelStyles = [
        fontColorNeutralSecondary(theme),
        {
            textAlign: "end",
            paddingRight: "16px",
            fontSize: "14px",
            lineHeight: "30px"
        }
    ];
    const customRecurrenceDialogStyles = {
        dialogContainer: [
            fontColorNeutralPrimary(theme),
            dialogBackgroundPrimary(theme),
            {
                boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 0px",
                borderRadius: "3px",
                "> div": {
                    flexGrow: "1"
                },
                "@media (min-width: 480px)": {
                    // This is needed to override fluent UI defined styles
                    maxWidth: "475px",
                    minWidth: "405px"
                }
            },
            theme.name === UnoTheme.TeamsContrast && {
                border: "1px solid " + Colors.White
            }
        ],
        dialogTitle: [
            fontColorNeutralPrimary(theme)
        ],
        dialogTopButton: {
            ".ms-Dialog-button": [
                fontColorNeutralPrimary(theme),
                {
                    ":hover": [
                        fontColorNeutralPrimary(theme),
                        {
                            backgroundColor: "transparent"
                        }
                    ]
                }
            ]
        },
        dialogFooterButtons: {
            ":hover": {
                backgroundColor: "transparent"
            },
            ":disabled": [
                fontColorNeutralTertiary(theme)
            ],
            "&.ms-Button--primary": [
                themedButtonStyle(theme)
            ],
            "&.ms-Button--default": [
                buttonStyle(theme)
            ]
        },
        body: [
            flexParent("column")
        ],
        repeatSection: [
            flexChildSizeFixed(),
            flexParent("column"),
            {
                ".repeatLabel": labelStyles,
                ".spinButton": {
                    marginRight: "8px",
                    width: "66px"
                },
                ".dropdown": {
                    minWidth: "90px"
                },
                ".repeatPattern": [
                    flexChildSizeFixed(),
                    flexParent("row"),
                    {
                        paddingBottom: "20px",
                        alignItems: "center"
                    }
                ]
            }
        ],
        weekdayPickerSection: [
            flexChildSizeFixed(),
            flexParent("column"),
            {
                paddingBottom: "20px",
                ".weekdayPickerRow": [
                    flexParent("row"),
                    {
                        alignItems: "center"
                    }
                ],
                ".weekdayPickerLabel": labelStyles,
                ".errorMessage": {
                    paddingTop: "16px"
                }
            }
        ],
        monthAndYearlyPickerSection: [
            flexChildSizeFixed(),
            flexParent("column"),
            {
                paddingBottom: "20px",
                ".absoluteRow, .relativeRow": [
                    flexChildSizeFixed(),
                    flexParent("row", "nowrap"),
                    {
                        marginLeft: "96px"
                    }
                ],
                ".absoluteRowElements, .relativeRowElements": [
                    flexChildSizeFlexible(),
                    flexParent("row", "wrap"),
                    {
                        " > *": [
                            flexChildSizeFixed(),
                            {
                                marginRight: "8px",
                                marginBottom: "10px"
                            }
                        ]
                    }
                ],
                ".weekIndexDropdown": {
                    minWidth: "92px"
                },
                ".monthDropdown": {
                    minWidth: "110px"
                },
                ".dayOfWeekDropdown": {
                    minWidth: "120px"
                },
                ".rowText": [
                    fontColorNeutralSecondary(theme),
                    {
                        fontSize: "14px",
                        height: "30px",
                        lineHeight: "30px"
                    }
                ],
                ".errorMessage": {
                    marginLeft: "30px",
                    paddingBottom: "16px"
                }
            }
        ],
        summaryText: [
            flexChildSizeFixed(),
            {
                fontSize: "12px",
                height: "32px",
                maxWidth: "fit-content",
                paddingTop: "4px",
                ".patternText": {
                    fontWeight: "600"
                }
            }
        ],
        errorMessage: [
            flexChildSizeFixed(),
            {
                color: errorColor(theme),
                paddingLeft: "95px",
                fontSize: "10px",
                height: "16px"
            }
        ]
    };
    return concatStyleSets(customRecurrenceDialogStyles, customStyles);
});
/**
 * Helper to get styles for Dialog content
 * @param styles The styles for the custom recurrence dialog
 */ export const getDialogContentStyles = memoizeFunction((styles)=>{
    const dialogContentStyles = {
        title: styles.dialogTitle,
        topButton: styles.dialogTopButton
    };
    return dialogContentStyles;
});
/**
 * Helper to get styles for the SpinButton in the custom recurrence dialog
 * @param theme The current theme
 * @param isDisabled Whether or not the spin button is disabled
 */ export const getSpinButtonStyles = memoizeFunction((theme, isDisabled)=>{
    const spinButtonStyles = {
        root: [
            dialogControlStyle(theme),
            {
                minWidth: "66px",
                ":hover .ms-Button--icon": {
                    opacity: "1"
                },
                ":focus-within": selectedControlStyle(theme),
                ":disabled": dialogControlStyle(theme),
                ":hover .ms-Button--icon:disabled": {
                    opacity: "0",
                    pointerEvents: "none",
                    color: "transparent"
                }
            }
        ],
        spinButtonWrapper: {
            minWidth: "66px",
            "::after": {
                border: "none",
                display: "none"
            }
        },
        input: [
            fontColorNeutralPrimary(theme),
            {
                backgroundColor: neutralSecondaryAltColor(theme),
                borderRadius: "2px",
                textAlign: "center",
                fontColor: "red"
            },
            isDisabled && [
                fontColorDisabled(theme),
                {
                    backgroundColor: neutralSecondaryAltColor(theme)
                }
            ]
        ],
        arrowButtonsContainer: {
            right: "0px",
            position: "absolute",
            backgroundColor: "transparent",
            width: "20px"
        }
    };
    return spinButtonStyles;
});
/**
 * Helper to get styles for the SpinButton arrow buttons in the custom recurrence dialog
 * @param theme The current theme
 * @param isDisabled Whether or not the arrow button is disabled
 */ export const getSpinButtonArrowStyles = memoizeFunction((theme, isDisabled)=>{
    let buttonsHoverPressedColor = NeutralColors.gray190;
    if (theme.name === UnoTheme.Dark || theme.name === UnoTheme.TeamsDark) {
        buttonsHoverPressedColor = NeutralColors.gray30;
    } else if (theme.name === UnoTheme.TeamsContrast) {
        buttonsHoverPressedColor = Colors.TeamsContrastHyperlinkColor;
    }
    // Styles needed to override fluent styles that get injected in several different scenarios
    const buttonHoverPressedStyle = {
        backgroundColor: "transparent",
        color: buttonsHoverPressedColor
    };
    const disabledArrowButtonStyle = {
        opacity: "0",
        pointerEvents: "none",
        color: "transparent"
    };
    const buttonStyles = {
        root: [
            fontColorNeutralSecondary(theme),
            {
                opacity: "0",
                width: "20px",
                backgroundColor: "transparent",
                cursor: "pointer"
            },
            isDisabled && disabledArrowButtonStyle
        ],
        rootHovered: buttonHoverPressedStyle,
        rootPressed: buttonHoverPressedStyle,
        rootChecked: [
            buttonHoverPressedStyle,
            isDisabled && disabledArrowButtonStyle
        ],
        rootCheckedPressed: buttonHoverPressedStyle,
        rootDisabled: disabledArrowButtonStyle
    };
    return buttonStyles;
});
/**
 * Helper to get styles for the Dropdowns in the custom recurrence dialog
 * @param theme The current theme
 */ export const getDropdownStyles = memoizeFunction((theme, isDisabled)=>{
    let dropdownCaretHoverColor = NeutralColors.gray190;
    if (theme.name === UnoTheme.Dark || theme.name === UnoTheme.TeamsDark) {
        dropdownCaretHoverColor = NeutralColors.gray30;
    } else if (theme.name === UnoTheme.TeamsContrast) {
        dropdownCaretHoverColor = Colors.TeamsContrastHyperlinkColor;
    }
    const dropdownStyles = {
        root: [
            dialogControlStyle(theme),
            {
                borderBottom: "1px"
            },
            !isDisabled && {
                ":focus-within": [
                    selectedControlStyle(theme)
                ]
            }
        ],
        dropdown: [
            {
                "&:focus:after": {
                    border: "none",
                    position: "inherit"
                }
            },
            !isDisabled && {
                "&:hover .ms-Dropdown-title, &:focus .ms-Dropdown-title, &:active .ms-Dropdown-title": [
                    fontColorNeutralPrimary(theme),
                    {
                        borderColor: "transparent"
                    }
                ],
                "&:hover .ms-Dropdown-caretDown, &:focus .ms-Dropdown-caretDown, &:active .ms-Dropdown-caretDown": {
                    color: dropdownCaretHoverColor
                }
            }
        ],
        title: [
            fontColorNeutralPrimary(theme),
            isDisabled && fontColorDisabled(theme),
            {
                backgroundColor: "transparent",
                border: "0px solid transparent",
                borderRadius: "3px",
                paddingTop: "1px"
            }
        ],
        caretDown: [
            fontColorNeutralSecondary(theme),
            isDisabled && fontColorDisabled(theme),
            {
                paddingTop: "1px"
            }
        ]
    };
    return dropdownStyles;
});
/**
 * Helper to get styles for ChoiceGroupOption in the custom recurrence dialog
 * @param props IChoiceGroupOption-related props that can be used to determine conditional styles
 * @param theme The current theme
 */ export const getChoiceGroupOptionStyles = memoizeFunction((props, theme)=>{
    const { checked, disabled } = props;
    /** Style for the radio button outline and fill colors for default, selected, disabled, and hover/focus states */ const choiceGroupOptionButtonColorStyles = choiceGroupOptionNeutralPrimary(checked ?? false, disabled ?? false, theme);
    /** Style for the on-hover radio button outline/container */ const hoverButtonContainerStyles = {
        width: "16px",
        height: "16px",
        top: "7px",
        left: "7px",
        borderWidth: "2px"
    };
    /** Style for the radio button fill input  */ const buttonFillStyles = {
        width: "8px",
        height: "8px",
        borderWidth: "4px"
    };
    const choiceGroupOptionStyles = {
        root: [
            flexChildSizeFixed(),
            flexParent(),
            {
                margin: "0",
                height: "30px",
                width: "30px",
                "&:focus-within": {
                    outline: "1px solid",
                    outlineColor: theme.semanticColors.primaryButtonBackgroundHovered ?? theme.palette.themePrimary
                }
            }
        ],
        choiceFieldWrapper: [
            flexChildSizeFlexible(),
            flexParent()
        ],
        /* Field pseudo-elements cheat sheet -
            ::before -> radio button container/outline
            ::after -> radio button fill
            */ input: [
            {
                ":focus": [
                    // When input receives focus, "focus/hover" button styles must be applied to the neighboring field sub-component
                    {
                        "~ .ms-ChoiceField-field": [
                            {
                                "::before": hoverButtonContainerStyles,
                                "::after": [
                                    buttonFillStyles,
                                    highContrast({
                                        backgroundColor: disabled ? HighContrastGrayText : HighContrastHighlight
                                    })
                                ],
                                "::before, ::after": highContrast({
                                    borderColor: disabled ? HighContrastGrayText : HighContrastHighlight
                                })
                            }
                        ]
                    }
                ]
            }
        ],
        field: [
            choiceGroupOptionButtonColorStyles,
            {
                height: "30px",
                lineHeight: "30px",
                "::before": {
                    width: "14px",
                    height: "14px",
                    top: "8px",
                    left: "8px",
                    transitionProperty: "all"
                },
                ":hover::before": !disabled && hoverButtonContainerStyles,
                "::after": {
                    transitionProperty: "all"
                },
                "::after, :hover::after": [
                    buttonFillStyles,
                    {
                        top: "11px",
                        left: "11px"
                    }
                ]
            }
        ]
    };
    return choiceGroupOptionStyles;
});
/**
 * Get the classnames associated with the computed styles for the Custom Recurrence Dialog
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("customRecurrenceDialog", styles.root),
        dialogContainer: mergeStyles("customRecurrenceDialogContainer", styles.dialogContainer),
        dialogContent: mergeStyles("customRecurrenceDialogContent", styles.dialogContent),
        dialogFooterButtons: mergeStyles(styles.dialogFooterButtons),
        body: mergeStyles("customRecurrenceBody", styles.body),
        repeatSection: mergeStyles("repeatSection", styles.repeatSection),
        weekdayPickerSection: mergeStyles("weekdayPickerSection", styles.weekdayPickerSection),
        monthAndYearlyPickerSection: mergeStyles("monthAndYearlyPickerSection", styles.monthAndYearlyPickerSection),
        summaryText: mergeStyles("summaryText", styles.summaryText),
        errorMessage: mergeStyles("errorMessage", styles.errorMessage),
        dialogTitle: mergeStyles("dialogTitle", styles.dialogTitle),
        dialogTopButton: mergeStyles("dialogTopButton", styles.dialogTopButton)
    };
});
